import React from "react";

import { Button } from "@material-ui/core";

import EntryPage from "../Entry";

import {
  CONSUMER_ROUTES as ROUTES,
  MERCHANT_ROUTES,
} from "../../utils/constants";
import { useBmapi } from "../../utils/bmapi-context";
import styles from "../../utils/styles";

export default function Landing() {
  const { bmapi, baseUrl } = useBmapi();
  const classes = styles.useStyles();

  return (
    <EntryPage
      routes={ROUTES}
      customLogin={bmapi.settings.customLogin}
      forward={
        bmapi.isMerchantEnabled && (
          <Button
            href={baseUrl + MERCHANT_ROUTES.HOME.slice(1)}
            className={classes.loginColor}
          >
            Sono un merchant
          </Button>
        )
      }
    />
  );
}
