import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_NAMESPACE, MERCHANT_NAMESPACE } from "../utils/constants";

import Common from "./Common";
import RoutingConsumer from "./RoutingConsumer";
import RoutingMerchant from "./RoutingMerchant";
import NotFound from "../pages/NotFound";

export default function App() {
  const { bmapi } = useBmapi();

  return !bmapi ? null : (
    <main className={`bme-${bmapi.app}`}>
      {bmapi.customCSS && <link rel="stylesheet" href={bmapi.customCSS} />}
      <Switch>
        {!bmapi.isMerchantEnabled ? null : (
          <Route path={MERCHANT_NAMESPACE}>
            <Common Routing={RoutingMerchant} />
          </Route>
        )}
        {!bmapi.isConsumerEnabled ? null : (
          <Route path={CONSUMER_NAMESPACE}>
            <Common Routing={RoutingConsumer} />
          </Route>
        )}
        {!bmapi.isConsumerEnabled && !!bmapi.isMerchantEnabled ? (
          <Route exact path={CONSUMER_NAMESPACE}>
            <Redirect to={MERCHANT_NAMESPACE} />
          </Route>
        ) : null}
        <Route component={NotFound} />
      </Switch>
    </main>
  );
}
