import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Container } from "@material-ui/core";
import { CenterFocusStrong as CenterFocusIcon } from "@material-ui/icons";

import ProductsList from "../../ui/ProductsList";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import Banners from "../../components/Banners";

import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES, FEATURES, TENANTS } from "../../utils/constants";
import Title from "../../ui/Title";

export default function Home() {
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const history = useHistory();
  const [stats, setStats] = useState(null);
  const [products, setProducts] = useState(null);

  const updateData = useCallback(() => {
    startLoading();
    const alpha = (a, b) => a.campaign.name.localeCompare(b.campaign.name);
    const revAlpha = (b, a) => a.campaign.name.localeCompare(b.campaign.name);

    Promise.all([bmapi.getUserProductsStats(), bmapi.getUserProducts()])
      .then(([stats = [], products = []]) => {
        setStats(stats.sort(bmapi.tenant === TENANTS.LUCCA ? revAlpha : alpha));
        setProducts(products);
      })
      .catch(notifyError)
      .finally(stopLoading);
  }, [bmapi, startLoading, stopLoading, notifyError]);

  useEffect(() => updateData(), [updateData]);

  return !stats || !products ? null : (
    <Container maxWidth="lg">
      <Banners products={products} />
      {!!stats.length && (
        <React.Fragment>
          <Title>I tuoi coupon/card/pass</Title>
          <ProductsList
            stats={stats}
            products={products}
            updateData={updateData}
          />
        </React.Fragment>
      )}
      {bmapi.can(FEATURES.MY_CODE) && (
        <FloatingActions>
          <Action
            icon={<CenterFocusIcon />}
            label={"app-navbar--qr-code"}
            action={() => history.push(CONSUMER_ROUTES.QR_CODE)}
          />
        </FloatingActions>
      )}
    </Container>
  );
}
