import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import QRCodeGenerator from "qrcode";

import {
  Button,
  Card,
  Container,
  FormControl,
  Typography,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES, USER_PREFIX } from "../../utils/constants";
import Title from "../../ui/Title";

export default function QrCode() {
  const history = useHistory();
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const [image, setImage] = useState(false);

  useEffect(() => {
    startLoading();

    QRCodeGenerator.toDataURL(`${USER_PREFIX}${bmapi.getUserInfo().user_id}`, {
      scale: 16,
    })
      .then(setImage)
      .catch(notifyError)
      .finally(stopLoading);
  }, [bmapi, startLoading, stopLoading, notifyError]);

  return (
    <Container maxWidth="sm">
      <Title backUrl={CONSUMER_ROUTES.HOME}>
        <FormattedMessage id="app-navbar--qr-code" />
      </Title>
      <Typography gutterBottom>
        Utilizza questo QR Code quando ti viene richiesto dal punto vendita per
        ottenere nuove promo o card
      </Typography>
      <Card>
        {image && (
          <img src={image} alt="QR Code" style={{ maxWidth: "100%" }} />
        )}
      </Card>
      <FormControl margin="normal">
        <Button
          onClick={() => {
            history.push(CONSUMER_ROUTES.HOME);
          }}
          startIcon={<ArrowBackIcon />}
        >
          Torna alla home
        </Button>
      </FormControl>
    </Container>
  );
}
