import { createMuiTheme } from "@material-ui/core/styles";
import { CONSUMER } from "./constants";

export default function createTheme(theme, app) {
  return app === CONSUMER
    ? {
        app: createMuiTheme({
          palette: {
            primary: {
              main: theme.primary,
            },
            secondary: {
              main: theme.secondary,
            },
            common: {
              bg: theme.backgroundConsumer || theme.primary,
            },
          },
          shape: {
            borderRadius: theme.borderRadius,
          },
          typography: {
            fontFamily: theme.fontFamily,
          },
        }),
        login: createMuiTheme({
          overrides: {
            MuiCssBaseline: {
              "@global": {
                ".mdl-button.mdl-button": {
                  borderRadius: theme.borderRadius,
                },
              },
            },
          },
          palette: {
            primary: {
              main: theme.primary,
            },
            secondary: {
              main: theme.secondary,
            },
            background: {
              default: theme.backgroundConsumer || theme.primary,
            },
            common: {
              bg: theme.backgroundConsumer || theme.primary,
            },
          },
          shape: {
            borderRadius: theme.borderRadius,
          },
          typography: {
            fontFamily: theme.fontFamily,
          },
        }),
      }
    : {
        app: createMuiTheme({
          palette: {
            primary: {
              main: theme.primary,
            },
            secondary: {
              main: theme.secondary,
            },
            common: {
              bg: theme.backgroundMerchant || theme.secondary,
            },
          },
          shape: {
            borderRadius: theme.borderRadius,
          },
          typography: {
            fontFamily: theme.fontFamily,
          },
        }),
        login: createMuiTheme({
          overrides: {
            MuiCssBaseline: {
              "@global": {
                ".mdl-button.mdl-button": {
                  borderRadius: theme.borderRadius,
                },
              },
            },
          },
          palette: {
            primary: {
              main: theme.primary,
            },
            secondary: {
              main: theme.secondary,
            },
            background: {
              default: theme.backgroundMerchant || theme.secondary,
            },
            common: {
              bg: theme.backgroundMerchant || theme.secondary,
            },
          },
          shape: {
            borderRadius: theme.borderRadius,
          },
          typography: {
            fontFamily: theme.fontFamily,
          },
        }),
      };
}
