import React from "react";

import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";

export default function LoginCard({ children, title, back, forward }) {
  const { bmapi } = useBmapi();
  const classes = styles.useStyles();

  return (
    <Container maxWidth="xs">
      <Box textAlign="center" mb={3} mt={6}>
        <img width="200px" src={bmapi.logo} alt="main-logo" />

        {bmapi.settings.liberactaLinks !== false && (
          <Link href="https://liberacta.com/" target="blank">
            <Typography className={classes.loginColor} gutterBottom>
              <i>powered by LiberActa</i>
            </Typography>
          </Link>
        )}
      </Box>
      <Box textAlign="center" mt={6} mb={4}>
        <Card>
          <CardContent>
            {title && (
              <Typography component="h1" variant="h5">
                {title}
              </Typography>
            )}
            {children}
          </CardContent>
        </Card>
      </Box>

      <Grid container justify="space-between" className={classes.loginColor}>
        <Grid item>{back}</Grid>
        <Grid item>{forward}</Grid>
      </Grid>
    </Container>
  );
}
