import React from "react";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

export default function LinkDistribution({ values, handleChange }) {
  return (
    <FormControl margin="normal">
      <FormControlLabel
        control={
          <Switch
            checked={values.link_distribution}
            color="primary"
            onChange={(_, v) => {
              handleChange("link_distribution")(_, v);
              handleChange("max_products_per_user")({ target: { value: "1" } });
            }}
            key="link_distribution"
            name="link_distribution"
          />
        }
        label="Distribuzione con link"
      />
    </FormControl>
  );
}
