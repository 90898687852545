import React, { useState } from "react";

import Confirm from "./Confirm";
import { Typography, FormControlLabel, Checkbox } from "@material-ui/core";

export default function DeleteCampaign({ children, campaign, handleDelete }) {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [acceptDelete, setAcceptDelete] = useState(false);

  function onDelete() {
    setShowDeleteConfirm(false);

    return handleDelete().finally(() => {
      setAcceptDelete(false);
    });
  }

  return (
    <React.Fragment>
      <Confirm
        open={showDeleteConfirm}
        onConfirm={onDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        disabled={!acceptDelete}
      >
        <Typography variant="body1" gutterBottom>
          Eliminare la campagna <strong>{campaign.name}</strong>?
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={acceptDelete}
              onChange={(_, f) => setAcceptDelete(f)}
              name="flag"
              color="primary"
            />
          }
          label={<Typography variant="caption">Sono sicuro</Typography>}
        />
      </Confirm>
      {children(() => setShowDeleteConfirm(true))}
    </React.Fragment>
  );
}
