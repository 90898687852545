import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { BmapiProvider } from "./utils/bmapi-context";
import {
  CONSUMER,
  CONSUMER_NAMESPACE,
  DEFAULT_TENANT,
  DEFAULT_TENANT_MAP,
  ENVS,
  LOAD_DEFAULT_TENANT,
  MERCHANT,
  MULTIPLE_TENANTS,
} from "./utils/constants";
import { LocalizationProvider } from "./utils/i18n";
import * as serviceWorker from "./utils/serviceWorker";

import App from "./components/App";

import "./index.css";

function bootstrap(basename, tenant, app = CONSUMER) {
  serviceWorker.unregister();

  ReactDOM.render(
    <Router basename={basename}>
      <BmapiProvider
        tenant={tenant}
        app={app === MERCHANT ? MERCHANT : CONSUMER}
        baseUrl={`${window.location.origin}/${
          MULTIPLE_TENANTS ? `${tenant}/` : ""
        }`}
      >
        <LocalizationProvider>
          <App />
        </LocalizationProvider>
      </BmapiProvider>
    </Router>,
    document.getElementById("root")
  );
}

const pathname = window.location.pathname.split("/");

if (MULTIPLE_TENANTS) {
  if (!pathname[1]) {
    if (LOAD_DEFAULT_TENANT) {
      window.location =
        window.location.href +
        (DEFAULT_TENANT_MAP[window.location.hostname] || DEFAULT_TENANT) +
        CONSUMER_NAMESPACE;
    } else {
      console.error("Tenant multipli attivati. Tenant non impostato");

      if (process.env.NODE_ENV === ENVS.PRODUCTION) {
        window.location.replace("https://liberacta.com/");
      }
    }
  } else {
    bootstrap(`/${pathname[1]}`, pathname[1], pathname[2]);
  }
} else {
  bootstrap("/", DEFAULT_TENANT, pathname[1]);
}
