import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  ArrowBack,
  Block,
  Check,
  Clear,
  ExpandMore,
  PlaylistAdd,
  PlaylistAddCheck,
} from "@material-ui/icons";

import { useBmapi } from "../../utils/bmapi-context";
import styles from "../../utils/styles";
import Title from "../../ui/Title";
import { useCampaigns } from "../../utils/campaigns";

function ReservationElement({ reservation, campaignId }) {
  const classes = styles.useStyles();
  const { bmapi, notifyError } = useBmapi();
  const [issuing, setIssuing] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [status, setStatus] = useState(reservation.status);

  const issue = () => {
    setIssuing(true);
    bmapi
      .issueCampaign(campaignId, {
        reservation_id: reservation.id,
      })
      .then(() => setStatus(1))
      .catch(notifyError)
      .finally(() => setIssuing(false));
  };

  const reject = () => {
    setRejecting(true);
    bmapi
      .rejectReservation(reservation.id)
      .then(() => setStatus(2))
      .catch(notifyError)
      .finally(() => setRejecting(false));
  };

  const Icon = useMemo(() => {
    if (reservation.status === 2) return Block;
    if (reservation.status === 1) return PlaylistAddCheck;
    if (status === 2) return Clear;
    if (status === 1) return Check;
    return PlaylistAdd;
  }, [status, reservation.status]);

  const bg = useMemo(() => {
    if (reservation.status === 0 && status === 2)
      return bmapi.theme.app.palette.error.main;
    if (reservation.status === 0 && status === 1)
      return bmapi.theme.app.palette.success.main;
    return undefined;
  }, [status, reservation.status, bmapi.theme.app.palette]);

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar style={bg && { backgroundColor: bg }}>
          <Icon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <React.Fragment>
            {reservation.user_name && (
              <Typography variant="caption" display="block">
                {reservation.user_name}
              </Typography>
            )}
            <Typography display="block" gutterBottom>
              {reservation.user_email}
            </Typography>
          </React.Fragment>
        }
        secondary={new Date(reservation.created_at).toLocaleString("it-IT")}
      />
      {status === 0 && (
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={reject}
              disabled={rejecting || issuing || !campaignId}
            >
              <Clear color="error" />
            </IconButton>
            {rejecting && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={issue}
              disabled={issuing || rejecting || !campaignId}
            >
              <Check style={{ color: bmapi.theme.app.palette.success.main }} />
            </IconButton>
            {issuing && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

function ReservationList({
  reservations,
  title,
  campaignId,
  expanded = false,
}) {
  return reservations.length ? (
    <Box mb={4}>
      <Accordion defaultExpanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <List>
          {reservations.map((reservation, i) => (
            <React.Fragment key={reservation.id}>
              {i !== 0 ? <Divider variant="inset" component="li" /> : null}
              <ReservationElement
                reservation={reservation}
                campaignId={campaignId}
              />
            </React.Fragment>
          ))}
        </List>
      </Accordion>
    </Box>
  ) : null;
}

export default function Reservations() {
  const { campaignId } = useParams();
  const history = useHistory();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [reservations, setReservations] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const { campaigns, loadCampaigns } = useCampaigns();

  useEffect(() => {
    !campaigns ? startLoading() : stopLoading();
  }, [campaigns, startLoading, stopLoading]);

  useEffect(() => {
    loadCampaigns().then((cs) => {
      setCampaign(cs.find((c) => c.campaign_id === campaignId));
    });
  }, [loadCampaigns, campaignId]);

  useEffect(() => {
    startLoading();

    const byDate = (a, b) => new Date(a.created_at) - new Date(b.created_at);

    bmapi
      .getCampaignReservations(campaignId)
      .then((reservations) => {
        setReservations((reservations || []).sort(byDate));
      })
      .catch(notifyError)
      .finally(stopLoading);
  }, [bmapi, campaignId, notifyError, startLoading, stopLoading]);

  return !!reservations ? (
    <Container maxWidth="sm">
      {campaign && (
        <Title>
          <IconButton onClick={history.goBack} size="small">
            <ArrowBack />
          </IconButton>{" "}
          {campaign.name}
        </Title>
      )}

      {reservations.length ? (
        <>
          <ReservationList
            title="Lista d'attesa"
            reservations={reservations.filter((r) => r.status === 0)}
            campaignId={campaign?.id}
            expanded
          />
          <ReservationList
            title="Prenotazioni accettate"
            reservations={reservations.filter((r) => r.status === 1)}
          />
          <ReservationList
            title="Prenotazioni cancellate"
            reservations={reservations.filter((r) => r.status === 2)}
          />
        </>
      ) : (
        <Card>
          <CardContent>Nessuna prenotazione presente.</CardContent>
        </Card>
      )}

      <Box my={4}>
        <Button onClick={history.goBack} startIcon={<ArrowBack />}>
          Indietro
        </Button>
      </Box>
    </Container>
  ) : null;
}
