import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";
import InputImage from "./InputImage";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "300px",
  },
  submit: {
    marginTop: theme.spacing(2),
  },
}));

export default function CampaignEdit({ onCancel, campaign }) {
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const onSubmit = (event) => {
    event.preventDefault();
    startLoading();

    const formData = new FormData();
    formData.append("file", file);

    bmapi
      .uploadCampaignPictureRequest(
        bmapi.getUserInfo().business.id,
        campaign.id,
        formData
      )
      .then(onCancel)
      .catch(notifyError)
      .finally(stopLoading);
  };

  const handleCapture = (file, preview) => {
    setFile(file);
    setFilePreview(preview);
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <InputImage
        image={filePreview || campaign.image_url || ""}
        onChange={handleCapture}
        emptyText="Carica un'immagine per vederne qui l'anteprima. Infine premi
            conferma per associare l'immagine alla campagna"
      />

      <Button
        disabled={!file}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        <FormattedMessage id="app-actions--confirm" />
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        className={classes.submit}
        onClick={() => onCancel()}
      >
        <FormattedMessage id="app-actions--cancel" />
      </Button>
    </form>
  );
}
