import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Adjust as AdjustIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Send as SendIcon,
} from "@material-ui/icons";

import CampaignPerformance from "./CampaignPerformance";
import Confirm from "./Confirm";
import DeleteCampaign from "./DeleteCampaign";

import { useBmapi } from "../utils/bmapi-context";
import {
  CAMPAIGN_STATUS,
  CONSUMER_ROUTES,
  MERCHANT_ROUTES,
  ROLES,
} from "../utils/constants";
import styles from "../utils/styles";
import { IconsMap } from "../utils/campaigns";

export default function CampaignCard({
  campaign,
  handleJoin,
  handleEdit,
  handleSend,
  handleDelete,
}) {
  const classes = styles.useStyles();
  const { bmapi, baseUrl, notifyError, notifySuccess } = useBmapi();

  const [bg] = useState(campaign.image_url || bmapi.createBg(campaign.name));
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [performance, setPerformance] = useState(false);
  const [terms, setTerms] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [acceptTOS, setAcceptTOS] = useState(!campaign.tos_merchant_url);

  function handleCloseClick() {
    setExpanded(false);
  }

  function handleExpandClick() {
    setLoading(true);
    setPerformance(false);

    bmapi
      .getCampaignDetails(campaign.campaign_id)
      .then(({ use_terms, performance, campaign }) => {
        setPerformance(performance);
        setTerms(use_terms);
        setInfo(campaign);
        setExpanded(true);
        setLoading(false);
      })
      .catch(notifyError);
  }

  const getSharableLink = () =>
    `${baseUrl}${CONSUMER_ROUTES.CAMPAIGN.replace(
      ":campaignId",
      campaign.campaign_id
    ).slice(1)}`;

  const copyLink = () => {
    navigator.clipboard
      .writeText(getSharableLink())
      .then(() => notifySuccess("Link copiato con successo"))
      .catch(notifyError);
  };

  const canDelete = () =>
    campaign.canIssue &&
    (campaign.demo ||
      (performance?.issued_value === 0 && performance?.issued_qty === 0));

  const CampaignIcon = IconsMap[campaign.type];

  return (
    <Card style={{ position: "relative" }}>
      <Confirm
        open={showConfirm}
        onConfirm={handleJoin}
        onCancel={() => setShowConfirm(false)}
        disabled={!acceptTOS}
      >
        <Typography variant="body1" gutterBottom>
          Sei sicuro di voler aderire alla campagna{" "}
          <strong>{campaign.name}</strong>?
        </Typography>

        {campaign.tos_merchant_url && (
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTOS}
                onChange={(_, f) => setAcceptTOS(f)}
                name="flag"
                color="primary"
              />
            }
            label={
              <Typography variant="caption">
                Dichiaro di avere letto e approvato il regolamento della
                campagna le condizioni di adesione pubblicate su{" "}
                <a
                  href={campaign.tos_merchant_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  questa pagina
                </a>
              </Typography>
            }
          />
        )}
      </Confirm>

      {campaign.demo && <div className={classes.demoRibbon}>Demo</div>}

      <CardHeader
        avatar={
          <Avatar
            src={campaign.avatar_url || ""}
            aria-label={campaign.business_owner_name}
            className={classNames(classes.cardIcon, {
              [classes.disabled]:
                campaign.toSign || campaign.status === CAMPAIGN_STATUS.EXPIRED,
            })}
          >
            {CampaignIcon ? <CampaignIcon /> : <AdjustIcon />}
          </Avatar>
        }
        title={campaign.name}
        titleTypographyProps={{ variant: "h5" }}
        subheader={`${
          campaign.status === CAMPAIGN_STATUS.EXPIRED
            ? "Campagna scaduta il"
            : "Scadenza:"
        } ${new Date(campaign.expiration_date).toLocaleDateString("it-IT")}`}
        subheaderTypographyProps={
          campaign.status === CAMPAIGN_STATUS.EXPIRED
            ? { color: "primary" }
            : {}
        }
      />
      <CardMedia
        className={classNames(classes.cardMedia, {
          [classes.disabled]:
            campaign.toSign || campaign.status === CAMPAIGN_STATUS.EXPIRED,
        })}
        image={bg}
      />
      <CardContent>
        <Typography color="primary" variant="overline">
          {campaign.business_owner_name}
        </Typography>
        <Typography>{campaign.description}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        {false &&
        bmapi.validateRoles(ROLES.TENANT_MANAGER, bmapi.getUserInfo().role) ? (
          <IconButton color="primary" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        ) : null}

        {!campaign.toSign ? (
          <React.Fragment>
            {campaign.canIssue && (
              <Button
                color="primary"
                onClick={handleSend}
                startIcon={<SendIcon />}
              >
                Emetti
              </Button>
            )}
            <div style={{ position: "relative", marginLeft: "auto" }}>
              {campaign.canIssue && campaign.campaign_data.waiting_list && (
                <IconButton
                  component={RouterLink}
                  to={MERCHANT_ROUTES.RESERVATIONS.replace(
                    ":campaignId",
                    campaign.campaign_id
                  )}
                >
                  <PlaylistAddCheckIcon />
                </IconButton>
              )}
              <IconButton
                className={classNames(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={expanded ? handleCloseClick : handleExpandClick}
                aria-expanded={expanded}
                aria-label={
                  <FormattedMessage id="page-home--campaign--show-more" />
                }
                disabled={loading}
              >
                <ExpandMoreIcon />
              </IconButton>
              {loading ? (
                <CircularProgress size={48} className={classes.fabProgress} />
              ) : null}
            </div>
          </React.Fragment>
        ) : (
          <Button
            color="primary"
            onClick={() => setShowConfirm(true)}
            startIcon={<AddCircleOutlineIcon />}
          >
            Aderisci
          </Button>
        )}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {info.link_distribution && (
          <CardContent>
            <Tooltip title={getSharableLink()}>
              <Button variant="contained" onClick={copyLink} disableElevation>
                Copia il link di condivisione
              </Button>
            </Tooltip>
          </CardContent>
        )}
        <CardContent>
          <CampaignPerformance
            fromLoop={campaign.loop_campaign}
            owner={campaign.business_id === campaign.business_owner_id}
            type={campaign.type}
            performance={performance}
            terms={terms}
          />
        </CardContent>
        {canDelete() && (
          <CardContent>
            <DeleteCampaign handleDelete={handleDelete} campaign={campaign}>
              {(onClick) => (
                <Link color="error" component="button" onClick={onClick}>
                  Elimina la campagna <strong>{campaign.name}</strong>
                </Link>
              )}
            </DeleteCampaign>
          </CardContent>
        )}
      </Collapse>
    </Card>
  );
}
