import React, { useState, useEffect, useCallback } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { Box, Button, Link } from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";
import LoginCard from "../ui/LoginCard";

export default function Entry({ routes, customLogin, forward }) {
  const {
    bmapi,
    baseUrl,
    notifyError,
    startLoading,
    stopLoading,
    loading,
  } = useBmapi();
  const classes = styles.useStyles();
  const history = useHistory();
  const [uiConfig, setUiConfig] = useState({
    signInOptions: [],
  });

  const signIn = useCallback(() => {
    history.push(routes.SIGN_IN);
  }, [history, routes]);

  const customSignIn = useCallback(() => {
    window.location.href = customLogin.replace(
      "{cbUrl}",
      `${baseUrl}${routes.SIGN_IN_ACTION.replace(":action?", "logged").slice(
        1
      )}`
    );
  }, [customLogin, baseUrl, routes]);

  const completeSocialSignup = useCallback(() => {
    startLoading();

    bmapi
      .doSocialSignIn()
      .then(() => history.push(routes.HOME))
      .catch(notifyError)
      .finally(stopLoading);
  }, [bmapi, history, notifyError, routes.HOME, startLoading, stopLoading]);

  useEffect(() => {
    setUiConfig(
      bmapi.getSocialSignInConfig(completeSocialSignup, bmapi.logout)
    );
  }, [bmapi, setUiConfig, completeSocialSignup]);

  useEffect(() => {
    if (
      bmapi.isConsumer() &&
      document.cookie
        .split(";")
        .map((c) => c.trim().split("="))
        .some((c) => c[0] === "luccacrea_user_jwt")
    ) {
      history.push(routes.SIGN_IN_ACTION.replace(":action?", "logged"));
    }
  }, [bmapi, signIn, history, routes]);

  return (
    <LoginCard title="Benvenuto!" forward={forward}>
      <Box mt={4} mb={4}>
        {loading || !uiConfig.signInOptions.length ? null : (
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={bmapi.getFirebaseAuth()}
          />
        )}

        {(bmapi.settings.loginEmail || bmapi.isMerchant()) && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.loginButton}
            onClick={signIn}
          >
            Sign in with Email
          </Button>
        )}

        {customLogin && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.loginButton}
            onClick={customSignIn}
          >
            Sign in
          </Button>
        )}
      </Box>

      {bmapi.settings.moreInfo && bmapi.settings.moreInfo.startsWith("http") && (
        <Link href={bmapi.settings.moreInfo} target="blank">
          Maggiori informazioni
        </Link>
      )}
      {bmapi.settings.moreInfo && !bmapi.settings.moreInfo.startsWith("http") && (
        <Link component={RouterLink} to={routes.INFO}>
          Maggiori informazioni
        </Link>
      )}
    </LoginCard>
  );
}
