import React from "react";
import { Box, Container } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useBmapi } from "../utils/bmapi-context";

export default function Content({ children }) {
  const { bmapi } = useBmapi();

  const daysLeft = () =>
    Math.floor(
      bmapi.settings.unverified -
        (new Date() - new Date(bmapi.user.metadata.creationTime)) / 864e5
    );

  return (
    <Box my={5} pb={10}>
      {bmapi.needsVerification() && (
        <Container maxWidth="sm">
          <Box mb={5}>
            <Alert severity="warning">
              <AlertTitle>Account non verificato</AlertTitle>
              {typeof bmapi.settings.unverified === "number"
                ? `Hai ancora ${daysLeft()} giorni per certificare `
                : "Certifica "}
              il tuo indirizzo email cliccando sul link che hai ricevuto via
              email al momento della registrazione.
            </Alert>
          </Box>
        </Container>
      )}

      {children}
    </Box>
  );
}
