import React from "react";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import { useBmapi } from "../../../utils/bmapi-context";

export default function Demo({ values, handleChange }) {
  const { bmapi } = useBmapi();

  return (
    <FormControl margin="normal">
      <FormControlLabel
        control={
          <Switch
            checked={values.demo}
            color="primary"
            onChange={handleChange("demo")}
            key="demo"
            name="demo"
            disabled={bmapi.settings.demo}
          />
        }
        label="Campagna demo"
      />
    </FormControl>
  );
}
