import React, { useState } from "react";

import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import Logo from "./Logo";
import MainMenu from "./MainMenu";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";

export default function TopBarConsumer({ links, logo, routes, userActions }) {
  const { userId } = useBmapi();
  const classes = styles.useStyles();
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const closeMenu = () => setIsMenuVisible(false);
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

  return (
    <AppBar position="sticky" className={classes.topBar}>
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <Logo logo={logo} link={routes.HOME} />
        </div>
        {userId && (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="Menu"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>
      <MainMenu
        open={isMenuVisible}
        onClose={closeMenu}
        links={[...links, ...userActions]}
        userActions={[]}
        logoImage={logo}
        anchor="right"
      />
    </AppBar>
  );
}
