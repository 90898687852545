import {
  API,
  BUSINESS_TYPES,
  CONSUMER,
  DEFAULT_LANGUAGE,
  DOMAIN_WILDCARD,
  EMPTY_USER,
  FEATURE_PERMISSION,
  MERCHANT,
  PERMISSIONS,
  ROLES,
} from "./constants";
import Firebase, {
  auth,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from "./firebase";
import utils from "./utils";
import { getErrorMessage } from "./errors";
import createTheme from "./themes";

function loadConf(tenant, env) {
  try {
    const conf = require(`../configurations/${tenant}${
      env ? `.${env}` : ""
    }.json`);
    console.info(`Configuration: ${tenant}.${env || "default"}`);
    return conf;
  } catch {
    return {};
  }
}

function createSettings(tenant, env) {
  const envConf = loadConf(tenant, env);
  const tenantConf = loadConf(tenant);
  const defaultConf = loadConf("_default");
  return {
    common: {
      ...defaultConf,
      ...tenantConf,
      ...envConf,
    },
    consumer: {
      ...defaultConf,
      ...defaultConf._consumer,
      ...tenantConf,
      ...tenantConf._consumer,
      ...envConf,
      ...envConf._consumer,
    },
    merchant: {
      ...defaultConf,
      ...defaultConf._merchant,
      ...tenantConf,
      ...tenantConf._merchant,
      ...envConf,
      ...envConf._merchant,
    },
  };
}

export default class BmarkenAPI {
  constructor(tenant, app) {
    console.group("BME");
    console.info(`Env: ${process.env.REACT_APP_ENV}/${process.env.NODE_ENV}`);
    this.allSettings = createSettings(tenant, process.env.REACT_APP_ENV);
    console.info(`Tenant: ${tenant}`);
    console.info(`App: ${app}`);
    console.info(`UI version: ${process.env.REACT_APP_GIT_SHA}`);
    console.info(`Project: ${this.allSettings.common.firebase}`);
    console.info(`Stats: ${this.allSettings.common.analytics ? "On" : "Off"}`);
    console.groupEnd();

    this.tenant = tenant;
    this.isConsumerEnabled = this.isAppEnabled(CONSUMER);
    this.isMerchantEnabled = this.isAppEnabled(MERCHANT);
    this.setApp(app);
  }

  isAppEnabled(app) {
    return this.allSettings[app].clientId && this.allSettings[app].enabled;
  }

  setApp(app) {
    if (this.isAppEnabled(app)) {
      this.user = null;
      this.userData = null;
      this.app = app;
      this.settings = this.allSettings[app];

      this.firebase = new Firebase(
        require(`../configurations/firebase/${this.settings.firebase}.json`),
        this.userKey,
        this.settings.persistance || "local",
        (user) => this.setFirebaseUser(user),
        this.settings.analytics
      );
      this.language = this.settings.defaultLanguage || DEFAULT_LANGUAGE;
      this.onAuthStateChangedFns = [];
      this.themeConf = {
        ...require(`../configurations/themes/_default.json`),
        ...require(`../configurations/themes/${this.settings.theme}.json`),
      };
      this.theme = createTheme(this.themeConf, app);
    } else if (app === CONSUMER && this.isMerchantEnabled) {
      throw new Error("Go to merchant");
    } else {
      throw new Error("App non accessibile");
    }
  }

  can(feature) {
    if (this.getUserInfo().role !== ROLES.USER) {
      return (
        this.settings[feature] &&
        (!FEATURE_PERMISSION[feature] ||
          (this.getUserInfo().permissions || []).includes(
            FEATURE_PERMISSION[feature]
          ))
      );
    }
    return this.settings[feature];
  }

  // *** UTILS ***
  setFirebaseUser(user) {
    this.user = user;
    return this.triggerAuthStateChanged();
  }

  get customCSS() {
    return this.settings.customCSS
      ? this.settings.customCSS.includes("://")
        ? this.settings.customCSS
        : `${this.tenantStaticUrl}${this.settings.customCSS}`
      : false;
  }

  get logo() {
    const logo =
      this.settings[`logo${!this.user ? "-login" : ""}`] || this.settings.logo;
    return logo?.includes("://") ? logo : `${this.tenantStaticUrl}${logo}`;
  }

  get tenantStaticUrl() {
    return `/static/tenants/${this.tenant}/`;
  }

  isConsumer() {
    return this.app === CONSUMER;
  }

  isMerchant() {
    return this.app === MERCHANT;
  }

  getAuth() {
    return this.firebase.app.auth();
  }

  getFirebaseAuth() {
    return auth(this.firebase.app);
  }

  getErrorMessage(err) {
    return getErrorMessage(err);
  }

  logEvent(event) {
    this.firebase.log(event);
  }

  getDefaultHeaders() {
    const token = this.getUserInfo().firebase_token_id;

    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };
  }

  fetchAuthenticated(url, method = "GET", body, headers, retry = true) {
    const fullUrl = url.includes("://") ? url : `${this.settings.apiUrl}${url}`;

    return utils
      .fetch(fullUrl, method, body, headers || this.getDefaultHeaders())
      .catch((error) => {
        if (utils.checkTokenExpired(error) && retry)
          return this.doRefreshToken().then(() =>
            this.fetchAuthenticated(url, method, body, headers, false)
          );
        if (error.httpCode === 401) this.logout();
        throw error;
      });
  }

  fetchPlain(url, method = "GET", body, headers = {}) {
    return this.fetchAuthenticated(url, method, body, headers);
  }

  get userKey() {
    return `${this.tenant}-${this.app}`;
  }

  get userDataKey() {
    return `${this.userKey}-${this.getUserInfo().user_id}`;
  }

  get tenantDataKey() {
    return `${this.userKey}-data`;
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem(this.userKey)) || EMPTY_USER;
  }

  needsVerification() {
    return (
      this.user &&
      this.isConsumer() &&
      this.user.email &&
      !this.user.email.includes(DOMAIN_WILDCARD) &&
      this.settings.unverified &&
      !this.getUserInfo().token_info.email_verified
    );
  }

  setUserInfo(userInfo) {
    localStorage.setItem(
      this.userKey,
      JSON.stringify({
        ...this.getUserInfo(),
        ...userInfo,
      })
    );
  }

  getUserData() {
    return JSON.parse(localStorage.getItem(this.userDataKey)) || {};
  }

  setUserData(data) {
    const newData = {
      ...this.getUserData(),
      ...data,
    };
    localStorage.setItem(this.userDataKey, JSON.stringify(newData));
    return newData;
  }

  getTenantData() {
    return JSON.parse(localStorage.getItem(this.tenantDataKey)) || {};
  }

  setTenantData(data) {
    const newData = {
      ...this.getTenantData(),
      ...data,
    };
    localStorage.setItem(this.tenantDataKey, JSON.stringify(newData));
    return newData;
  }

  setCallbackUrl(url) {
    return this.setTenantData({
      signinCallbackUrl: url,
    });
  }

  removeUserInfo() {
    this.user = null;
    this.userData = null;
    localStorage.removeItem(this.userKey);
  }

  validateEmail(email) {
    return utils.validateEmail(email);
  }

  validatePassword(password) {
    return password.length >= 6;
  }

  validateRoles(roleWanted, userRole) {
    return utils.validateRoles(roleWanted, userRole);
  }

  validateUUID(id) {
    return utils.validateUUID(id);
  }

  createBg(seed) {
    return utils.createBg(seed, this.themeConf.primary);
  }

  // *** Token utils ***
  logout() {
    return this.doSignOut()
      .then(() => this.removeUserInfo())
      .then(() => this.setTenantData({ logout: true }))
      .then(() => this.triggerAuthStateChanged())
      .catch(console.error);
  }

  saveToken(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonObj = JSON.parse(window.atob(base64));

    this.setUserInfo({
      token_info: jsonObj,
      firebase_token_id: token,
      user_id: jsonObj.bme_id,
      role: jsonObj.r,
    });

    return jsonObj;
  }

  checkIfLoggedIn() {
    const userInfo = this.getUserInfo();
    return this.user && userInfo.token_info !== {} ? userInfo : false;
  }

  doCreateUserWithEmailAndPassword(email, password) {
    return this.getAuth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        this.getAuth().currentUser.sendEmailVerification();
        return this.isConsumer() && this.settings.unverified
          ? this.doSignInWithEmailAndPassword(email, password)
          : this.logout();
      });
  }

  doPasswordReset(email) {
    return this.getAuth().sendPasswordResetEmail(email);
  }

  doPasswordUpdate(oldPassword, newPassword) {
    const { token_info } = this.getUserInfo();

    const credential = EmailAuthProvider.credential(
      token_info.email,
      oldPassword
    );

    return this.getAuth()
      .currentUser.reauthenticateWithCredential(credential)
      .then(() =>
        this.getAuth()
          .currentUser.updatePassword(newPassword)
          .then(() => this.doRefreshToken())
      );
  }

  getCurrentToken() {
    return this.getAuth().currentUser.getIdToken(false);
  }

  doRefreshToken() {
    return this.getAuth()
      .currentUser.getIdToken(true)
      .then((t) => this.saveToken(t));
  }

  checkVerification(user) {
    if (
      this.isMerchant() &&
      !user.emailVerified &&
      !this.settings.unverified &&
      !user.email.includes(DOMAIN_WILDCARD)
    ) {
      this.doSignOut();
      throw new Error("USER_NOT_VERIFIED");
    } else if (
      this.isConsumer() &&
      !user.emailVerified &&
      user.email &&
      !user.email.includes(DOMAIN_WILDCARD)
    ) {
      if (!this.settings.unverified) {
        this.doSignOut();
        throw new Error("USER_NOT_VERIFIED");
      } else if (
        typeof this.settings.unverified === "number" &&
        this.settings.unverified <
          (new Date() - new Date(user.metadata.creationTime)) / 864e5
      ) {
        this.doSignOut();
        throw new Error("VERIFICATION_TIME_EXPIRED");
      }
    }
  }

  getCustomToken() {
    if (!this.settings.clientId) throw new Error("CLIENTID_NOT_FOUND");

    return this.fetchAuthenticated(
      API.CUSTOM_SIGNUP,
      "POST",
      JSON.stringify({
        tenant_id: this.settings.tenantId,
        client_id: this.settings.clientId,
      })
    )
      .then((res) => res.token)
      .catch((e) => {
        this.logout();
        throw e;
      });
  }

  doSignInWithEmailAndPassword(email, password) {
    return this.getAuth()
      .signInWithEmailAndPassword(email, password)
      .then((credentials) => this.checkVerification(credentials.user))
      .then(() => this.getCurrentToken())
      .then((token) => this.signup(token))
      .then(() => this.doRefreshToken())
      .then(() => this.triggerAuthStateChanged());
  }

  doSignInWithCustomToken() {
    return this.getCustomToken()
      .then((token) => this.getAuth().signInWithCustomToken(token))
      .then((credentials) => this.checkVerification(credentials.user))
      .then(() => this.getCurrentToken())
      .then((token) => this.signup(token))
      .then(() => this.doRefreshToken())
      .then(() => this.triggerAuthStateChanged());
  }

  doSocialSignIn() {
    return (
      this.getCurrentToken()
        // TODO: get email from twitter sign in
        // .then((token) => {
        //   console.log(this.user);
        //   return token;
        // })
        .then((token) => this.signup(token))
        .then(() => this.doRefreshToken())
        .then(() => this.triggerAuthStateChanged())
    );
  }

  signup(token) {
    this.logEvent("signup");
    if (!this.settings.clientId) throw new Error("CLIENTID_NOT_FOUND");

    return this.fetchAuthenticated(
      API.SIGNUP,
      "POST",
      JSON.stringify({
        token_id: token,
        client_id: this.settings.clientId,
      })
    ).catch((e) => {
      this.logout();
      throw e;
    });
  }

  doSignOut() {
    return this.getAuth().signOut();
  }

  getSocialSignInOptions() {
    let signInOptions = [];

    if (this.settings.google) {
      signInOptions.push({
        provider: GoogleAuthProvider.PROVIDER_ID,
        scopes: ["email"],
        customParameters: {
          prompt: "select_account",
        },
      });
    }

    if (this.settings.facebook) {
      signInOptions.push({
        provider: FacebookAuthProvider.PROVIDER_ID,
        scopes: ["public_profile", "email"],
        customParameters: {
          prompt: "select_account",
        },
      });
    }

    if (this.settings.twitter) {
      signInOptions.push(TwitterAuthProvider.PROVIDER_ID);
    }

    return signInOptions;
  }

  getSocialSignInConfig(onSuccess, onFailure) {
    return {
      signInFlow: "redirect",
      signInOptions: this.getSocialSignInOptions(),
      callbacks: {
        signInSuccessWithAuthResult: onSuccess,
        signInFailure: onFailure,
      },
    };
  }

  onAuthStateChanged(fn) {
    this.onAuthStateChangedFns.push(fn);
    return () => this.onAuthStateChangedFns.filter((f) => f !== fn);
  }

  async triggerAuthStateChanged() {
    if (this.user && this.getAuth().currentUser) {
      await this.getAuth()
        .currentUser.getIdToken(true)
        .then((t) => this.saveToken(t))
        .then(() => this.checkVerification(this.user))
        .catch(() => this.logout());
    }

    const user = this.checkIfLoggedIn();
    const trigger = () =>
      Promise.all(this.onAuthStateChangedFns.map((fn) => fn(user)));

    return !user.user_id
      ? trigger()
      : Promise.all([this.loadBusiness(), this.loadUserData()])
          .then(() => this.app === MERCHANT && this.setUserPermission())
          .then(trigger);
  }

  // *** Bmarken API ***
  getUserBusiness = (userId) => {
    const url = API.GET_USER_BUSINESS.replace(
      "{user}",
      userId || this.getUserInfo().user_id
    );
    return this.fetchAuthenticated(url);
  };

  getUserReservations = (userId) => {
    const url = API.GET_USER_RESERVATIONS.replace(
      "{user}",
      userId || this.getUserInfo().user_id
    );
    return this.fetchAuthenticated(url);
  };

  getUserPermission = (businessId, userId) => {
    if (this.getUserInfo().role === ROLES.STORE_MANAGER) {
      const url = API.GET_USER_PERMISSION.replace(
        "{business}",
        businessId || this.getUserInfo().business.id
      ).replace("{user}", userId || this.getUserInfo().user_id);
      return this.fetchAuthenticated(url);
    } else if (this.getUserInfo().role === ROLES.TENANT_MANAGER) {
      return new Promise((res) => res(Object.values(PERMISSIONS)));
    }
  };

  setUserPermission = (businessId) => {
    return this.getUserPermission(businessId).then((permissions) =>
      this.setUserInfo({ permissions })
    );
  };

  selectBusiness(business) {
    this.setUserInfo({ business });
    return this.setUserPermission(business.id);
  }

  changeBusiness(business) {
    return this.selectBusiness(business).then(() =>
      this.triggerAuthStateChanged()
    );
  }

  getTenantBusiness = () => {
    return this.fetchAuthenticated(API.GET_TENANT_BUSINESS);
  };

  getPolicy = () => {
    return this.fetchAuthenticated(API.GET_TENANT_POLICY);
  };

  loadBusiness() {
    if (
      (!this.getUserInfo().business ||
        typeof this.getUserInfo().business === "string") &&
      this.app === MERCHANT
    ) {
      return this.getUserBusiness().then((bs) => {
        if (!bs || bs.length === 0) return this.logout();

        const firstBusiness = bs.sort((a, b) =>
          a.type === b.type
            ? a.name.localeCompare(b.name)
            : a.type === BUSINESS_TYPES.LOOP
            ? -1
            : 1
        )[0];

        return this.selectBusiness(firstBusiness);
      });
    }
    return this.getUserInfo();
  }

  getTransactions = (filters) => {
    return this.fetchAuthenticated(
      `${API.GET_TRANSACTIONS}${utils.objToParams(filters)}`
    );
  };

  getTransactionsByCampaign = (campaignId) => {
    return this.getTransactions({ campaignId });
  };

  getCampaigns = (businessId) => {
    return this.fetchAuthenticated(
      API.GET_CAMPAIGNS.replace(
        "{business}",
        businessId || this.getUserInfo().business.id
      )
    );
  };

  getProductInfo = (code, businessId) => {
    const url = API.GET_PRODUCT_INFO.replace(
      "{business}",
      businessId || this.getUserInfo().business.id
    ).replace("{qr-code}", code);

    return this.fetchAuthenticated(url);
  };

  getCampaignTerms = (campaignId) => {
    const url = API.GET_CAMPAIGN_TERMS.replace("{campaign}", campaignId);
    return this.fetchAuthenticated(url);
  };

  getCampaignPrizes = (campaignId, productId) => {
    const url = API.GET_CAMPAIGN_PRIZES.replace(
      "{campaign}",
      campaignId
    ).replace("{product}", productId);
    return this.fetchAuthenticated(url);
  };

  getCampaignReservations = (campaignId) => {
    const url = API.CAMPAIGN_RESERVATION.replace("{campaign}", campaignId);
    return this.fetchAuthenticated(url);
  };

  reserveCampaign = (campaignId, quantity) => {
    const url = API.CAMPAIGN_RESERVATION.replace("{campaign}", campaignId);
    return this.fetchAuthenticated(
      url,
      "POST",
      JSON.stringify({ quantity: quantity || 1 })
    );
  };

  acceptProduct = (campaignId) => {
    const url = API.ACCEPT_PRODUCT.replace("{campaign}", campaignId);
    return this.fetchAuthenticated(url, "POST", "");
  };

  redeemPrize = (cardId, prizeId) => {
    const url = API.REDEEM_PRIZE.replace("{card}", cardId).replace(
      "{prize}",
      prizeId
    );

    return this.fetchAuthenticated(url, "POST", "");
  };

  getCampaignDetails = (campaignId, businessId) => {
    return this.fetchAuthenticated(
      API.GET_CAMPAIGN_DETAILS.replace(
        "{business}",
        businessId || this.getUserInfo().business.id
      ).replace("{campaign}", campaignId)
    );
  };

  getCampaignPerformance = (campaignId, businessId) => {
    const url = API.GET_CAMPAIGN_PERF.replace(
      "{business}",
      businessId || this.getUserInfo().business.id
    ).replace("{campaign}", campaignId);

    return this.fetchAuthenticated(url);
  };

  getTerms = (businessId) => {
    const url = API.GET_TERMS.replace(
      "{business}",
      businessId || this.getUserInfo().business.id
    );

    return this.fetchAuthenticated(url);
  };

  signTerm = (term, businessId) => {
    const url = API.SIGN_TERM.replace(
      "{business}",
      businessId || this.getUserInfo().business.id
    ).replace("{terms}", term);

    return this.fetchAuthenticated(url, "PUT");
  };

  getUsePermissionByEmail = (businessId, email) => {
    const url = API.GET_USE_PERMISSION_BY_EMAIL.replace(
      "{business}",
      businessId
    );

    return this.fetchAuthenticated(
      url,
      "PUT",
      JSON.stringify({
        email: email,
      })
    );
  };

  getRules = (campaignViewId, businessId) => {
    const url = API.GET_RULES.replace(
      "{business}",
      businessId || this.getUserInfo().business.id
    ).replace("{campaign}", campaignViewId);
    return this.fetchAuthenticated(url);
  };

  burnCoupon = (code, businessId) => {
    const url = API.BURN_COUPON.replace("{qr-code}", code);

    return this.fetchAuthenticated(
      url,
      "PUT",
      JSON.stringify({
        business_id: businessId || this.getUserInfo().business.id,
      })
    );
  };

  increaseCardBalance = (code, businessId, total, expense, extra) => {
    const url = API.INCREASE_CARD_BALANCE.replace("{qr-code}", code);

    return this.fetchAuthenticated(
      url,
      "PUT",
      JSON.stringify({
        total_value: total,
        expense: expense,
        extra_points: extra,
        business_id: businessId,
      })
    );
  };

  decreaseCardBalance = (code, businessId, expense) => {
    const url = API.DECREASE_CARD_BALANCE.replace("{qr-code}", code);

    return this.fetchAuthenticated(
      url,
      "PUT",
      JSON.stringify({
        total_value: expense,
        business_id: businessId,
      })
    );
  };

  getContents = (businessId) => {
    const url = API.GET_CONTENTS.replace(
      "{business}",
      businessId || this.getUserInfo().business.id
    );
    return this.fetchAuthenticated(url);
  };

  updatePolicy = (
    terms,
    privacy_policy,
    marketing_policy,
    profiling_policy,
    rules
  ) => {
    const url = API.UPDATE_TENANT_POLICY;

    return this.fetchAuthenticated(
      url,
      "PUT",
      JSON.stringify({
        rules,
        terms,
        privacy_policy,
        marketing_policy,
        profiling_policy,
      })
    );
  };

  updateContent = (
    businessId,
    id,
    title,
    description,
    expiration,
    priority
  ) => {
    const url = API.UPDATE_CONTENT.replace("{business}", businessId).replace(
      "{content}",
      id
    );

    return this.fetchAuthenticated(
      url,
      "PUT",
      JSON.stringify({
        title,
        expiration,
        description,
        priority,
      })
    );
  };

  updateContentStatus = (businessId, contentId, status) => {
    const url = API.UPDATE_CONTENT_STATUS.replace("{business}", businessId)
      .replace("{content}", contentId)
      .replace("{status}", status);

    return this.fetchAuthenticated(url, "PUT");
  };

  deleteContent = (businessId, contentId) => {
    const url = API.DELETE_CONTENT.replace("{business}", businessId).replace(
      "{content}",
      contentId
    );

    return this.fetchAuthenticated(url, "DELETE");
  };

  createOrder = (body) => {
    const url = API.CREATE_ORDER;
    return this.fetchAuthenticated(url, "POST", JSON.stringify(body));
  };

  checkOrder = (orderId, status) => {
    const url = API.CHECK_ORDER.replace("{order}", orderId).replace(
      "{status}",
      status
    );
    return this.fetchAuthenticated(url, "PUT", "");
  };

  getInventories = () => {
    return this.fetchAuthenticated(API.GET_INVENTORIES);
  };

  uploadContentRequest = (businessId, body) => {
    const url = API.GET_UPLOAD_CONTENTS.replace("{business}", businessId);

    return this.fetchAuthenticated(url).then((res) =>
      this.fetchPlain(res.upload_url, "POST", body)
    );
  };

  uploadCampaignPictureRequest = (businessId, campaignId, body) => {
    const url = API.GET_CAMPAIGN_UPLOAD.replace(
      "{business}",
      businessId
    ).replace("{campaign}", campaignId);

    return this.fetchAuthenticated(url).then((res) =>
      this.fetchAuthenticated(res.upload_url, "POST", body)
    );
  };

  getBusiness = (businessId) => {
    const url = API.GET_BUSINESS.replace(
      "{business}",
      businessId || this.getUserInfo().business.id
    );

    return this.fetchAuthenticated(url);
  };

  getBusinessCampaigns = (businessId) => {
    const url = API.GET_BUSINESS_CAMPAIGNS.replace(
      "{business}",
      businessId || this.getUserInfo().business.id
    );

    return this.fetchAuthenticated(url);
  };

  getTenantCampaigns = () => {
    return this.fetchAuthenticated(API.GET_TENANT_CAMPAIGNS);
  };

  getCampaign = (campaignId) => {
    return this.fetchPlain(
      API.GET_CAMPAIGN.replace("{tenant}", this.settings.tenantId).replace(
        "{campaign}",
        campaignId
      ),
      "GET",
      "",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    );
  };

  getEvents = (mainEvent, businessId) => {
    let params = "?";
    if (mainEvent || businessId) {
      params += mainEvent ? `main=${mainEvent}&` : "";
      params += businessId ? `business=${businessId}&` : "";
    }
    return this.fetchPlain(
      API.GET_EVENTS.replace("{tenant}", this.settings.tenantId) + params,
      "GET",
      "",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    );
  };

  createCampaign = (body) => {
    return this.fetchAuthenticated(
      API.CAMPAIGN_CREATE,
      "POST",
      JSON.stringify(body)
    );
  };

  deleteCampaign = (campaignId) => {
    return this.fetchAuthenticated(
      API.CAMPAIGN_DELETE.replace("{campaign}", campaignId),
      "DELETE"
    );
  };

  issueCampaign = (campaignId, body) => {
    return this.fetchAuthenticated(
      API.ISSUE_CAMPAIGN_PRODUCT.replace("{campaign}", campaignId),
      "POST",
      JSON.stringify(body)
    );
  };

  rejectReservation = (reservationId) => {
    return this.fetchAuthenticated(
      API.RESERVATION.replace("{reservation}", reservationId),
      "DELETE"
    );
  };

  queryPointsUsers = (query) => {
    return this.fetchAuthenticated(
      API.QUERY_POINTS_USERS,
      "POST",
      JSON.stringify(query)
    );
  };

  getUserProducts = (userId) => {
    const url = API.GET_PRODUCTS.replace(
      "{user}",
      userId || this.getUserInfo().user_id
    );
    return this.fetchAuthenticated(url);
  };

  getUserProducts = (userId, params = "") => {
    const url = API.GET_PRODUCTS.replace(
      "{user}",
      userId || this.getUserInfo().user_id
    );
    return this.fetchAuthenticated(`${url}${params}`);
  };

  getUserActiveProducts = (userId) => {
    return this.getUserProducts(userId, "?status=0");
  };

  getUserProductsByCampaign = (campaignId, userId) => {
    return this.getUserProducts(userId, `?campaignId=${campaignId}`);
  };

  getUserProductsStats = (userId) => {
    const url = API.GET_PRODUCTS_STATS.replace(
      "{user}",
      userId || this.getUserInfo().user_id
    );
    return this.fetchAuthenticated(url);
  };

  getUserDefaultProduct = (userId) => {
    const url = API.GET_DEFAULT_PRODUCT.replace(
      "{user}",
      userId || this.getUserInfo().user_id
    );
    return this.fetchAuthenticated(url);
  };

  getUserDefaultProductId = (userId) => {
    return this.getUserDefaultProduct(userId).then((p) => p.id);
  };

  createPermissionCode = (productId) => {
    const url = API.CREATE_QRCODE_PERMISSION.replace("{product}", productId);
    return this.fetchAuthenticated(url);
  };

  getExternalCode = (productId) => {
    const url = API.GET_EXTERNAL_CODE.replace("{product}", productId);
    return this.fetchAuthenticated(url).then((res) => res.Code);
  };

  getQrCodeImage = (code) => {
    const url = API.CREATE_QRCODE_IMAGE.replace("{qr-code}", code);

    return this.fetchAuthenticated(url, "GET", null, {
      ...this.getDefaultHeaders(),
      "Content-Type": "image/png",
    });
  };

  getUser = (userId) => {
    const url = API.GET_USER.replace(
      "{user}",
      userId || this.getUserInfo().user_id
    );

    return this.fetchAuthenticated(url);
  };

  updateLcngUser = (body, userId) => {
    const url = API.UPDATE_LCNG_USER.replace(
      "{user}",
      userId || this.getUserInfo().user_id
    );

    return this.fetchAuthenticated(url, "PUT", JSON.stringify(body))
      .then(() => this.loadUserData())
      .then(() => this.triggerAuthStateChanged());
  };

  loadUserData = (userId) => {
    return this.getUser(userId)
      .then((data) => (this.userData = data))
      .catch(() => this.logout());
  };

  upload = (url, file) => {
    const formData = new FormData();
    formData.append("file", file);

    return this.fetchAuthenticated(url, "POST", formData, {
      Authorization: `Bearer ${this.getUserInfo().firebase_token_id}`,
    });
  };

  uploadProfilePicture = (file, userId) => {
    const url = API.UPLOAD_AVATAR.replace(
      "{user}",
      userId || this.getUserInfo().user_id
    );

    return this.upload(url, file)
      .then(() => this.loadUserData())
      .then(() => this.triggerAuthStateChanged());
  };

  uploadCampaignCover = (campaignId, file) => {
    const url = API.UPLOAD_CAMPAIGN_COVER.replace("{campaign}", campaignId);
    return this.upload(url, file);
  };

  uploadCampaignIcon = (campaignId, file) => {
    const url = API.UPLOAD_CAMPAIGN_ICON.replace("{campaign}", campaignId);
    return this.upload(url, file);
  };
}
