import React, { useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { CONSUMER_ROUTES as ROUTES, TENANTS } from "../utils/constants";
import { useBmapi } from "../utils/bmapi-context";
import { consumer as links } from "../utils/pages";

import Account from "../pages/consumer/Account";
import Calendar from "../pages/consumer/Calendar";
import Campaign from "../pages/consumer/Campaign";
import Landing from "../pages/consumer/Landing";
import MoreInfo from "../pages/consumer/MoreInfo";
import PasswordReset from "../pages/consumer/PasswordReset";
import Products from "../pages/consumer/Products";
import QrCode from "../pages/consumer/QrCode";
import SignIn from "../pages/consumer/SignIn";
import SignUp from "../pages/consumer/SignUp";
import Static from "../pages/consumer/Static";

import LuccaBuy from "../pages/custom/lcng/LuccaBuy";
import LuccaConfirm from "../pages/custom/lcng/LuccaConfirm";
import LuccaTOC from "../pages/custom/lcng/LuccaTOC";

import Navbar from "./Navbar";
import Content from "../ui/Content";

function PublicPage({ component: Page, ...props }) {
  const { bmapi } = useBmapi();
  return (
    <Route {...props}>
      <MuiThemeProvider theme={bmapi.theme.login}>
        <CssBaseline />
        <Page />
      </MuiThemeProvider>
    </Route>
  );
}

function PrivatePage({ component: Page, ...props }) {
  return (
    <Route {...props}>
      <Navbar links={links} routes={ROUTES} />
      <Content>
        <Page />
      </Content>
    </Route>
  );
}

export default function RoutingConsumer() {
  const { userId, bmapi, baseUrl } = useBmapi();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => window.scrollTo(0, 0), [location.pathname]);

  useEffect(() => {
    const cbUrl = bmapi.getTenantData().signinCallbackUrl;
    if (userId && cbUrl) {
      history.push(cbUrl.replace(baseUrl, ""));
      bmapi.setCallbackUrl(false);
    }
  }, [bmapi, userId, baseUrl, history]);

  const needsPrivacy = () => {
    return (
      bmapi.tenant === TENANTS.LUCCA &&
      (!bmapi.userData ||
        !bmapi.userData.custom_fields ||
        bmapi.userData.custom_fields === "" ||
        !JSON.parse(bmapi.userData.custom_fields).rules)
    );
  };

  return !userId ? (
    <Switch>
      <PublicPage path={ROUTES.SIGN_IN_ACTION} component={SignIn} />
      <PublicPage path={ROUTES.SIGN_IN} component={SignIn} />
      <PublicPage path={ROUTES.SIGN_UP} component={SignUp} />
      <PublicPage path={ROUTES.PASSWORD_FORGET} component={PasswordReset} />
      <PublicPage path={ROUTES.INFO} component={MoreInfo} />
      <PrivatePage path={ROUTES.CALENDAR} component={Calendar} />
      <PrivatePage path={ROUTES.CAMPAIGN} component={Campaign} />
      <PublicPage exact path={ROUTES.HOME} component={Landing} />
      <Redirect to={ROUTES.HOME} />
    </Switch>
  ) : (
    <Switch>
      <PrivatePage path={ROUTES.STATIC} component={Static} />
      {needsPrivacy() && (
        <PrivatePage path={ROUTES.HOME} component={LuccaTOC} />
      )}
      <PrivatePage path={ROUTES.QR_CODE} component={QrCode} />
      <PrivatePage path={ROUTES.ACCOUNT} component={Account} />
      <PrivatePage path={ROUTES.CALENDAR} component={Calendar} />
      <PrivatePage path={ROUTES.CAMPAIGN} component={Campaign} />
      {bmapi.tenant === TENANTS.LUCCA && (
        <Redirect from={ROUTES.LUCCA_CANCEL} to={ROUTES.LUCCA_BUY} />
      )}
      {bmapi.tenant === TENANTS.LUCCA && (
        <PrivatePage path={ROUTES.LUCCA_BUY} component={LuccaBuy} />
      )}
      {bmapi.tenant === TENANTS.LUCCA && (
        <PrivatePage path={ROUTES.LUCCA_CONFIRM} component={LuccaConfirm} />
      )}
      <PrivatePage exact path={ROUTES.HOME} component={Products} />
      <Redirect to={ROUTES.HOME} />
    </Switch>
  );
}
