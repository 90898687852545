import React from "react";

import { MenuItem, TextField } from "@material-ui/core";

import { CODE_FORMATS, PRODUCT_TYPES } from "../../../utils/constants";

export default function ExternalIdFormat({ values, handleChange }) {
  return (
    [PRODUCT_TYPES.CAMPAIGN_COUPON, PRODUCT_TYPES.CAMPAIGN_EVENT_PASS].includes(
      values.type
    ) && (
      <TextField
        name="external_ids_format"
        label="Utilizzo di codici esterni"
        value={values.external_ids_format}
        onChange={(v) => {
          handleChange("external_ids_format")(v);
          if (v) {
            handleChange("auto_join")(null, false);
            handleChange("max_issue_number")({ target: { value: "" } });
          }
        }}
        select
        fullWidth
        margin="normal"
      >
        {[
          { label: "Disattivato", value: "" },
          { label: "Formato testo", value: CODE_FORMATS.STRING },
          { label: "QR Code", value: CODE_FORMATS.QR_CODE },
        ].map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    )
  );
}
