import React from "react";

import NumberInput from "../../NumberInput";

export default function MaxIssueNumber({ values, handleChange, label }) {
  return (
    <NumberInput
      name="max_issue_number"
      label={`Numero massimo di ${label} distribuibili`}
      value={values.max_issue_number}
      onChange={handleChange("max_issue_number")}
      key="max_issue_number"
      fullWidth
      margin="normal"
      disabled={!!values.external_ids_format}
    />
  );
}
