import React from "react";

import { TimePicker } from "../../DatePicker";

export default function StartHour({ values, handleChange, label }) {
  return (
    <TimePicker
      label={`Ora di inizio ${label === "pass" ? "evento" : "campagna"}`}
      value={values.start_hour}
      onChange={handleChange("start_hour")}
      key="start_hour"
      fullWidth
      autoOk
      clearable
      required={!!values.end_hour}
    />
  );
}
