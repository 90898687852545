import React, { useContext, useState, useEffect, useCallback } from "react";
// import { FormattedMessage } from "react-intl";

import BmarkenAPI from "./bmapi";
import { setupMeta } from "./meta";
import { MERCHANT_NAMESPACE, VARIANTS } from "./constants";

const bmapiContext = React.createContext(null);

export const useBmapi = () => useContext(bmapiContext);

export const BmapiProvider = ({ children, tenant, app, baseUrl }) => {
  const [bmapi, setBmapi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const notifyError = useCallback(
    (error) => {
      // TODO: translate errors: <FormattedMessage id="app-actions--cancel" />
      setShowMessage(true);
      setMessage({
        text: bmapi.getErrorMessage(error),
        type: VARIANTS.ERROR,
      });
    },
    [bmapi]
  );
  const notifySuccess = useCallback((message) => {
    setShowMessage(true);
    setMessage({
      text: message,
      type: VARIANTS.SUCCESS,
    });
  }, []);
  const hideMessage = useCallback(() => setShowMessage(false), []);

  const startLoading = useCallback(() => setLoading(true), []);
  const stopLoading = useCallback(() => setLoading(false), []);
  const toggleLoading = useCallback(
    (flag) => setLoading((l) => (typeof flag === "boolean" ? flag : !l)),
    []
  );

  useEffect(() => {
    try {
      const api = new BmarkenAPI(tenant, app);
      setBmapi(api);
      setupMeta(api);

      return api.onAuthStateChanged((user) => {
        setUser(user);

        if (
          !user &&
          api.getTenantData().logout &&
          api.settings.customLogout &&
          api.isConsumer()
        ) {
          api.setTenantData({ logout: false });
          window.location.href = api.settings.customLogout.replace(
            "{cbUrl}",
            baseUrl
          );
        }
      });
    } catch (error) {
      if (error.message === "Go to merchant") {
        window.location.href = `${baseUrl}${MERCHANT_NAMESPACE.slice(1)}`;
      } else {
        console.error("Failed start");
        setError(error);
      }
    }
  }, [tenant, baseUrl, app]);

  const value = {
    bmapi,
    baseUrl,
    businessId: user?.business?.id,
    error,
    hideMessage,
    loading,
    message,
    tenant,
    notifyError,
    notifySuccess,
    showMessage,
    starting: user === null,
    startLoading,
    stopLoading,
    toggleLoading,
    userId: user?.user_id,
  };

  return (
    <bmapiContext.Provider value={value}>{children}</bmapiContext.Provider>
  );
};
