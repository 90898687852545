import React from "react";

import { InputAdornment } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function ProductLifespan({ values, handleChange, label }) {
  return (
    values.subtype !== PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
      <NumberInput
        name="product_lifespan"
        label={`Validità ${label}`}
        value={values.product_lifespan}
        onChange={handleChange("product_lifespan")}
        key="product_lifespan"
        fullWidth
        margin="normal"
        required={!values.product_limit_date}
        InputProps={{
          endAdornment: <InputAdornment position="end">mesi</InputAdornment>,
        }}
      />
    )
  );
}
