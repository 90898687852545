import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export default function Confirm({
  children,
  onConfirm,
  onCancel,
  title,
  text,
  open,
  disabled = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="responsive-dialog-title"
    >
      {title ? (
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      ) : null}
      {text ? (
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
      ) : null}
      {children ? <DialogContent>{children}</DialogContent> : null}
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          <FormattedMessage id="app-actions--cancel" />
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          autoFocus
          disabled={disabled}
          variant="contained"
        >
          <FormattedMessage id="app-actions--confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
