import React from "react";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import { useBmapi } from "../../../utils/bmapi-context";

export default function AutoJoin({ values, handleChange }) {
  const { bmapi } = useBmapi();

  return (
    bmapi.getUserInfo().business.type === "Loop" && (
      <FormControl margin="normal">
        <FormControlLabel
          control={
            <Switch
              checked={values.auto_join}
              color="primary"
              onChange={handleChange("auto_join")}
              key="auto_join"
              name="auto_join"
              disabled={!!values.external_ids_format}
            />
          }
          label="Adesione automatica alla campagna"
        />
      </FormControl>
    )
  );
}
