import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";

import { useBmapi } from "./bmapi-context";
import { getDictionary, getDateDictionary } from "./dictionaries";
import { DEFAULT_LANGUAGE } from "./constants";

export function LocalizationProvider({ children }) {
  const { bmapi } = useBmapi();
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);

  useEffect(() => {
    if (bmapi) {
      setLanguage(
        bmapi.getUserInfo().language ||
          bmapi.language ||
          (navigator.languages && navigator.languages[0]) ||
          navigator.language ||
          navigator.userLanguage
      );
    }
  }, [bmapi]);

  return (
    <IntlProvider
      locale={language}
      messages={getDictionary(language)}
      dateDictionary={getDateDictionary(language)}
    >
      {children}
    </IntlProvider>
  );
}
