import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import QRCodeGenerator from "qrcode";
import { endOfDay } from "date-fns";

import {
  AccountCircle as AccountCircleIcon,
  AddCircle as AddCircleIcon,
  EmojiEvents as EmojiEventsIcon,
  Event as EventIcon,
  FilterCenterFocus as FilterCenterFocusIcon,
  Info as InfoIcon,
  PlaylistAdd as PlaylistAddIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
} from "@material-ui/icons";
import {
  // Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { IconsMap } from "../utils/campaigns";
import {
  CODE_FORMATS,
  CONSUMER_ROUTES,
  FEATURES,
  PRODUCT_SUBTYPES,
  PRODUCT_TYPES,
} from "../utils/constants";
import styles from "../utils/styles";
import { useBmapi } from "../utils/bmapi-context";
import Confirm from "./Confirm";

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const actionLabel = {
  [PRODUCT_TYPES.CAMPAIGN_COUPON]: "il Coupon",
  [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD]: "la Fidelity Card",
  [PRODUCT_TYPES.CAMPAIGN_EVENT_PASS]: "il Pass",
  [PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD]: "la Card",
};

const STATUS = {
  ANONYMOUS: 1,
  NO_PERMISSION: 2,
  DISABLED_ACCOUNT: -2,
  CAMPAIGN_NOT_STARTED: -10,
  CAMPAIGN_ENDED: -11,
  BOOKED: -12,
  NO_PRODUCTS_AVAILABLE: -20,
  ALL_PRODUCTS_USED: -21,
  NOT_AVAILABLE: 0,
  REEDEMABLE: 10,
  ENABLED: 11,
  BOOKABLE: 12,
  EXTERNAL: 13,
};

function getStatus(campaign, availability) {
  switch (campaign.type) {
    case PRODUCT_TYPES.CAMPAIGN_EARNING_CARD:
      return `Saldo: ${availability} ${campaign.rules.currency} punti`;

    case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
      return `Valore disponibile: ${availability} ${campaign.rules.currency}`;

    case PRODUCT_TYPES.CAMPAIGN_COUPON:
      return `${availability} coupon disponibil${
        availability === 1 ? "e" : "i"
      }`;

    case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
      return `${availability} pass disponibil${availability === 1 ? "e" : "i"}`;

    default:
      break;
  }

  return "";
}

function localizeDate(date) {
  return new Date(date).toLocaleDateString("it-IT");
}

function getSignificantDateInfo(campaign, nextExpiration, availability) {
  if (campaign.status !== 0) return `Campagna terminata`;
  if (new Date(campaign.start_date) > new Date()) {
    if (campaign.type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS) {
      return `Inizio evento ${localizeDate(campaign.start_date)}${
        campaign.start_hour ? ` alle ore ${campaign.start_hour}` : ""
      }`;
    }
    return `Potrai utilizzare ${
      actionLabel[campaign.type]
    } a partire dal ${localizeDate(campaign.start_date)}`;
  }
  if (availability === 0)
    return `La campagna terminerà il ${localizeDate(campaign.expiration_date)}`;
  return `Prossima scadenza: ${localizeDate(nextExpiration)}`;
}

export function UseProduct({
  campaign,
  issuedQuantity,
  product,
  availability,
  onUpdate,
  productsReceived,
  reserved,
}) {
  const {
    bmapi,
    startLoading,
    stopLoading,
    notifyError,
    notifySuccess,
  } = useBmapi();
  const history = useHistory();
  const { campaignId } = useParams();
  const [image, setImage] = useState(false);
  const [code, setCode] = useState(false);
  const [updateInterval, setUpdateInterval] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [acceptTOS, setAcceptTOS] = useState(!campaign.tos_consumer_url);
  const updateIntervalTime = 5000;

  const btnText = {
    [STATUS.ANONYMOUS]: "Accedi o registrati",
    [STATUS.NO_PERMISSION]: "Accedi o registrati",
    [STATUS.DISABLED_ACCOUNT]: "Verifica il tuo account",
    [STATUS.CAMPAIGN_NOT_STARTED]: `${capitalize(
      actionLabel[campaign.type] || ""
    )} non è ancora utilizzabile`,
    [STATUS.CAMPAIGN_ENDED]: "La campagna è terminata",
    [STATUS.NO_PRODUCTS_AVAILABLE]: "Disponibilità esaurita",
    [STATUS.ALL_PRODUCTS_USED]: `${
      capitalize(actionLabel[campaign.type]) || ""
    } è stato utilizzato`,
    [STATUS.NOT_AVAILABLE]: `${
      capitalize(actionLabel[campaign.type]) || ""
    } non è disponibile`,
    [STATUS.BOOKED]: "Lista d'attesa",
    [STATUS.REEDEMABLE]: `Ottieni ${actionLabel[campaign.type] || ""}`,
    [STATUS.ENABLED]: `Utilizza ${actionLabel[campaign.type] || ""}`,
    [STATUS.BOOKABLE]: `Lista d'attesa`,
    [STATUS.EXTERNAL]: "Visualizza il codice",
  };

  const btnIcon = {
    [STATUS.ANONYMOUS]: <AccountCircleIcon />,
    [STATUS.NO_PERMISSION]: <AccountCircleIcon />,
    [STATUS.REEDEMABLE]: <AddCircleIcon />,
    [STATUS.ENABLED]: <FilterCenterFocusIcon />,
    [STATUS.BOOKABLE]: <PlaylistAddIcon />,
    [STATUS.BOOKED]: <PlaylistAddCheckIcon />,
    [STATUS.EXTERNAL]: <FilterCenterFocusIcon />,
  };

  const campaignStatus = useMemo(() => {
    if (!bmapi.checkIfLoggedIn()) {
      return !campaign.link_distribution
        ? STATUS.NO_PERMISSION
        : STATUS.ANONYMOUS;
    }
    if (bmapi.needsVerification()) {
      return STATUS.DISABLED_ACCOUNT;
    }

    if (endOfDay(new Date(campaign.expiration_date)) < new Date()) {
      return STATUS.CAMPAIGN_ENDED;
    }
    if (
      productsReceived > 0 &&
      availability === 0 &&
      campaign.type !== PRODUCT_TYPES.CAMPAIGN_EARNING_CARD
    ) {
      return STATUS.ALL_PRODUCTS_USED;
    }

    if (productsReceived > 0) {
      if (new Date(campaign.start_date) > new Date()) {
        return STATUS.CAMPAIGN_NOT_STARTED;
      }

      return !campaign.external_ids_format ? STATUS.ENABLED : STATUS.EXTERNAL;
    }

    if (reserved) {
      return STATUS.BOOKED;
    }

    if (
      campaign.max_issue_number !== 0 &&
      campaign.max_issue_number <= issuedQuantity
    ) {
      if (campaign.waiting_list) {
        return STATUS.BOOKABLE;
      }

      return STATUS.NO_PRODUCTS_AVAILABLE;
    }

    if (campaign.link_distribution) {
      return STATUS.REEDEMABLE;
    }

    return STATUS.NOT_AVAILABLE;
  }, [
    bmapi,
    campaign,
    availability,
    issuedQuantity,
    productsReceived,
    reserved,
  ]);

  useEffect(() => {
    return () => {
      if (updateInterval) {
        clearInterval(updateInterval);
      }
    };
  }, [updateInterval]);

  const joinWaitingList = useCallback(() => {
    startLoading();

    return bmapi
      .reserveCampaign(campaign.id)
      .then(onUpdate)
      .catch(notifyError)
      .finally(stopLoading);
  }, [bmapi, campaign.id, onUpdate, notifyError, startLoading, stopLoading]);

  const showExternalCode = useCallback(
    (productId) => {
      startLoading();

      return bmapi
        .getExternalCode(productId)
        .then((code) => {
          if (campaign.external_ids_format === CODE_FORMATS.QR_CODE) {
            return QRCodeGenerator.toDataURL(code, { scale: 16 }).then(
              setImage
            );
          } else if (campaign.external_ids_format === CODE_FORMATS.STRING) {
            return setCode(code);
          }
        })
        .catch(notifyError)
        .finally(stopLoading);
    },
    [
      startLoading,
      bmapi,
      notifyError,
      stopLoading,
      campaign.external_ids_format,
    ]
  );

  const showQrCode = useCallback(
    (productId) => {
      function checkQrCode(codeExpiration) {
        if (
          codeExpiration &&
          new Date(codeExpiration) - new Date() - updateIntervalTime <= 0
        ) {
          showQrCode(productId);
        }
      }

      startLoading();

      return bmapi
        .createPermissionCode(productId)
        .then((code) => {
          setUpdateInterval(
            setInterval(() => checkQrCode(code.expire_at), updateIntervalTime)
          );
          return QRCodeGenerator.toDataURL(code.id, { scale: 16 });
        })
        .then(setImage)
        .catch(notifyError)
        .finally(stopLoading);
    },
    [startLoading, bmapi, updateIntervalTime, notifyError, stopLoading]
  );

  const hideQrCode = () => {
    setImage(false);
    setCode(false);
    if (!campaign.external_ids_format) {
      clearInterval(updateInterval);
      onUpdate();
    }
  };

  const isDisabled = campaignStatus <= 0;

  const acceptProduct = () => {
    startLoading();
    bmapi
      .acceptProduct(campaign.id)
      .then(() => {
        notifySuccess("Prodotto ricevuto");
        onUpdate();
      })
      .catch(notifyError)
      .finally(() => {
        stopLoading();
        setShowConfirm(false);
        setAcceptTOS(!campaign.tos_consumer_url);
      });
  };

  const handleClick = (event) => {
    if (campaignStatus === STATUS.REEDEMABLE) {
      setShowConfirm(true);
    } else if (
      [STATUS.ANONYMOUS, STATUS.NO_PERMISSION].includes(campaignStatus)
    ) {
      bmapi.setCallbackUrl(window.location.href);
      history.push(CONSUMER_ROUTES.HOME);
    } else if (Array.isArray(product) && product.length > 1) {
      setAnchorEl(event.currentTarget);
    } else if (campaignStatus === STATUS.BOOKABLE) {
      joinWaitingList();
    } else if (campaignStatus === STATUS.EXTERNAL) {
      showExternalCode(Array.isArray(product) ? product[0].id : product.id);
    } else {
      showQrCode(Array.isArray(product) ? product[0].id : product.id);
    }
  };

  const handleChoice = (id) => {
    setAnchorEl(false);
    if (!!campaign.external_ids_format) return showExternalCode(id);
    return showQrCode(id);
  };

  const copyCode = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => notifySuccess("Codice copiato con successo"))
      .catch(notifyError);
  };

  const CampaignIcon = IconsMap[campaign.rules.subtype];

  return (
    <React.Fragment>
      <Dialog
        onClose={hideQrCode}
        open={!!image || !!code}
        maxWidth={"sm"}
        style={{ alignItems: "center", textAlign: "center" }}
      >
        <DialogTitle>{campaign.name}</DialogTitle>
        {!campaign.external_ids_format && (
          <DialogContent>
            Esponi questo QR Code all'addetto del punto vendita per utilizzare{" "}
            {actionLabel[campaign.type]}
          </DialogContent>
        )}
        <DialogContent>
          {image ? (
            <img src={image || ""} alt="QR Code" style={{ maxWidth: "100%" }} />
          ) : (
            <React.Fragment>
              <div>
                <Button onClick={copyCode}>
                  <Typography
                    variant="h5"
                    style={{ wordBreak: "break-all", textTransform: "none" }}
                  >
                    {code}
                  </Typography>
                </Button>
              </div>
              <div>
                <Button onClick={copyCode}>
                  <Typography variant="caption">
                    Tocca sul codice per copiarlo
                  </Typography>
                </Button>
              </div>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          {!campaignId && campaign.external_ids_format && (
            <Button
              component={RouterLink}
              to={CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", campaign.id)}
            >
              Istruzioni
            </Button>
          )}
          <Button
            autoFocus
            onClick={hideQrCode}
            color="primary"
            variant="contained"
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>

      <Confirm
        open={showConfirm}
        onConfirm={acceptProduct}
        onCancel={() => setShowConfirm(false)}
        disabled={!acceptTOS}
      >
        <Typography variant="body1" gutterBottom>
          Sei sicuro di voler ottenere {actionLabel[campaign.type]}{" "}
          <strong>{campaign.name}</strong>?
        </Typography>

        {campaign.tos_consumer_url && (
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTOS}
                onChange={(_, f) => setAcceptTOS(f)}
                name="flag"
                color="primary"
              />
            }
            label={
              <Typography variant="caption">
                Dichiaro di avere letto e approvato il regolamento della
                campagna le condizioni di adesione pubblicate su{" "}
                <a
                  href={campaign.tos_consumer_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  questa pagina
                </a>
              </Typography>
            }
          />
        )}
      </Confirm>

      {Array.isArray(product) && (
        <React.Fragment>
          <Backdrop
            open={Boolean(anchorEl)}
            onClick={() => setAnchorEl(null)}
            style={{ zIndex: 1290 }}
          />

          <Menu
            anchorEl={anchorEl}
            keepMounted={false}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {product.map((prod) => (
              <MenuItem onClick={() => handleChoice(prod.id)} key={prod.id}>
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`Valore residuo: ${prod.available_value / 100} ${
                    prod.currency
                  }`}
                  secondary={`Scadenza: ${new Date(
                    prod.expiration_date
                  ).toLocaleDateString("it-IT")}`}
                />
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}

      {!!campaignStatus && (
        <Button
          color="primary"
          onClick={handleClick}
          startIcon={btnIcon[campaignStatus]}
          disabled={isDisabled}
        >
          {btnText[campaignStatus]}
        </Button>
      )}
    </React.Fragment>
  );
}

export default function Product({
  campaign,
  products,
  issuedQuantity,
  availability,
  onUpdate = () => {},
  link = false,
  compact = false,
  reserved = false,
}) {
  const classes = styles.useStyles();
  const { bmapi } = useBmapi();

  const [bg] = useState(campaign.image_url || bmapi.createBg(campaign.name));

  // const CampaignIcon = IconsMap[campaign.rules.subtype];

  function getProductExpiration(campaignId) {
    const nextProd = getCampaignProducts(campaignId)[0];
    return nextProd && nextProd.expiration_date;
  }

  function getCampaignProducts(campaignId) {
    return products
      .filter((p) => p.campaign_id === campaignId)
      .filter((p) => p.status === 0)
      .sort(
        (a, b) => new Date(a.expiration_date) - new Date(b.expiration_date)
      );
  }

  function getToUseProduct(campaignId) {
    return PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE !== campaign.rules.subtype
      ? getCampaignProducts(campaignId)[0]
      : getCampaignProducts(campaignId).reduce(
          (acc, cur) =>
            acc.some((p) => p.available_value === cur.available_value)
              ? acc
              : [...acc, cur],
          []
        );
  }

  const OptionalActionArea = ({ children }) => {
    return link ? (
      <CardActionArea component={RouterLink} to={link}>
        {children}
      </CardActionArea>
    ) : (
      children
    );
  };

  const hasPrize = () => {
    return (
      campaign.type === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD &&
      campaign.rules.prizes &&
      Array.isArray(campaign.rules.prizes) &&
      campaign.rules.prizes.sort((a, b) => a.threshold - b.threshold)[0]
        .threshold /
        100 <
        availability
    );
  };

  return (
    <Card style={{ position: "relative" }}>
      <OptionalActionArea>
        {campaign.demo && <div className={classes.demoRibbon}>Demo</div>}
        <CardMedia
          className={classes.cardMedia}
          image={campaign.image_url || bg}
        />
        <CardHeader
          title={campaign.name}
          titleTypographyProps={{ variant: "h5" }}
          subheader={
            compact
              ? getSignificantDateInfo(
                  campaign,
                  getProductExpiration(campaign.id),
                  availability
                )
              : campaign.business_name
          }
        />
        {!compact && products?.length > 0 && (
          <CardContent>
            <Typography variant="body2">
              {getStatus(campaign, availability)}
            </Typography>
            <Typography variant="body2">
              {getSignificantDateInfo(
                campaign,
                getProductExpiration(campaign.id),
                availability
              )}
            </Typography>
          </CardContent>
        )}
      </OptionalActionArea>

      <CardActions disableSpacing>
        <Box>
          {bmapi.can(FEATURES.CALENDAR) &&
            campaign.type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS && (
              <Button
                component={RouterLink}
                to={CONSUMER_ROUTES.CALENDAR.replace(
                  ":campaignId",
                  campaign.rules.main_event_id || campaign.id
                )}
                startIcon={<EventIcon />}
                color="primary"
              >
                Partecipa agli eventi
              </Button>
            )}
          {products && (
            <UseProduct
              campaign={campaign}
              issuedQuantity={issuedQuantity}
              product={getToUseProduct(campaign.id)}
              productsReceived={products.length}
              availability={availability}
              onUpdate={onUpdate}
              reserved={reserved}
            />
          )}
        </Box>

        <Box style={{ marginLeft: "auto" }}>
          {link && (
            <IconButton component={RouterLink} to={link}>
              {hasPrize() ? <EmojiEventsIcon color="primary" /> : <InfoIcon />}
            </IconButton>
          )}
        </Box>
      </CardActions>
    </Card>
  );
}
