import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Hidden,
  Typography
} from "@material-ui/core";

export default function InputImage({ image, emptyText, onChange }) {
  const handleCapture = event => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = e => onChange(file, e.target.result);
  };

  return (
    <Card>
      <CardActionArea>
        <label style={{ cursor: "pointer" }}>
          {image ? (
            <CardMedia
              style={{ height: 0, paddingTop: "100%" }}
              image={image}
              title="image"
            />
          ) : (
            <Box p={2}>
              <Typography variant="subtitle1">{emptyText}</Typography>
            </Box>
          )}
          <Hidden only={["xs", "sm", "md", "lg", "xl"]} implementation="css">
            <input accept="image/*" onChange={handleCapture} type="file" />
          </Hidden>
        </label>
      </CardActionArea>
    </Card>
  );
}
