import React, { useState, useCallback, useEffect } from "react";

import { useBmapi } from "../../utils/bmapi-context";
import QrScanner from "../../ui/QrScanner";
import FormManager from "../../ui/FormManager";

export default function Scanner() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [productInfo, setProductInfo] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  const reset = useCallback(() => {
    setProductInfo(null);
    setQrCode(null);
  }, [setProductInfo, setQrCode]);

  const onError = useCallback(
    (error) => {
      stopLoading();
      notifyError(error);
    },
    [notifyError, stopLoading]
  );

  const sendCode = useCallback(
    (code) => {
      if (code && bmapi.validateUUID(code)) {
        startLoading();

        bmapi.getProductInfo(code).then(setProductInfo).catch(onError);
      } else {
        onError("Codice scannerizzato non valido");
      }
    },
    [bmapi, onError, startLoading]
  );

  useEffect(() => {
    if (qrCode) sendCode(qrCode);
  }, [qrCode, sendCode]);

  return productInfo ? (
    <FormManager
      onCancel={reset}
      onSuccess={reset}
      info={productInfo}
      qrCode={qrCode}
    />
  ) : (
    <QrScanner handleCode={setQrCode} handleError={notifyError} />
  );
}
