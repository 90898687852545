import React, { useState, useCallback, useEffect } from "react";
import {
  useParams,
  useHistory,
  Prompt,
  Link as RouterLink,
} from "react-router-dom";
import { format, addDays, addMonths, endOfMonth, endOfDay } from "date-fns";

import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  IconButton,
} from "@material-ui/core";
import {
  ArrowBack,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@material-ui/icons";

import Title from "../../ui/Title";
import CommonCreateForm from "../../ui/forms/CommonCreateForm";
import Subtype from "../../ui/forms/input/Subtype";

import { MERCHANT_ROUTES, PRODUCT_SUBTYPES } from "../../utils/constants";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns, typeMap } from "../../utils/campaigns";

const formatDate = (date) => (date ? format(date, "yyyy-MM-dd") : "");
const formatTime = (date) => (date ? format(date, "HH:mm") : "");
const getLabel = (subtype) => {
  if (subtype.includes("COUPON")) return "coupon";
  if (subtype.includes("PASS")) return "pass";
  return "card";
};

function Navigation() {
  return (
    <FormControl margin="normal" fullWidth>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item>
          <Button
            component={RouterLink}
            to={MERCHANT_ROUTES.CAMPAIGNS}
            startIcon={<ArrowBackIcon />}
          >
            Torna alle campagne
          </Button>
        </Grid>
        <Grid item>
          <Button
            component={RouterLink}
            to={MERCHANT_ROUTES.HOME}
            startIcon={<ArrowForwardIcon />}
          >
            Vai alla home
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}

const formDefault = {
  _common: {
    auto_join: false,
    business_id: "",
    cover: false,
    currency: "Euro",
    description: "",
    demo: false,
    end_hour: null,
    expiration_date: null,
    external_ids_format: "",
    grace_period: "",
    icon: false,
    link_distribution: false,
    loop: false,
    mailing_list: "",
    main_event_id: "",
    max_issue_number: "",
    max_products_per_user: "",
    name: "",
    prizes: [],
    product_lifespan: "",
    product_limit_date: null,
    products_name: "",
    quantum: "",
    rate: "",
    shot_number: "1",
    start_date: addDays(new Date(), 1),
    start_hour: null,
    subtype: "",
    tos_consumer_url: "",
    tos_merchant_url: "",
    type: "",
    use_frequency: "",
    use_period: "",
    value: "",
    waiting_list: false,
  },
  [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: {
    currency: "%",
    expiration_date: endOfMonth(addMonths(new Date(), 6)),
    product_lifespan: "3",
  },
  [PRODUCT_SUBTYPES.COUPON_SIMPLE]: {
    expiration_date: endOfMonth(addMonths(new Date(), 6)),
    product_lifespan: "3",
  },
  [PRODUCT_SUBTYPES.COUPON_VALUE]: {
    expiration_date: endOfMonth(addMonths(new Date(), 6)),
    product_lifespan: "3",
  },
  [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: {
    currency: "Punti",
    expiration_date: endOfMonth(addMonths(new Date(), 12)),
    product_limit_date: endOfMonth(addMonths(new Date(), 12)),
  },
  [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: {
    max_products_per_user: "1",
  },
  [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: {
    expiration_date: endOfMonth(addMonths(new Date(), 6)),
    product_lifespan: "12",
  },
};

const getInitialState = (subtype, bmapi, values) => ({
  ...formDefault._common,
  ...(!bmapi
    ? {}
    : {
        auto_join:
          bmapi.getUserInfo().business.type === "Loop" && !!values?.auto_join,
        business_id: bmapi.getUserInfo().business.id,
        demo: !!bmapi.settings.demo,
        loop: bmapi.getUserInfo().business.type === "Loop",
      }),
  ...(!values
    ? {}
    : {
        cover: values.cover,
        description: values.description,
        demo: values.demo,
        end_hour: values.end_hour,
        expiration_date: values.expiration_date,
        icon: values.icon,
        name: values.name,
        products_name: values.products_name,
        start_date: values.start_date,
        start_hour: values.start_hour,
        tos_consumer_url: values.tos_consumer_url,
        tos_merchant_url: values.tos_merchant_url,
      }),
  ...formDefault[subtype],
  ...{
    subtype: subtype,
    type: typeMap[subtype],
  },
});

export default function CreateCampaign() {
  const { subtype: startType = "" } = useParams();
  const history = useHistory();
  const { campaigns, loadCampaigns } = useCampaigns();
  const {
    bmapi,
    businessId,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();

  const [dirty, setDirty] = useState(false);
  const [values, setValues] = useState(
    getInitialState(startType.toUpperCase(), bmapi)
  );

  useEffect(() => {
    setValues((vs) => getInitialState(vs.subtype, bmapi));
  }, [businessId, bmapi]);

  const handleChange = useCallback(
    (label) => {
      const updateValue = (val) => {
        setDirty(true);
        setValues((v) => ({ ...v, [label]: val }));
      };

      const updateSubtype = (e) => {
        setDirty(false);
        setValues((vs) => getInitialState(e.target.value, bmapi, vs));
      };

      const parseInput = (i, f) => {
        if (typeof f === "boolean") updateValue(f);
        else if (i?.target) updateValue(i.target.value);
        else updateValue(i);
      };

      return label === "subtype" ? updateSubtype : parseInput;
    },
    [bmapi]
  );

  useEffect(() => {
    history.replace(
      MERCHANT_ROUTES.CREATE_CAMPAIGN.replace(
        ":subtype?/",
        !values.subtype ? "" : `${values.subtype.toLowerCase()}/`
      )
    );
  }, [values.subtype, history]);

  const checkPrizes = useCallback(() => {
    return !values.prizes
      .map(
        (p) =>
          new Date(
            campaigns.find((c) => c.campaign_id === p.prize_id).expiration_date
          )
      )
      .some((exp) => endOfDay(exp) < endOfDay(values.product_limit_date));
  }, [values, campaigns]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!checkPrizes()) {
        return notifyError("Scadenza dei premi non compatibile");
      }

      const getFormattedValues = () => ({
        ...values,
        end_hour: formatTime(values.end_hour),
        expiration_date: formatDate(values.expiration_date),
        grace_period: parseInt(values.grace_period) || 0,
        max_issue_number:
          parseInt(values.max_issue_number) || (values.waiting_list ? -1 : 0),
        max_products_per_user: parseInt(values.max_products_per_user) || 0,
        product_lifespan: parseInt(values.product_lifespan) || 0,
        product_limit_date: formatDate(values.product_limit_date),
        quantum: parseInt(values.quantum * 100) || 100,
        rate: parseInt(values.rate * 100) || 100,
        shot_number: parseInt(values.shot_number) || 0,
        start_date: formatDate(values.start_date),
        start_hour: formatTime(values.start_hour),
        use_frequency: parseInt(values.use_frequency) || 0,
        use_period: parseInt(values.use_period) || 0,
        value: parseInt(values.value * 100) || 0,
      });

      startLoading();

      return bmapi
        .createCampaign(getFormattedValues())
        .then((c) =>
          values.cover ? bmapi.uploadCampaignCover(c.id, values.cover) : c
        )
        .then((c) =>
          values.icon ? bmapi.uploadCampaignIcon(c.id, values.icon) : c
        )
        .then(() => {
          setDirty(false);
          notifySuccess("Campagna creata con successo");
          history.push(MERCHANT_ROUTES.CAMPAIGNS);
        })
        .catch((e) => {
          notifyError(e);
          stopLoading();
        });
    },
    [
      bmapi,
      values,
      checkPrizes,
      history,
      notifySuccess,
      notifyError,
      startLoading,
      stopLoading,
    ]
  );

  useEffect(() => {
    if (
      !campaigns &&
      [
        PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE,
        PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
      ].includes(values.subtype)
    ) {
      loadCampaigns();
    }
  }, [values.subtype, campaigns, loadCampaigns]);

  return (
    <Container maxWidth="sm">
      <Title>
        <IconButton
          component={RouterLink}
          to={MERCHANT_ROUTES.CAMPAIGNS}
          size="small"
        >
          <ArrowBack />
        </IconButton>{" "}
        Crea una campagna per{" "}
        <strong>{bmapi.getUserInfo().business.name}</strong>
      </Title>

      <Prompt
        when={dirty}
        message="Ci sono modifiche non salvate. Sei sicuro di voler uscire?"
      />

      <Card>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Subtype values={values} handleChange={handleChange} />

            {!!values.subtype && (
              <React.Fragment>
                <CommonCreateForm
                  values={values}
                  handleChange={handleChange}
                  campaigns={campaigns}
                  label={getLabel(values.subtype)}
                />
                <FormControl fullWidth margin="normal">
                  <Button variant="contained" color="primary" type="submit">
                    Crea
                  </Button>
                </FormControl>
              </React.Fragment>
            )}
          </form>
        </CardContent>
      </Card>
      <Navigation />
    </Container>
  );
}
