import React, { useEffect } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import {
  FEATURES,
  MERCHANT_ROUTES as ROUTES,
  TENANTS,
} from "../utils/constants";
import { useBmapi } from "../utils/bmapi-context";
import { merchant as links } from "../utils/pages";

import AccountPage from "../pages/merchant/Account";
import CampaignsPage from "../pages/merchant/Campaigns";
import CreateCampaignPage from "../pages/merchant/CreateCampaign";
// import ExportPage from "../pages/merchant/Export";
import HomePage from "../pages/merchant/Home";
import MoreInfo from "../pages/merchant/MoreInfo";
import LandingPage from "../pages/merchant/Landing";
import PasswordForgetPage from "../pages/merchant/PasswordReset";
// import PromosPage from "../pages/merchant/Promos";
import Reservations from "../pages/merchant/Reservations";
import ScannerPage from "../pages/merchant/Scanner";
import SendPointsPage from "../pages/merchant/SendPoints";
import SignInMerchantPage from "../pages/merchant/SignIn";
import SignUpMerchantPage from "../pages/merchant/SignUp";
// import StoresPage from "../pages/merchant/Stores";
// import TenantSettingsPage from "../pages/merchant/Tenant";

import LuccaStats from "../pages/custom/lcng/LuccaStats";

import Navbar from "./Navbar";
import Content from "../ui/Content";

export default function RoutingMerchant() {
  const { bmapi, userId } = useBmapi();
  const location = useLocation();

  useEffect(() => window.scrollTo(0, 0), [location.pathname]);

  return !userId ? (
    <MuiThemeProvider theme={bmapi.theme.login}>
      <CssBaseline />
      <Switch>
        <Route path={ROUTES.SIGN_IN_ACTION} component={SignInMerchantPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInMerchantPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpMerchantPage} />
        <Route path={ROUTES.INFO} component={MoreInfo} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route exact path={ROUTES.HOME} component={LandingPage} />
        <Redirect to={ROUTES.HOME} />
      </Switch>
    </MuiThemeProvider>
  ) : (
    <React.Fragment>
      <Navbar links={links} routes={ROUTES} />
      <Content>
        <Switch>
          {bmapi.can(FEATURES.MANAGE_CAMPAIGN) && (
            <Route
              path={ROUTES.CREATE_CAMPAIGN}
              component={CreateCampaignPage}
            />
          )}
          <Route exact path={ROUTES.RESERVATIONS} component={Reservations} />
          <Route exact path={ROUTES.CAMPAIGNS} component={CampaignsPage} />
          {/* <Route path={ROUTES.STORES} component={StoresPage} /> */}
          <Route path={ROUTES.SCAN} component={ScannerPage} />
          {bmapi.can(FEATURES.SEND_POINTS) && (
            <Route path={ROUTES.SEND_POINTS} component={SendPointsPage} />
          )}
          {bmapi.tenant === TENANTS.LUCCA && (
            <Route path={ROUTES.LUCCA_STATS} component={LuccaStats} />
          )}
          {/* <Route path={ROUTES.PROMOS} component={PromosPage} /> */}
          {/* <Route path={ROUTES.TENANT_SETTINGS} component={TenantSettingsPage} /> */}
          {/* <Route path={ROUTES.EXPORT} component={ExportPage} /> */}
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Redirect to={ROUTES.HOME} />
        </Switch>
      </Content>
    </React.Fragment>
  );
}
