import React from "react";
import { useHistory } from "react-router-dom";

import { useBmapi } from "../utils/bmapi-context";
import SignUpForm from "../ui/SignUpForm";

export default function SignUp({ routes }) {
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const history = useHistory();

  function onSubmit(email, password) {
    startLoading();

    bmapi
      .doCreateUserWithEmailAndPassword(email, password)
      .then(() => {
        if (!bmapi.getUserInfo().user_id) {
          history.push(routes.SIGN_IN);
          stopLoading();
          notifySuccess(
            "Completa la registrazione cliccando il link ricevuto via email"
          );
        }
      })
      .catch((e) => {
        notifyError(e);
        stopLoading();
      });
  }

  return <SignUpForm onSubmit={onSubmit} routes={routes} />;
}
