import React from "react";
import { useHistory } from "react-router-dom";

import { useBmapi } from "../utils/bmapi-context";
import TopBar from "../ui/TopBar";

export default function Navbar({ links, routes }) {
  const { bmapi } = useBmapi();
  const history = useHistory();
  const { role, business } = bmapi.getUserInfo();

  function canView(link) {
    return (
      (!link.feature || bmapi.can(link.feature)) &&
      (!link.roles || bmapi.validateRoles(link.roles, role)) &&
      (!link.tenants || link.tenants.includes(bmapi.tenant))
    );
  }

  function getLinks(links) {
    return links.filter(canView).map((link) => ({
      ...link,
      active: link.url === history.location.pathname,
    }));
  }

  return (
    <TopBar
      links={getLinks(links.pages)}
      logo={business.logo || bmapi.logo}
      routes={routes}
      userActions={getLinks(links.actions(bmapi))}
    />
  );
}
