import React, { useState, useCallback } from "react";
import QrReader from "react-qr-reader";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  CenterFocusStrong as CenterFocusIcon,
  Loyalty as LoyaltyIcon,
  Person as PersonIcon,
  SwapVert as SwapVertIcon,
} from "@material-ui/icons";

import ListItemInfo from "./ListItemInfo";
import NumberInput from "./NumberInput";

import { useBmapi } from "../utils/bmapi-context";
import { USER_PREFIX, PRODUCT_SUBTYPES } from "../utils/constants";

export default function IssueProduct({ campaign, open, onClose }) {
  const {
    bmapi,
    notifySuccess,
    notifyError,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [scanning, setScanning] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [email, setEmail] = useState("");

  const handleClose = useCallback(() => {
    setScanning(false);
    setEmail("");
    setQuantity(1);
    onClose();
  }, [onClose]);

  const loadUser = useCallback(
    (userId) => {
      startLoading();

      bmapi
        .getUser(userId)
        .then((user) => {
          setScanning(false);
          setEmail(user.email);
        })
        .catch((e) => {
          notifyError(e);
          handleClose();
        })
        .finally(stopLoading);
    },
    [bmapi, handleClose, notifyError, startLoading, stopLoading]
  );

  const handleScan = useCallback(
    (code) => {
      if (code && code.startsWith(USER_PREFIX)) {
        startLoading();
        loadUser(code.replace(USER_PREFIX, ""));
      }
    },
    [startLoading, loadUser]
  );

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleSend = useCallback(
    (event) => {
      event.preventDefault();
      startLoading();
      bmapi
        .issueCampaign(campaign.id, {
          user: email,
          quantity: parseInt(quantity),
        })
        .then(() => {
          handleClose();
          notifySuccess(
            `Hai inviato con successo la campagna ${campaign.name}`
          );
        })
        .catch((e) => {
          e.message === "Entity not found"
            ? notifyError("Email non registrata")
            : notifyError(e);
        })
        .finally(stopLoading);
    },
    [
      bmapi,
      campaign.id,
      campaign.name,
      quantity,
      email,
      startLoading,
      stopLoading,
      handleClose,
      notifyError,
      notifySuccess,
    ]
  );

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={"sm"} fullWidth>
      {open &&
        (scanning ? (
          <React.Fragment>
            <DialogTitle>Scansiona il codice utente</DialogTitle>
            <DialogContent>
              <QrReader
                onError={notifyError}
                onScan={handleScan}
                style={{ maxWidth: "100%", width: "100vw" }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setScanning(false)} color="primary">
                Indietro
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <form onSubmit={handleSend}>
            <DialogTitle>Conferma invio</DialogTitle>
            <DialogContent>
              <List>
                <ListItemInfo
                  Icon={LoyaltyIcon}
                  label="Campagna"
                  text={campaign.name}
                />
                <ListItemInfo Icon={PersonIcon}>
                  <TextField
                    label="Email"
                    fullWidth
                    variant="filled"
                    value={email}
                    onChange={handleChangeEmail}
                    type="email"
                    required
                    autoFocus
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Scan QR Code"
                            onClick={() => setScanning(true)}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <CenterFocusIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </ListItemInfo>
                {campaign.type !== PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE && (
                  <ListItemInfo Icon={SwapVertIcon}>
                    <NumberInput
                      label="Quantità"
                      fullWidth
                      variant="filled"
                      value={quantity}
                      required
                      onChange={(e) => setQuantity(e.target.value)}
                      min={1}
                      max={50}
                    />
                  </ListItemInfo>
                )}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Annulla
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Invia
              </Button>
            </DialogActions>
          </form>
        ))}
    </Dialog>
  );
}
