import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Visibility, VisibilityOff } from "@material-ui/icons";

import LoginCard from "./LoginCard";

export default function SignInForm({ onSubmit, routes }) {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit(
      event.target.email.value.trim().toLowerCase(),
      event.target.password.value
    );
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  const backButton = (
    <Button
      onClick={() => history.push(routes.HOME)}
      startIcon={<ArrowBack />}
      color="inherit"
    >
      Indietro
    </Button>
  );

  return (
    <LoginCard back={backButton}>
      <Typography>
        Se sei già registrato inserisci le credenziali del tuo account
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">
            <FormattedMessage id="page-signin--email" />
          </InputLabel>
          <Input
            id="email"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">
            <FormattedMessage id="page-signin--password" />
          </InputLabel>
          <Input
            name="password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Button type="submit" fullWidth variant="contained" color="primary">
            <FormattedMessage id="page-signin--title" />
          </Button>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Link component={RouterLink} to={routes.PASSWORD_FORGET}>
            <FormattedMessage id="app-forgotpassword--link--forgot-question" />
          </Link>
        </FormControl>
        <Box mt={6}>
          <Divider variant="middle" />
        </Box>
        <FormControl margin="normal" fullWidth>
          <Typography>
            Se non sei in possesso di un account, registrati!
          </Typography>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => history.push(routes.SIGN_UP)}
          >
            <FormattedMessage id="app-signup--link--create-new" />
          </Button>
        </FormControl>
      </form>
    </LoginCard>
  );
}
