import React, { useState } from "react";

import {
  CardMedia,
  Fab,
  FormControl,
  Hidden,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

import { useBmapi } from "../../../utils/bmapi-context";
import styles from "../../../utils/styles";

export default function Cover({ values, handleChange, label }) {
  const { bmapi } = useBmapi();
  const classes = styles.useStyles();
  const [coverPreview, setCoverPreview] = useState(false);

  const removeCover = () => {
    setCoverPreview(false);
    handleChange("cover")(false);
  };

  const handleCapture = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => setCoverPreview(e.target.result);
    handleChange("cover")(file);
  };

  return (
    <FormControl margin="normal">
      <Typography variant="caption" gutterBottom>
        Immagine {label === "coupon" ? "del coupon" : "della card"}
      </Typography>

      <div className={classes.coverUpload}>
        <CardMedia
          style={{ height: 0, paddingTop: `${(10 / 16) * 100}%` }}
          image={coverPreview || bmapi.createBg(values.subtype)}
          title="Campaign cover"
        />
        <div className={classes.coverUploadButtons}>
          {values.cover && (
            <Fab color="secondary" aria-label="delete" onClick={removeCover}>
              <Delete />
            </Fab>
          )}
          <label>
            <Fab color="secondary" component="div" aria-label="edit">
              <Edit />
              <Hidden
                only={["xs", "sm", "md", "lg", "xl"]}
                implementation="css"
              >
                <input accept="image/*" onChange={handleCapture} type="file" />
              </Hidden>
            </Fab>
          </label>
        </div>
      </div>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        color="textSecondary"
      >
        Per una migliore resa consigliamo di caricare un'immagine in formato
        16:10 con una risoluzione di 1000 * 625 pixel
      </Typography>
    </FormControl>
  );
}
