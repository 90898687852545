import { useState, useCallback } from "react";
import {
  CardGiftcard as CardGiftcardIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Event as EventIcon,
  Loyalty as LoyaltyIcon,
} from "@material-ui/icons";

import {
  PRODUCT_TYPES,
  PRODUCT_SUBTYPES,
  ROLES,
  FEATURES,
  CAMPAIGN_STATUS,
} from "./constants";
import { useBmapi } from "./bmapi-context";

function alphasort(a, b) {
  return a.name.localeCompare(b.name);
}

export const IconsMap = {
  [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: ConfirmationNumberIcon,
  [PRODUCT_SUBTYPES.COUPON_SIMPLE]: ConfirmationNumberIcon,
  [PRODUCT_SUBTYPES.COUPON_VALUE]: ConfirmationNumberIcon,
  [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: LoyaltyIcon,
  [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: EventIcon,
  [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: CardGiftcardIcon,
};

export const typeMap = {
  [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: PRODUCT_TYPES.CAMPAIGN_COUPON,
  [PRODUCT_SUBTYPES.COUPON_SIMPLE]: PRODUCT_TYPES.CAMPAIGN_COUPON,
  [PRODUCT_SUBTYPES.COUPON_VALUE]: PRODUCT_TYPES.CAMPAIGN_COUPON,
  [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: PRODUCT_TYPES.CAMPAIGN_EARNING_CARD,
  [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: PRODUCT_TYPES.CAMPAIGN_EVENT_PASS,
  [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD,
};

export function useCampaigns() {
  const {
    bmapi,
    businessId,
    notifyError,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [campaigns, setCampaigns] = useState(null);

  const loadCampaigns = useCallback(async () => {
    startLoading();

    const canIssue = (campaign) => {
      const ownCampaign =
        bmapi.getUserInfo().role === ROLES.TENANT_MANAGER ||
        // &&  campaign.loop_campaign
        (bmapi.getUserInfo().role !== ROLES.TENANT_MANAGER &&
          bmapi.getUserInfo().business.id === campaign.business_owner_id);

      return (
        !campaign.toSign &&
        ownCampaign &&
        bmapi.can(FEATURES.ISSUE_PRODUCT) &&
        campaign.status !== CAMPAIGN_STATUS.EXPIRED
      );
    };

    return Promise.all([
      bmapi.getTerms(businessId),
      bmapi.getCampaigns(businessId),
    ])
      .then(([toSign, cs = []]) => {
        const campaigns = (cs || []).sort(alphasort).map((campaign) => {
          const terms = toSign.find(
            (c) => c.campaign_id === campaign.campaign_id
          );

          return {
            ...campaign,
            campaign_data: campaign.campaign_data || campaign.CampaignData, // Legacy API
            terms: terms || false,
            toSign: terms && !terms.signed,
            canIssue: canIssue(campaign),
          };
        });
        setCampaigns(campaigns);
        return campaigns;
      })
      .catch(notifyError)
      .finally(stopLoading);
  }, [bmapi, businessId, notifyError, startLoading, stopLoading]);

  const signCampaign = useCallback(
    (campaign) => bmapi.signTerm(campaign.terms.id),
    [bmapi]
  );

  return { campaigns, loadCampaigns, signCampaign };
}
