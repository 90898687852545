import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  List,
  Typography,
} from "@material-ui/core";
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Adjust as AdjustIcon,
  Euro as EuroIcon,
  CardGiftcard as CardGiftcardIcon,
  Person as PersonIcon,
  TrendingUp as TrendingUpIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import * as CONSTANTS from "../../utils/constants";
import { useBmapi } from "../../utils/bmapi-context";
import ListItemInfo from "../ListItemInfo";
import NumberInput from "../NumberInput";

export default function ShoppingCardActionForm({ info, owner, qrCode, rules }) {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [isValid, setIsValid] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    expense: "",
  });

  const getExpense = useCallback(
    () =>
      !values.expense.match(CONSTANTS.POSITIVE_FLOATING_NUMBER)
        ? 0
        : values.expense * 100,
    [values.expense]
  );

  const validateInput = useCallback(
    (exp) =>
      rules &&
      info &&
      exp > 0 &&
      exp >= Math.min(rules.min_expense, info.available_value) &&
      exp <= info.available_value,
    [rules, info]
  );

  const getError = useCallback(
    (exp) => {
      if (!rules || !info) {
        return "";
      }
      if (exp > info.available_value) {
        return "Credito non sufficiente";
      }

      if (exp > 0 && exp < Math.min(rules.min_expense, info.available_value)) {
        return "Non hai raggiunto la soglia minima di spesa";
      }
    },
    [rules, info]
  );

  useEffect(() => {
    if (rules && info) {
      setValues({
        expense: `${Math.min(rules.min_expense, info.available_value)}`,
      });
    }
  }, [rules, info]);

  useEffect(() => {
    setIsValid(validateInput(getExpense()));
  }, [values.expense, getExpense, validateInput]);

  function handleChange(valueLabel) {
    return (event) => {
      setValues({ ...values, [valueLabel]: event.target.value });
    };
  }

  function onSubmit(event) {
    event.preventDefault();
    if (
      rules.min_expense > getExpense() &&
      info.available_value > rules.min_expense
    ) {
      return notifyError(
        <FormattedMessage id="app-admin--giftCard--error-min-expense-not-respected" />
      );
    }

    startLoading();

    bmapi
      .decreaseCardBalance(
        qrCode,
        bmapi.getUserInfo().business.id,
        getExpense()
      )
      .then(() => setSuccess(true))
      .catch((e) => setError(bmapi.getErrorMessage(e)))
      .finally(stopLoading);
  }

  function getNewCardBalance() {
    return info.available_value - getExpense();
  }

  return (
    <Card>
      <CardContent>
        <form onSubmit={onSubmit}>
          <List>
            <ListItemInfo
              Icon={CardGiftcardIcon}
              label="Prodotto"
              text="Giftcard"
            />
            <ListItemInfo
              Icon={AdjustIcon}
              label="Campagna"
              text={info.campaign_name}
            />
            <ListItemInfo
              Icon={PersonIcon}
              label="Emessa a"
              text={owner && owner.email}
            />
            <ListItemInfo
              Icon={AccountBalanceWalletIcon}
              label="Saldo disponibile"
              text={
                <Typography color="primary">
                  <FormattedNumber
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    value={info.available_value / 100}
                  />{" "}
                  {info.currency}
                </Typography>
              }
            />
            <ListItemInfo Icon={EuroIcon}>
              <NumberInput
                label={<FormattedMessage id="app-admin--giftCard--expense" />}
                fullWidth
                variant="filled"
                value={values.expense}
                required
                onChange={handleChange("expense")}
                id="expense"
                name="expense"
                autoFocus
                float
                error={!isValid}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {info.currency}
                    </InputAdornment>
                  ),
                }}
                helperText={
                  rules && rules.min_expense > 0 ? (
                    <FormattedMessage
                      values={{
                        min: (
                          <FormattedNumber
                            maximumFractionDigits={2}
                            value={rules.min_expense / 100}
                          />
                        ),
                        currency: info.currency,
                      }}
                      id="app-admin--giftCard--min-expense-warning"
                    />
                  ) : (
                    getError(getExpense())
                  )
                }
              />
            </ListItemInfo>
            <ListItemInfo
              Icon={TrendingUpIcon}
              label="Saldo dopo utilizzo"
              text={
                <Typography color="primary">
                  <FormattedNumber
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    value={getNewCardBalance() / 100}
                  />{" "}
                  {info.currency}
                </Typography>
              }
            />
          </List>

          <FormControl fullWidth={true} margin="normal">
            {!success && !error ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isValid}
              >
                <FormattedMessage id="app-actions--confirm" />
              </Button>
            ) : (
              <Alert
                variant="filled"
                severity={success ? "success" : "error"}
                // action={
                //   <Button color="inherit" size="small" onClick={onSuccess}>
                //     {success ? "Nuova scansione" : "Riprova"}
                //   </Button>
                // }
              >
                {success ? "Transazione effettuata" : error}
              </Alert>
            )}
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}
