import React from "react";

import { TextField } from "@material-ui/core";

export default function Name({ values, handleChange }) {
  return (
    <TextField
      name="name"
      label="Nome"
      value={values.name}
      onChange={handleChange("name")}
      key="name"
      fullWidth
      margin="normal"
      required
    />
  );
}
