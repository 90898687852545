import React, { useState } from "react";

import { CONSUMER_ROUTES, PRODUCT_TYPES } from "../utils/constants";

import Product from "./Product";
import Grid from "./Grid";
import { Box, Button } from "@material-ui/core";

function getAvailability({ campaign, statistics }) {
  switch (campaign.type) {
    case PRODUCT_TYPES.CAMPAIGN_EARNING_CARD:
    case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
      return (
        (statistics.received_value -
          statistics.used_value -
          statistics.expired_value) /
        100
      );

    case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
    case PRODUCT_TYPES.CAMPAIGN_COUPON:
      return campaign.rules.shot_number === -1
        ? statistics.received_qty - statistics.expired_qty
        : statistics.received_qty * (campaign.rules.shot_number || 1) -
            statistics.used_qty -
            statistics.expired_qty;

    default:
      break;
  }

  return false;
}

export default function ProductsList({
  products = [],
  stats = [],
  updateData,
}) {
  const [showAll, setShowAll] = useState(false);

  const onlyAvailables = (p) =>
    getAvailability(p) ||
    p.campaign.type === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD;

  const notAvailable = (p) => !onlyAvailables(p);

  const getItems = () => {
    return [
      ...stats.filter(onlyAvailables),
      ...(showAll ? stats.filter(notAvailable) : []),
    ];
  };

  const sameCampaign = (campaign) => (p) =>
    p.campaign_id === campaign.campaign.id;

  const campaignLink = (campaign) =>
    CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", campaign.campaign.id);

  return (
    <React.Fragment>
      <Grid
        items={getItems()}
        emptyMsg="page-home--no-products-found"
        render={(campaign) => (
          <Product
            key={campaign.campaign.id}
            campaign={campaign.campaign}
            products={products.filter(sameCampaign(campaign))}
            availability={getAvailability(campaign)}
            onUpdate={updateData}
            link={campaignLink(campaign)}
            compact={true}
          />
        )}
      />
      {!showAll && stats.filter(notAvailable).length > 0 && (
        <Box my={4}>
          <Button
            variant="contained"
            color="default"
            onClick={() => setShowAll(true)}
          >
            Mostra tutte le card e coupon
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
}
