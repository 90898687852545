import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Container, FormControl, Button, Grid } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@material-ui/icons";

import CouponActionForm from "./forms/CouponActionForm";
import EventPassActionForm from "./forms/EventPassActionForm";
import EarningCardActionForm from "./forms/EarningCardActionForm";
import ShoppingCardActionForm from "./forms/ShoppingCardActionForm";

import { PRODUCT_SUBTYPES, MERCHANT_ROUTES } from "../utils/constants";
import { useBmapi } from "../utils/bmapi-context";

export default function FormManager({ info, qrCode, onSuccess, onCancel }) {
  const history = useHistory();
  const [ownerInfo, setOwnerInfo] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rules, setRules] = useState(undefined);
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();

  useEffect(() => {
    startLoading();

    Promise.all([
      bmapi.getCampaigns(),
      bmapi.fetchAuthenticated(info.owner_link.url),
      bmapi.fetchAuthenticated(info.transactions_link.url),
    ])
      .then(([campaigns, owner, txs]) => {
        setOwnerInfo(owner);
        setTransactions(txs);
        if (campaigns && campaigns.length > 0) {
          const c = campaigns.find((c) => c.campaign_id === info.campaign_id);

          return !c
            ? notifyError("Campagna appartente ad un negozio diverso")
            : bmapi.getRules(c.id).then(setRules);
        }
      })
      .catch(notifyError)
      .finally(() => {
        stopLoading();
        setLoading(false);
      });
  }, [bmapi, info, notifyError, startLoading, stopLoading, setLoading]);

  const Component = {
    [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: CouponActionForm,
    [PRODUCT_SUBTYPES.COUPON_SIMPLE]: CouponActionForm,
    [PRODUCT_SUBTYPES.COUPON_VALUE]: CouponActionForm,
    [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: EarningCardActionForm,
    [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: EventPassActionForm,
    [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: ShoppingCardActionForm,
  }[info.subtype];

  return !loading ? (
    <Container maxWidth="sm">
      <Component
        onCancel={onCancel}
        onSuccess={onSuccess}
        info={info}
        transactions={transactions}
        owner={ownerInfo}
        qrCode={qrCode}
        rules={rules}
      />
      <FormControl margin="normal" fullWidth>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <Button onClick={onCancel} startIcon={<ArrowBackIcon />}>
              Nuova scansione
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                history.push(MERCHANT_ROUTES.HOME);
              }}
              startIcon={<ArrowForwardIcon />}
            >
              Vai alla home
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Container>
  ) : null;
}
