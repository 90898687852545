import React from "react";

import { DatePicker } from "../../DatePicker";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function ExpirationDate({
  values,
  handleChange,
  label,
  campaigns,
}) {
  const getMaxDate = () => {
    return values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE &&
      values.main_event_id
      ? campaigns.find((c) => c.campaign_id === values.main_event_id)
          .expiration_date
      : new Date("2100-01-01");
  };

  const onChange = (val) => {
    handleChange("expiration_date")(val);
    if (
      (values.product_limit_date && values.product_limit_date < val) ||
      values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE
    ) {
      handleChange("product_limit_date")(val);
    }
  };

  return (
    <DatePicker
      label={
        label === "pass"
          ? "Data di fine evento"
          : `Data termine distribuzione ${label}`
      }
      value={values.expiration_date}
      onChange={onChange}
      key="expiration_date"
      fullWidth
      autoOk
      minDate={values.start_date}
      minDateMessage="Termine antecedente all'inizio della campagna"
      maxDate={getMaxDate()}
      required
    />
  );
}
