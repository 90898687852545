import React from "react";

import NumberInput from "../../NumberInput";
import { PRODUCT_TYPES } from "../../../utils/constants";

export default function UseFrequency({ values, handleChange }) {
  return (
    [PRODUCT_TYPES.CAMPAIGN_EVENT_PASS].includes(values.type) && (
      <NumberInput
        name="use_frequency"
        label="Numero massimo utilizzi nel periodo"
        value={values.use_frequency}
        onChange={handleChange("use_frequency")}
        fullWidth
        required={values.use_period !== ""}
        margin="normal"
        min={1}
      />
    )
  );
}
