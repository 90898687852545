import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import {
  ArrowForward,
  AccountBalance,
  ShoppingBasket,
  SwapHoriz,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import { CONSUMER_ROUTES } from "../../../utils/constants";
import { useBmapi } from "../../../utils/bmapi-context";
import Title from "../../../ui/Title";

export default function LuccaConfirm() {
  const location = useLocation();
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [status, setStatus] = useState(false);
  const [info] = useState(
    location.search
      .slice(1)
      .split("&")
      .map((p) => p.trim().split("="))
      .reduce((a, c) => ({ ...a, [c[0]]: c[1] }), {})
  );

  useEffect(() => {
    startLoading();

    bmapi
      .checkOrder(info.O, info.esito === "OK" ? "CONFIRMED" : "CANCELED")
      .then((e) => setStatus(e.Code))
      .catch((e) => setStatus(e.code))
      .finally(stopLoading);
  }, [bmapi, info, startLoading, stopLoading]);

  return (
    status && (
      <Container maxWidth="sm">
        <Title backUrl={CONSUMER_ROUTES.HOME}>Il tuo ordine</Title>
        <Box mb={2}>
          {status === "OK" && info.esito === "OK" ? (
            <Alert severity="success">Acquisto concluso correttamente</Alert>
          ) : (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  component={RouterLink}
                  to={CONSUMER_ROUTES.LUCCA_BUY}
                >
                  Riprova
                </Button>
              }
            >
              C'è stato un problema durante l'aquisto.
              <br />
              Per assistenza contattare{" "}
              <Link href="mailto:ordini@ekshop.it">ordini@ekshop.it</Link>
            </Alert>
          )}
        </Box>

        <Card>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AccountBalance />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Codice autorizzazione"
                secondary={info.codAut}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <SwapHoriz />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="ID Transazione"
                secondary={info.codTrans}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ShoppingBasket />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Codice ordine" secondary={info.O} />
            </ListItem>
          </List>
        </Card>

        <Box mt={2} textAlign="right">
          <Button
            component={RouterLink}
            to={CONSUMER_ROUTES.HOME}
            startIcon={<ArrowForward />}
          >
            Vai alla home
          </Button>
        </Box>
      </Container>
    )
  );
}
