import React from "react";

import { InputAdornment } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function GracePeriod({ values, handleChange, label }) {
  return (
    values.subtype !== PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
      <NumberInput
        name="grace_period"
        label="Tolleranza"
        value={values.grace_period}
        onChange={handleChange("grace_period")}
        key="grace_period"
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: <InputAdornment position="end">giorni</InputAdornment>,
        }}
        helperText={`Giorni extra di validità ${
          label === "coupon" ? "dei coupon" : "della card"
        } oltre la scadenza`}
      />
    )
  );
}
