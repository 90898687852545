import React from "react";
import { FormattedMessage } from "react-intl";

// import { Avatar } from "@material-ui/core";
import {
  AccountCircle as AccountCircleIcon,
  // CardGiftcard as GiftIcon,
  CenterFocusStrong as CenterFocusIcon,
  ExitToApp as ExitIcon,
  // GetApp as DownloadIcon,
  Home as HomeIcon,
  Loyalty as LoyaltyIcon,
  Send as SendIcon,
  // Settings as SettingsIcon,
  // Store as StoreIcon,
} from "@material-ui/icons";

import {
  CONSUMER_ROUTES,
  MERCHANT_ROUTES,
  ROLES,
  FEATURES,
  TENANTS,
} from "../utils/constants";

export const consumer = {
  pages: [
    {
      label: <FormattedMessage id="app-navbar--home" />,
      url: CONSUMER_ROUTES.HOME,
      icon: <HomeIcon />,
    },
    {
      label: <FormattedMessage id="app-navbar--qr-code" />,
      url: CONSUMER_ROUTES.QR_CODE,
      feature: FEATURES.MY_CODE,
      icon: <CenterFocusIcon />,
    },
    {
      label: "Campfire FAQ",
      url: CONSUMER_ROUTES.STATIC.replace(":content?/", "lucca-faq"),
      icon: <AccountCircleIcon />,
      tenants: [TENANTS.LUCCA],
    },
    {
      label: <FormattedMessage id="app-navbar--account" />,
      url: CONSUMER_ROUTES.ACCOUNT,
      icon: <AccountCircleIcon />,
      feature: FEATURES.ACCOUNT,
    },
  ],
  actions: (bmapi) => [
    {
      label: <FormattedMessage id="page-signin--logout" />,
      sublabel: bmapi.getUserInfo().token_info.email,
      action: () => bmapi.logout(),
      icon: <ExitIcon />,
      feature: FEATURES.LOGOUT,
    },
  ],
};

export const merchant = {
  pages: [
    {
      label: <FormattedMessage id="app-navbar--home" />,
      url: MERCHANT_ROUTES.HOME,
      icon: <HomeIcon />,
    },
    {
      label: <FormattedMessage id="app-navbar--campaigns" />,
      url: MERCHANT_ROUTES.CAMPAIGNS,
      icon: <LoyaltyIcon />,
    },
    {
      label: <FormattedMessage id="app-navbar--read-qrcode" />,
      url: MERCHANT_ROUTES.SCAN,
      icon: <CenterFocusIcon />,
      feature: FEATURES.SCAN_QR,
      roles: [ROLES.STORE_MANAGER, ROLES.GENERAL_MANAGER],
    },
    {
      label: "Campfire stats",
      url: MERCHANT_ROUTES.LUCCA_STATS,
      tenants: [TENANTS.LUCCA],
    },
    {
      label: <FormattedMessage id="app-navbar--send-points" />,
      url: MERCHANT_ROUTES.SEND_POINTS,
      icon: <SendIcon />,
      feature: FEATURES.SEND_POINTS,
    },
    // {
    //   label: <FormattedMessage id="app-navbar--select-merchant" />,
    //   url: MERCHANT_ROUTES.STORES,
    //   icon: <StoreIcon />,
    //   roles: [ROLES.TENANT_MANAGER, ROLES.STORE_MANAGER],
    // },
    // {
    //   label: <FormattedMessage id="app-navbar--manage-content" />,
    //   url: MERCHANT_ROUTES.PROMOS,
    //   icon: <GiftIcon />,
    // },
    // {
    //   label: <FormattedMessage id="app-navbar--export" />,
    //   url: MERCHANT_ROUTES.EXPORT,
    //   icon: <DownloadIcon />,
    // },
    // {
    //   label: <FormattedMessage id="app-navbar--tenant-configuration" />,
    //   url: MERCHANT_ROUTES.TENANT_SETTINGS,
    //   icon: <SettingsIcon />,
    //   roles: [ROLES.TENANT_MANAGER],
    // },
  ],
  actions: (bmapi) => [
    {
      label: <FormattedMessage id="app-navbar--account" />,
      sublabel: bmapi.getUserInfo().token_info.email,
      url: MERCHANT_ROUTES.ACCOUNT,
      icon: (
        <AccountCircleIcon />
        // <Avatar
        //   src={bmapi.userData.picture}
        //   style={{ width: 30, height: 30 }}
        // />
      ),
    },
    {
      label: <FormattedMessage id="page-signin--logout" />,
      action: () => bmapi.logout(),
      icon: <ExitIcon />,
    },
  ],
};
