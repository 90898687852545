/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSL representation
 */
export function rgbToHsl(red, green, blue) {
  const r = red / 255;
  const g = green / 255;
  const b = blue / 255;

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  return [h, s, l];
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  l       The lightness
 * @return  Array           The RGB representation
 */
export function hslToRgb(h, s, l) {
  var r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r * 255, g * 255, b * 255];
}

export function increaseBrightness(rgbcode, percent) {
  var r = parseInt(rgbcode.slice(1, 3), 16),
    g = parseInt(rgbcode.slice(3, 5), 16),
    b = parseInt(rgbcode.slice(5, 7), 16),
    HSL = rgbToHsl(r, g, b),
    newBrightness = HSL[2] + HSL[2] * (percent / 100),
    RGB;

  RGB = hslToRgb(HSL[0], HSL[1], newBrightness);
  rgbcode =
    "#" +
    convertToTwoDigitHexCodeFromDecimal(RGB[0]) +
    convertToTwoDigitHexCodeFromDecimal(RGB[1]) +
    convertToTwoDigitHexCodeFromDecimal(RGB[2]);

  return rgbcode;
}

export function convertToTwoDigitHexCodeFromDecimal(decimal) {
  var code = Math.round(decimal).toString(16);

  code.length > 1 || (code = "0" + code);
  return code;
}

function shortToLongHex(color) {
  if (color.length === 7) {
    return color;
  }
  return `#${color.charAt(1)}${color.charAt(1)}${color.charAt(2)}${color.charAt(
    2
  )}${color.charAt(3)}${color.charAt(3)}`;
}

export function createBgGradient(color) {
  const c = shortToLongHex(color);
  return [
    increaseBrightness(c, 60).slice(1),
    increaseBrightness(c, 50).slice(1),
    increaseBrightness(c, 40).slice(1),
    increaseBrightness(c, 30).slice(1),
    increaseBrightness(c, 20).slice(1),
    increaseBrightness(c, 10).slice(1),
    color,
    increaseBrightness(c, -10).slice(1),
    increaseBrightness(c, -20).slice(1),
  ];
}

export default {
  createBgGradient,
  hslToRgb,
  rgbToHsl,
  increaseBrightness,
};
