import React from "react";

import { DatePicker } from "../../DatePicker";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function StartDate({ values, handleChange, label, campaigns }) {
  const getMinDate = () => {
    return values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE &&
      values.main_event_id
      ? campaigns.find((c) => c.campaign_id === values.main_event_id).start_date
      : new Date();
  };

  const getMaxDate = () => {
    return values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE &&
      values.main_event_id
      ? campaigns.find((c) => c.campaign_id === values.main_event_id)
          .expiration_date
      : new Date("2100-01-01");
  };
  return (
    <DatePicker
      label={`Data di inizio ${label === "pass" ? "evento" : "campagna"}`}
      value={values.start_date}
      onChange={handleChange("start_date")}
      key="start_date"
      fullWidth
      autoOk
      required
      minDate={getMinDate()}
      maxDate={getMaxDate()}
    />
  );
}
