import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import { Box, Button, Container } from "@material-ui/core";
import { Add as AddIcon, ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import CampaignsTable from "../../ui/CampaignsTable";
import CreateCampaignButton from "../../ui/CreateCampaignButton";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import Title from "../../ui/Title";

import { useBmapi } from "../../utils/bmapi-context";
import { FEATURES, MERCHANT_ROUTES } from "../../utils/constants";
import { useCampaigns } from "../../utils/campaigns";

export default function Campaigns() {
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const { campaigns, signCampaign, loadCampaigns } = useCampaigns();

  useEffect(() => {
    !campaigns ? startLoading() : stopLoading();
  }, [campaigns, startLoading, stopLoading]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  return !!campaigns ? (
    <Container maxWidth="sm">
      <Title>
        <FormattedMessage id="app-navbar--campaigns" />
      </Title>
      <CampaignsTable
        campaigns={campaigns}
        signCampaign={signCampaign}
        loadCampaigns={loadCampaigns}
      />
      <Box my={4}>
        <Button
          component={RouterLink}
          to={MERCHANT_ROUTES.HOME}
          startIcon={<ArrowBackIcon />}
        >
          Torna alla home
        </Button>
      </Box>
      {bmapi.can(FEATURES.MANAGE_CAMPAIGN) && (
        <CreateCampaignButton
          trigger={(onClick) => (
            <FloatingActions>
              <Action
                icon={<AddIcon />}
                label="Crea campagna"
                action={onClick}
              />
            </FloatingActions>
          )}
        />
      )}
    </Container>
  ) : null;
}
