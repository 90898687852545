import Validator from "validator";
import trianglify from "trianglify";

import { createBgGradient } from "./colors";

export function validateRoles(roleWanted, userRole) {
  return Array.isArray(roleWanted)
    ? roleWanted.includes(userRole)
    : roleWanted === userRole;
}

export function validateUUID(id) {
  return Validator.isUUID(id);
}

export function validateEmail(e) {
  return Validator.isEmail(e);
}

export function download(blob) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute("download", "export.csv");
  document.body.appendChild(link);
  link.click();
}

export function checkTokenExpired(error) {
  return (
    error.httpCode === 401 &&
    error.code === "TOKEN_NOT_VALID" &&
    error.message.includes("ID token has expired at")
  );
}

class ApiError extends Error {
  constructor(httpCode, code, message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = "ApiError";
    this.code = code;
    this.httpCode = httpCode;
    this.message = message;
  }
}

export function handleError(response) {
  return response.json().then((data) => {
    throw new ApiError(data.HTTPCode, data.Code, data.Message);
  });
}

export function handleResponse(response) {
  if (!response.ok) return handleError(response);

  const contentType = response.headers.get("content-type") || "";
  if (contentType.includes("application/json")) {
    return response.json();
  } else if (contentType.includes("text/plain")) {
    return response.json();
  } else if (contentType.includes("image/png")) {
    return response.blob();
  }
}

export function handleDownload(response) {
  return response.ok ? response.blob().then(download) : handleError(response);
}

export function fetch(url, method = "GET", body, headers) {
  return window
    .fetch(url, {
      method,
      headers: new Headers(headers),
      ...(body ? { body } : {}),
      credentials: "include",
    })
    .then(handleResponse);
}

export function createBg(seed = null, color) {
  const pattern = trianglify({
    height: 400,
    width: 640,
    seed: seed,
    xColors: createBgGradient(color),
    yColors: "match",
    variance: 1,
  });
  return pattern.toCanvas().toDataURL();
}

export function onlyInt(e) {
  if (!/^[0-9]$/.test(e.key) && e.key !== "Enter") {
    e.preventDefault();
  }
}

export function onlyNumbers(e) {
  if (!/^[0-9,.]$/.test(e.key) && e.key !== "Enter") {
    e.preventDefault();
  }
}

export function objToParams(obj) {
  return obj
    ? `?${Object.entries(obj)
        .map((p) => p.join("="))
        .join("&")}`
    : "";
}

export default {
  checkTokenExpired,
  createBg,
  createBgGradient,
  download,
  fetch,
  handleDownload,
  handleError,
  handleResponse,
  objToParams,
  onlyNumbers,
  validateEmail,
  validateRoles,
  validateUUID,
};
