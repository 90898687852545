import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Fab } from "@material-ui/core";

import styles from "../utils/styles";

export function Action({ label, action, icon }) {
  const classes = styles.useStyles();
  const intl = useIntl();

  return (
    <Fab key={label} variant="extended" color="primary" onClick={action}>
      {icon}
      {label ? (
        <span className={classes.floatingActionsButtonLabel}>
          {!!intl.messages[label] ? (
            <FormattedMessage id={label}>
              {(...content) => content}
            </FormattedMessage>
          ) : (
            label
          )}
        </span>
      ) : null}
    </Fab>
  );
}

export default function FloatingActions({ children }) {
  const classes = styles.useStyles();

  return (
    <React.Fragment>
      <div className={classes.floatingActionsBg}></div>
      <div className={classes.floatingActionsWrapper} data-fab>
        {children}
      </div>
    </React.Fragment>
  );
}
