// import dictionary_DE from "../locales/de.json";
// import dictionary_EN from "../locales/en.json";
// import dictionary_ES from "../locales/es.json";
// import dictionary_FR from "../locales/fr.json";
import dictionary_IT from "../locales/it.json";

// import localeDe from "date-fns/locale/de";
// import localeEn from "date-fns/locale/en-US";
// import localeEs from "date-fns/locale/es";
// import localeFr from "date-fns/locale/fr";
import localeIt from "date-fns/locale/it";

const dictionaries = {
  // de: dictionary_DE,
  // en: dictionary_EN,
  // es: dictionary_ES,
  // fr: dictionary_FR,
  it: dictionary_IT,
};

const datesDictionary = {
  // de: localeDe,
  // en: localeEn,
  // es: localeEs,
  // fr: localeFr,
  it: localeIt,
};

function getLanguageWithoutRegionCode(language) {
  return language.toLowerCase().split(/[_-]+/)[0];
}

export function getDictionary(language) {
  return (
    dictionaries[getLanguageWithoutRegionCode(language)] ||
    dictionaries[language] ||
    dictionaries.it
  );
}

export function getDateDictionary(language) {
  return (
    datesDictionary[getLanguageWithoutRegionCode(language)] ||
    datesDictionary[language] ||
    datesDictionary.it
  );
}
