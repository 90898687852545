import React from "react";

import { Grid, Typography } from "@material-ui/core";

export default function NotFound({ backUrl }) {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">Page not found</Typography>
    </Grid>
  );
}
