import React from "react";

import { TextField } from "@material-ui/core";

export default function Description({ values, handleChange }) {
  return (
    <TextField
      name="description"
      label="Descrizione"
      value={values.description}
      onChange={handleChange("description")}
      key="description"
      fullWidth
      margin="normal"
      required
      multiline
    />
  );
}
