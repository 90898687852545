import React, { useState, useEffect } from "react";

import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Toolbar,
  Typography,
  ListSubheader,
} from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";

import Logo from "./Logo";
import MainMenu from "./MainMenu";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";
import { BUSINESS_TYPES } from "../utils/constants";

export default function TopBarMerchant({ links, logo, routes, userActions }) {
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const classes = styles.useStyles();
  const [selected, setSelected] = useState(bmapi.getUserInfo().business);
  const [businesses, setBusinesses] = useState([]);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const closeMenu = () => setIsMenuVisible(false);
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (business) => {
    startLoading();
    bmapi.changeBusiness(business).then(stopLoading);
    setSelected(business);
    handleClose();
  };

  useEffect(() => {
    bmapi.getUserBusiness().then(setBusinesses);
  }, [bmapi, setBusinesses]);

  const alpha = (a, b) => a.name.localeCompare(b.name);

  const loops = businesses
    .filter((b) => b.type === BUSINESS_TYPES.LOOP)
    .sort(alpha);
  const stores = businesses
    .filter((b) => b.type === BUSINESS_TYPES.MERCHANT)
    .sort(alpha);

  return (
    <AppBar position="sticky" className={classes.topBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Menu"
          onClick={toggleMenu}
        >
          <MenuIcon />
        </IconButton>
        <div style={{ flex: "1 1 auto", margin: "0 5px" }}>
          <Logo logo={logo} link={routes.HOME} />
        </div>
        <div style={{ flex: "0 0 auto" }}>
          {businesses.length > 1 ? (
            <React.Fragment>
              <Button
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                variant="outlined"
                color="inherit"
              >
                {selected.name}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {loops.length > 0 && <ListSubheader>Circuiti</ListSubheader>}
                {loops.map((b) => (
                  <MenuItem
                    key={b.id}
                    onClick={() => handleChange(b)}
                    selected={b.id === selected.id}
                  >
                    {b.name}
                  </MenuItem>
                ))}
                {stores.length > 0 && <ListSubheader>Negozi</ListSubheader>}
                {stores.map((b) => (
                  <MenuItem
                    key={b.id}
                    onClick={() => handleChange(b)}
                    selected={b.id === selected.id}
                  >
                    {b.name}
                  </MenuItem>
                ))}
              </Menu>
            </React.Fragment>
          ) : (
            <Typography variant="button" display="block">
              {selected.name}
            </Typography>
          )}
        </div>
      </Toolbar>
      <MainMenu
        open={isMenuVisible}
        onClose={closeMenu}
        links={links}
        userActions={userActions}
        logoImage={logo}
      />
    </AppBar>
  );
}
