import React from "react";

import TopBarConsumer from "./TopBarConsumer";
import TopBarMerchant from "./TopBarMerchant";
import { useBmapi } from "../utils/bmapi-context";

export default function TopBar({ links, logo, routes, userActions }) {
  const { bmapi } = useBmapi();

  const CurrentTopBar = bmapi.isConsumer() ? TopBarConsumer : TopBarMerchant;

  return (
    <CurrentTopBar
      links={links}
      logo={logo}
      routes={routes}
      userActions={userActions}
    />
  );
}
