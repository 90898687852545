import React from "react";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {},
  subtitle: {
    marginTop: theme.spacing(1),
  },
}));

export default function Title({ children, subtitle, backUrl }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <header className={classes.header}>
      <Typography variant="h5" className={classes.title}>
        {backUrl && (
          <IconButton
            color="inherit"
            aria-label="back"
            onClick={() => history.push(backUrl)}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        {children}
      </Typography>
      {subtitle && (
        <Typography variant="h6" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </header>
  );
}
