import React from "react";

import NumberInput from "../../NumberInput";
import { PRODUCT_TYPES } from "../../../utils/constants";

export default function Quantum({ values, handleChange, ...props }) {
  return (
    [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD].includes(values.type) && (
      <NumberInput
        name="quantum"
        label="Scaglione di conversione spesa -> punti"
        placeholder="1"
        value={values.quantum}
        onChange={handleChange("quantum")}
        fullWidth
        margin="normal"
        helperText="Scaglione di assegnazione dei punti (ogni XX euro di spesa assegna i punti)"
        {...props}
      />
    )
  );
}
