import React from "react";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";

import NumberInput from "../../NumberInput";

export default function MaxProductPerUser({ values, handleChange, label }) {
  return (
    values.subtype !== PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
      <NumberInput
        name="max_products_per_user"
        label={`Numero massimo di ${label} per utente`}
        value={values.max_products_per_user}
        onChange={handleChange("max_products_per_user")}
        key="max_products_per_user"
        fullWidth
        margin="normal"
        disabled={!!values.link_distribution}
      />
    )
  );
}
