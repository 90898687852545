import React, { useEffect, useRef, useState } from "react";
import { addDays, addMonths, endOfDay } from "date-fns";

import {
  Chip,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";

import NumberInput from "../../NumberInput";
import styles from "../../../utils/styles";
import {
  PRODUCT_SUBTYPES,
  PRODUCT_TYPES,
  ROLES,
} from "../../../utils/constants";
import { useBmapi } from "../../../utils/bmapi-context";

export default function Prizes({ values, handleChange, campaigns = [] }) {
  const classes = styles.useStyles();
  const { bmapi } = useBmapi();
  const input = useRef();
  const [threshold, setThreshold] = useState("");
  const [prizes, setPrizes] = useState([]);
  const [possiblePrizes, setPossiblePrizes] = useState([]);

  useEffect(() => {
    const isPossiblePrize = (c) =>
      [
        PRODUCT_SUBTYPES.COUPON_DISCOUNT,
        PRODUCT_SUBTYPES.COUPON_SIMPLE,
        PRODUCT_SUBTYPES.COUPON_VALUE,
        PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
        PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE,
      ].includes(c.type);

    const canAdd = (c) =>
      bmapi.getUserInfo().role === ROLES.TENANT_MANAGER ||
      bmapi.getUserInfo().business.id === c.business_owner_id;

    const notExpired = (c) => new Date(c.expiration_date) > new Date();

    setPossiblePrizes(
      campaigns?.filter(isPossiblePrize).filter(canAdd).filter(notExpired)
    );
  }, [campaigns, bmapi]);

  useEffect(() => {
    if (prizes.length !== values.prizes.length) {
      handleChange("prizes")(
        prizes.map((p) => ({
          description: p.prize.description,
          name: p.prize.name,
          prize_id: p.prize.campaign_id,
          threshold: p.threshold * 100,
        }))
      );
    }
  }, [prizes, handleChange, values.prizes.length]);

  const addPrize = (prize) => {
    if (threshold && prize) {
      setPrizes((p) => [
        ...p,
        {
          id: Date.now(),
          threshold: parseInt(threshold),
          prize: possiblePrizes.find((c) => c.id === prize),
        },
      ]);
      setThreshold("");
      window.setTimeout(() => input.current.focus(), 0);
    }
  };

  const removePrize = (id) => {
    setPrizes((ps) => ps.filter((p) => p.id !== id));
  };

  const byPoints = (a, b) => a.threshold - b.threshold;

  const lastDatePossible = () =>
    addDays(
      values.product_limit_date ||
        addMonths(values.expiration_date, values.product_lifespan || 0),
      values.grace_period || 0
    );

  return (
    [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD].includes(values.type) && (
      <FormControl margin="normal">
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={4}>
            <NumberInput
              name="threshold"
              label="Punti"
              value={threshold}
              onChange={(e) => setThreshold(e.target.value)}
              fullWidth
              inputRef={input}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              select
              label="Scegli un premio"
              value={""}
              onChange={(e) => addPrize(e.target.value)}
              fullWidth
              disabled={!possiblePrizes || !threshold}
              helperText={`Scadenza minima necessaria: ${lastDatePossible().toLocaleDateString(
                "it-IT"
              )}`}
            >
              {possiblePrizes?.length ? (
                (possiblePrizes || []).map((c) => (
                  <MenuItem
                    key={c.id}
                    value={c.id}
                    disabled={
                      endOfDay(new Date(c.expiration_date)) <
                      endOfDay(lastDatePossible())
                    }
                  >
                    {c.name}
                    {endOfDay(new Date(c.expiration_date)) <
                    endOfDay(lastDatePossible())
                      ? ` [Scadenza: ${new Date(
                          c.expiration_date
                        ).toLocaleDateString("it-IT")}]`
                      : ""}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">Nessun premio assegnabile</MenuItem>
              )}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              {prizes.sort(byPoints).map((p) => (
                <Grid item key={p.id} classes={{ root: classes.chipWrapper }}>
                  <Chip
                    label={`${new Intl.NumberFormat("it-IT").format(
                      p.threshold
                    )} ${p.threshold === 1 ? "punto" : "punti"} = ${
                      p.prize.name
                    }`}
                    onDelete={() => removePrize(p.id)}
                    color="secondary"
                    classes={{ root: classes.chip }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    )
  );
}
