import React, { useCallback, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Link,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../../utils/bmapi-context";
import { CONSUMER_ROUTES } from "../../../utils/constants";

export default function LuccaTOC() {
  const { bmapi, startLoading, stopLoading } = useBmapi();

  const accept = useCallback(() => {
    startLoading();

    bmapi
      .updateLcngUser({
        rules: true,
        privacy: true,
        direct_marketing: false,
        indirect_marketing: false,
      })
      .finally(stopLoading);
  }, [bmapi, startLoading, stopLoading]);

  useEffect(() => {
    stopLoading();
  }, [stopLoading]);

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          <img
            src="https://storage.googleapis.com/bmarken-img-bucket/5b85449d-76d8-451c-85d2-c2425a5f4376.jpg"
            alt="Logo"
            style={{
              maxWidth: 200,
              margin: "10px auto 30px",
              display: "block",
            }}
          />
          <Typography variant="h5" gutterBottom>
            Approvazione dei Termini di Servizio
          </Typography>
          <Typography variant="body2" gutterBottom>
            Dichiaro di essere consapevole che per utilizzare l’applicazione è
            necessaria la connessione internet (eventuali costi in base al piano
            tariffario del proprio Operatore).
          </Typography>
          <Typography variant="body2" gutterBottom>
            Dichiaro di aver compiuto 18 anni di età (in caso contrario si è
            invitati ad uscire dall’applicazione).
          </Typography>
          <Typography variant="body2" gutterBottom>
            Dichiaro di avere letto e accettato i Termini di Servizio
            (utilizzando questo servizio ci si impegna ad osservare le norme
            contenute nei Termini di Servizio{" "}
            <Link
              component={RouterLink}
              to={CONSUMER_ROUTES.STATIC.replace(":content?/", "lucca-toc")}
              target="_blank"
            >
              qui
            </Link>{" "}
            descritti)
          </Typography>
        </CardContent>
        <CardContent>
          <Box textAlign="center">
            <Button variant="contained" color="primary" onClick={accept}>
              CONFERMO QUANTO DICHIARATO SOPRA
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}
