import React from "react";

import { TextField } from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function ProductName({ values, handleChange }) {
  return (
    <TextField
      name="products_name"
      label="Nome dei prodotti"
      value={values.products_name}
      onChange={handleChange("products_name")}
      key="products_name"
      fullWidth
      margin="normal"
      required={values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE}
    />
  );
}
