import React from "react";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

export default function WaitingList({ values, handleChange }) {
  return (
    <FormControl margin="normal">
      <FormControlLabel
        control={
          <Switch
            checked={values.waiting_list}
            color="primary"
            onChange={handleChange("waiting_list")}
            key="waiting_list"
            name="waiting_list"
          />
        }
        label="Lista d'attesa"
      />
    </FormControl>
  );
}
