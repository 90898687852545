import React from "react";

import { CircularProgress, Grid, Typography } from "@material-ui/core";

export default function ProductDetails({ label, value }) {
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justify="space-between"
      alignItems="baseline"
    >
      <Grid item xs="auto">
        <Typography variant="body2">{label}</Typography>
      </Grid>
      <Grid item xs="auto">
        {value === false ? (
          <CircularProgress size={14} thickness={7} />
        ) : (
          <Typography variant="body2">{value}</Typography>
        )}
      </Grid>
    </Grid>
  );
}
