import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../utils/bmapi-context";

export default function FidelityForm({ getPermissionByMail }) {
  const { bmapi } = useBmapi();
  const [email, setEmail] = useState("");

  function handleEmailInput(event) {
    setEmail(event.target.value.trim().toLocaleLowerCase());
  }

  function handleSubmit(event) {
    event.preventDefault();
    getPermissionByMail(email);
  }

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          <Typography gutterBottom>
            Inserisci l'indirizzo email a cui vuoi inviare dei punti e premi
            avanti. Se l'indirizzo è corretto si aprirà il form per
            l'inserimento dei punti
          </Typography>
          <Typography variant="body2" gutterBottom>
            Per motivi di sicurezza avrai poi 3 minuti per completare l'invio
            dei punti prima che scada il permesso, in caso di scadenza basterà
            fare ANNULLA, tornare qui e reinserire la mail.
          </Typography>

          <form onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">
                <FormattedMessage id="page-signin--email" />
              </InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
                value={email}
                onChange={handleEmailInput}
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!bmapi.validateEmail(email)}
            >
              Avanti
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}
