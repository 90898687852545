import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import QrReader from "react-qr-reader";

import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import { MERCHANT_ROUTES, UUID_LENGTH, UUID_REGEX } from "../utils/constants";

function isCompatibleChar(char) {
  return /^[0-9a-zA-Z-]$/.test(char);
}

export default function QrScanner({ handleError, handleCode }) {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [scanner, setScanner] = useState(false);
  const [stream, setStream] = useState(false);

  const handleInput = useCallback(
    (e) => {
      if (isCompatibleChar(e.key)) {
        setCode((code + e.key).slice(-UUID_LENGTH));
      }
    },
    [code, setCode]
  );

  const handleScan = (c) => {
    if (c !== code && UUID_REGEX.test(c)) setCode(c);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCode(code);
    setCode("");
  };

  useEffect(() => {
    document.addEventListener("keydown", handleInput);

    return () => {
      document.removeEventListener("keydown", handleInput);
    };
  }, [handleInput]);

  useEffect(() => {
    if (UUID_REGEX.test(code)) {
      handleCode(code);
      setCode("");
    }
  }, [code, handleCode]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        setStream(stream);
        setScanner(true);
      })
      .catch(() => handleError("Nessuna webcam disponibile"));
  }, [handleError]);

  useEffect(() => {
    if (stream) stream.getTracks().forEach((track) => track.stop());
  }, [stream]);

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Grid container direction="column" alignItems="stretch">
              <Grid item>
                <Typography align="center" paragraph>
                  <FormattedMessage id="app-scanner--info-message" />
                </Typography>
              </Grid>

              {!scanner ? null : (
                <QrReader
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: "100%" }}
                />
              )}

              {/* <TextField
                inputRef={inputRef}
                fullWidth
                label="Codice"
                type="text"
                value={code}
                onChange={handleChange}
                margin="normal"
              />

              <Box mt={4}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={!UUID_REGEX.test(code)}
                  >
                    <FormattedMessage id="app-actions--confirm" />
                  </Button>
                </Grid>
              </Box> */}
            </Grid>
          </CardContent>
        </Card>
        <FormControl margin="normal">
          <Button
            onClick={() => {
              history.push(MERCHANT_ROUTES.HOME);
            }}
            startIcon={<ArrowBackIcon />}
          >
            Torna alla home
          </Button>
        </FormControl>
      </form>
    </Container>
  );
}
