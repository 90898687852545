import React, { useState } from "react";

import { InputAdornment, MenuItem, TextField } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCT_TYPES } from "../../../utils/constants";

export default function ShotNumber({ values, handleChange }) {
  const [shots, setShots] = useState(values.shot_number || "1");

  const handleShots = (e) => {
    setShots(e.target.value);
    handleChange("shot_number")(
      e.target.value > -2 ? e : { target: { value: "1" } }
    );
  };

  return (
    [PRODUCT_TYPES.CAMPAIGN_COUPON].includes(values.type) && (
      <React.Fragment>
        <TextField
          name="shots"
          label="Numero di utilizzi"
          value={shots}
          onChange={handleShots}
          select
          fullWidth
          margin="normal"
        >
          {[
            { label: "Illimitati", value: "-1" },
            { label: "Uso singolo", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "5", value: "5" },
            { label: "7", value: "7" },
            { label: "10", value: "10" },
            { label: "15", value: "15" },
            { label: "20", value: "20" },
            { label: "25", value: "25" },
            { label: "30", value: "30" },
            { label: "Personalizzato", value: "-2" },
          ].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {shots === "-2" && (
          <NumberInput
            name="shot_number"
            label="Numero di utilizzi personalizzato"
            value={values.shot_number}
            onChange={handleChange("shot_number")}
            fullWidth
            required
            margin="normal"
            max={25}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">volte</InputAdornment>
              ),
            }}
          />
        )}
      </React.Fragment>
    )
  );
}
