import React from "react";

import { TextField } from "@material-ui/core";

import { useBmapi } from "../../../utils/bmapi-context";
import { ROLES } from "../../../utils/constants";

export default function TosMerchantUrl({ values, handleChange }) {
  const { bmapi } = useBmapi();

  return (
    bmapi.getUserInfo().role === ROLES.TENANT_MANAGER && (
      <TextField
        name="tos_merchant_url"
        label="Merchant term of services URL"
        value={values.tos_merchant_url}
        onChange={handleChange("tos_merchant_url")}
        key="tos_merchant_url"
        fullWidth
        margin="normal"
        type="url"
      />
    )
  );
}
