import { makeStyles } from "@material-ui/core/styles";

export default {
  get useStyles() {
    return makeStyles((theme) => ({
      avatar: {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main
        ),
        color: theme.palette.primary.main,
      },
      bannerMedia: {
        paddingTop: `${(5 / 16) * 100}%`,
      },
      campaignIcon: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,
      },
      cardIcon: {
        backgroundColor: theme.palette.primary.main,
      },
      cardMedia: {
        paddingTop: `${(10 / 16) * 100}%`,
      },
      chip: {
        maxWidth: "100%",
      },
      chipWrapper: {
        maxWidth: "100%",
      },
      coverUpload: {
        marginBottom: 3,
        position: "relative",

        "& img": {
          display: "block",
          width: "100%",
        },
      },
      coverUploadButtons: {
        bottom: theme.spacing(2),
        position: "absolute",
        right: theme.spacing(2),

        "& > *": {
          marginLeft: theme.spacing(1),
        },
      },
      demoChip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
      demoRibbon: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: "0 0 20px rgba(255, 255, 255, 0.7)",
        color: theme.palette.getContrastText(theme.palette.primary.main),
        display: "block",
        height: "1.6em",
        lineHeight: "1.6em",
        position: "absolute",
        right: "-5em",
        textAlign: "center",
        top: "-0.8em",
        transform: "rotate(45deg) translateY(3em)",
        width: "10em",
      },
      disabled: {
        filter: "saturate(0)",
      },
      fabProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
      },
      fieldset: {
        width: "100%",
        marginBottom: theme.spacing(4),
      },
      floatingActionsWrapper: {
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
        bottom: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 2,
      },
      floatingActionsBg: {
        backgroundImage: `linear-gradient(to top, ${theme.palette.background.default} 40%, transparent)`,
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        height: 110,
        pointerEvents: "none",
        zIndex: theme.zIndex.drawer + 1,
      },
      floatingActionsButtonLabel: {
        marginLeft: theme.spacing(1),
        whiteSpace: "nowrap",
      },
      expand: {
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: "rotate(180deg)",
      },
      headerLogo: {
        objectFit: "contain",
        objectPosition: "left center",
        width: "100%",
        maxHeight: 40,
        maxWidth: 200,
        verticalAlign: "middle",
      },
      loading: {
        padding: 10,
      },
      loginColor: {
        color: theme.palette.getContrastText(theme.palette.background.default),
      },
      loginButton: {
        borderRadius: theme.shape.borderRadius,
        maxWidth: 220,
        textTransform: "none",
      },
      mainMenu: {
        width: "60vw",
        minWidth: 235,
        maxWidth: 300,
        background: theme.palette.common.bg,
        color: theme.palette.getContrastText(theme.palette.common.bg),

        "&.MuiDrawer-paperAnchorLeft": {
          borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
        },
        "&.MuiDrawer-paperAnchorRight": {
          borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
        },
      },
      mainMenuBack: {
        color: "inherit",
        textTransform: "none",
        justifyContent: "flex-end",
        fontWeight: "bold",
        minHeight: 56,
        [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
          minHeight: 48,
        },
        [theme.breakpoints.up("sm")]: {
          minHeight: 64,
        },
      },
      mainMenuLogoWrapper: {
        padding: theme.spacing(1),
      },
      mainMenuLogo: {
        objectFit: "contain",
        objectPosition: "center center",
        maxHeight: 80,
        maxWidth: 160,
        width: "100%",
        margin: "auto",
        display: "block",
      },
      menuItem: {
        fontWeight: "normal",
      },
      menuItemSelected: {
        fontWeight: "bold",
        backgroundColor: "#fff3",
      },
      pageIcon: {
        backgroundColor: theme.palette.secondary.main,
        display: "inline-flex",
        margin: theme.spacing(2),
      },
      topBar: {
        background: theme.palette.common.bg,
        color: theme.palette.getContrastText(theme.palette.common.bg),
      },
    }));
  },
};
