import React, { useState } from "react";

import { TextField } from "@material-ui/core";

import { onlyNumbers, onlyInt } from "../utils/utils";

export default function NumberInput({
  value,
  onChange,
  float = false,
  min = 0,
  max,
  format = true,
  ...props
}) {
  const [focused, setFocused] = useState(false);

  const handleChange = (e) => {
    if (e.target.value === "" || e.target.validity.valid) {
      onChange(e);
    }
  };

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const formatValue = () =>
    value ? new Intl.NumberFormat("it-IT").format(value) : "";

  return (
    <TextField
      value={focused || !format ? value : formatValue()}
      onChange={handleChange}
      type={focused ? "number" : "text"}
      inputProps={{
        onKeyPress: float ? onlyNumbers : onlyInt,
        min: min,
        max: max,
        step: float ? 0.01 : 1,
        lang: "it-IT",
        onFocus: onFocus,
        onBlur: onBlur,
      }}
      {...props}
    />
  );
}
