import React from "react";

import { PRODUCT_TYPES } from "../../utils/constants";

import AutoJoin from "./input/AutoJoin";
import Cover from "./input/Cover";
import Currency from "./input/Currency";
import Demo from "./input/Demo";
import Description from "./input/Description";
import ExpirationDate from "./input/ExpirationDate";
import EndHour from "./input/EndHour";
import ExternalIdFormat from "./input/ExternalIdFormat";
import FormSection from "./input/FormSection";
import GracePeriod from "./input/GracePeriod";
import Icon from "./input/Icon";
import LinkDistribution from "./input/LinkDistribution";
import MainEventId from "./input/MainEventId";
import MaxIssueNumber from "./input/MaxIssueNumber";
import MaxProductPerUser from "./input/MaxProductPerUser";
import Name from "./input/Name";
import Prizes from "./input/Prizes";
import ProductLifespan from "./input/ProductLifespan";
import ProductLimitDate from "./input/ProductLimitDate";
import ProductName from "./input/ProductName";
import Quantum from "./input/Quantum";
import Rate from "./input/Rate";
import ShotNumber from "./input/ShotNumber";
import StartDate from "./input/StartDate";
import StartHour from "./input/StartHour";
import TosConsumerUrl from "./input/TosConsumerUrl";
import TosMerchantUrl from "./input/TosMerchantUrl";
import UseFrequency from "./input/UseFrequency";
import UsePeriod from "./input/UsePeriod";
import Value from "./input/Value";
import WaitingList from "./input/WaitingList";

export default function CommonCreateForm(props) {
  return (
    <React.Fragment>
      <FormSection>
        <MainEventId {...props} />
        <Name {...props} />
        <ProductName {...props} />
        <Description {...props} />
        <StartDate {...props} />
        <StartHour {...props} />
        <ExpirationDate {...props} />
        <EndHour {...props} />
        <TosConsumerUrl {...props} />
        <TosMerchantUrl {...props} />
        <LinkDistribution {...props} />
        <Demo {...props} />
        <AutoJoin {...props} />
        <WaitingList {...props} />
      </FormSection>

      <FormSection title="Immagini">
        <Cover {...props} />
        <Icon {...props} />
      </FormSection>

      <FormSection title={`Informazioni ${props.label}`}>
        <ProductLimitDate {...props} />
        <ProductLifespan {...props} />
        <GracePeriod {...props} />
        <MaxIssueNumber {...props} />
        <MaxProductPerUser {...props} />
      </FormSection>

      <FormSection title="Dettagli">
        <Value {...props} />
        <Currency {...props} />
        <ShotNumber {...props} />
        <ExternalIdFormat {...props} />
        <Rate {...props} />
        <Quantum {...props} />
      </FormSection>

      {props.values.subtype === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD && (
        <FormSection title="Premi">
          <Prizes {...props} />
        </FormSection>
      )}

      {props.values.subtype === PRODUCT_TYPES.CAMPAIGN_COUPON && (
        <FormSection title="Vincoli di utilizzo">
          <UseFrequency {...props} />
          <UsePeriod {...props} />
        </FormSection>
      )}
    </React.Fragment>
  );
}
