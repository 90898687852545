import React from "react";

import { TextField } from "@material-ui/core";

export default function TosConsumerUrl({ values, handleChange }) {
  return (
    <TextField
      name="tos_consumer_url"
      label="Consumer term of services URL"
      value={values.tos_consumer_url}
      onChange={handleChange("tos_consumer_url")}
      key="tos_consumer_url"
      fullWidth
      margin="normal"
      type="url"
    />
  );
}
