import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { Box, Button, Container, Grid } from "@material-ui/core";
import { CenterFocusStrong as CenterFocusIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import CampaignsList from "../../ui/CampaignsList";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import CreateCampaignButton from "../../ui/CreateCampaignButton";

import { useBmapi } from "../../utils/bmapi-context";
import {
  CAMPAIGN_STATUS,
  MERCHANT_ROUTES,
  FEATURES,
  ROLES,
} from "../../utils/constants";
import { useCampaigns } from "../../utils/campaigns";

export default function Home() {
  const history = useHistory();
  const { bmapi, toggleLoading } = useBmapi();
  const { campaigns, signCampaign, loadCampaigns } = useCampaigns();
  const [toSign, setToSign] = useState([]);

  useEffect(() => {
    toggleLoading(!campaigns);
  }, [campaigns, toggleLoading]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  useEffect(() => {
    if (campaigns) {
      setToSign(
        campaigns
          .filter((c) => c.status === CAMPAIGN_STATUS.ACTIVE && c.toSign)
          .map((c) => c.id)
      );
    }
  }, [campaigns, bmapi]);

  const joinedFirst = (a, b) => {
    if (a.toSign && !b.toSign) return 1;
    if (!a.toSign && b.toSign) return -1;
    return a.name.localeCompare(b.name);
  };

  const saveView = () => {
    return bmapi.setUserData({
      notifiedCampaigns: toSign,
    });
  };

  const toNotify = (campaign) => {
    const { notifiedCampaigns = [] } = bmapi.getUserData();
    return !notifiedCampaigns.includes(campaign);
  };

  return !!campaigns ? (
    <React.Fragment>
      {bmapi.getUserInfo().role !== ROLES.TENANT_MANAGER &&
        toSign.filter(toNotify).length > 0 && (
          <Container maxWidth="sm">
            <Box mb={5}>
              <Alert
                severity="info"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    component={RouterLink}
                    to={MERCHANT_ROUTES.CAMPAIGNS}
                    onClick={saveView}
                  >
                    Vedi tutte
                  </Button>
                }
              >
                Ci sono nuove campagne disponibili
              </Alert>
            </Box>
          </Container>
        )}
      <Container maxWidth="lg">
        <CampaignsList
          campaigns={campaigns
            .filter((c) => c.status === CAMPAIGN_STATUS.ACTIVE && !c.toSign)
            .sort(joinedFirst)}
          signCampaign={signCampaign}
          loadCampaigns={loadCampaigns}
        />

        <Box my={4}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to={MERCHANT_ROUTES.CAMPAIGNS}
              >
                Vedi tutte le campagne
              </Button>
            </Grid>
            {bmapi.can(FEATURES.MANAGE_CAMPAIGN) && (
              <Grid item>
                <CreateCampaignButton
                  trigger={(onClick) => (
                    <Button
                      variant="contained"
                      onClick={onClick}
                      color="primary"
                    >
                      Crea una nuova campagna
                    </Button>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        {bmapi.getUserInfo().role !== ROLES.TENANT_MANAGER &&
        bmapi.can(FEATURES.SCAN_QR) &&
        campaigns.some(
          (c) => !c.toSign && c.status === CAMPAIGN_STATUS.ACTIVE
        ) ? (
          <FloatingActions>
            <Action
              icon={<CenterFocusIcon />}
              label={"app-actions--scan"}
              action={() => history.push(MERCHANT_ROUTES.SCAN)}
            />
          </FloatingActions>
        ) : null}
      </Container>
    </React.Fragment>
  ) : null;
}
