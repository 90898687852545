export const MULTIPLE_TENANTS = JSON.parse(
  process.env.REACT_APP_MULTIPLE_TENANTS
);

export const ENVS = {
  PRODUCTION: "production",
};

export const TENANTS = {
  LUCCA: "lcng",
  DEMO: "demo",
};

export const DEFAULT_TENANT_MAP = {
  "campfire.demo3.mediaus.online": TENANTS.LUCCA,
};

export const DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT;
export const LOAD_DEFAULT_TENANT = process.env.REACT_APP_LOAD_DEFAULT_TENANT;

export const DOMAIN_WILDCARD = "@liberacta.";

export const DEFAULT_LANGUAGE = "it";
export const DEFAULT_MANIFEST = {
  background_color: "#fafafa",
  display: "standalone",
  icons: [
    {
      src: "/static/tenants/demo/icon.png",
      sizes: "500x500",
      type: "image/png",
    },
  ],
  name: `BMarkEn by Liberacta`,
  short_name: "BMarkEn",
  theme_color: "#e60",
};

export const POSITIVE_FLOATING_NUMBER = /^(?:\d+|0)+(?:\.\d{0,2})?$/gm;
export const UUID_LENGTH = 36;
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const USER_PREFIX = "UID_";

export const EMPTY_USER = {
  language: "",
  user_id: "",
  user_name: "",
  role: "",
  business: false,
  token_info: {},
  firebase_token_id: "",
};

export const ROLES = {
  USER: "U",
  STORE_MANAGER: "M",
  TENANT_MANAGER: "TM",
};

export const PERMISSIONS = {
  ISSUE_BUSINESS_PRODUCTS: "IssueBusinessProducts",
  MANAGE_BUSINESS_CAMPAIGN: "ManageBusinessCampaign",
  USE_BUSINESS_PRODUCTS: "UseBusinessProducts",
  VIEW_BUSINESS_CAMPAIGN: "ViewBusinessCampaign",
  VIEW_BUSINESS_STATISTICS: "ViewBusinessStatistics",
  VIEW_CAMPAIGN_STATISTICS: "ViewCampaignStatistics",
  VIEW_REPORTS: "ViewBusinessReports",
};

export const FEATURES = {
  ACCOUNT: "account",
  CALENDAR: "calendar",
  MY_CODE: "myQrCode",
  MANAGE_CAMPAIGN: "createCampaigns",
  ISSUE_PRODUCT: "issueProduct",
  SCAN_QR: "scanQrCode",
  SEND_POINTS: "sendPoints",
  LOGOUT: "logout",
  VIEW_REPORTS: "viewReports",
};

export const FEATURE_PERMISSION = {
  [FEATURES.MANAGE_CAMPAIGN]: PERMISSIONS.MANAGE_BUSINESS_CAMPAIGN,
  [FEATURES.ISSUE_PRODUCT]: PERMISSIONS.ISSUE_BUSINESS_PRODUCTS,
  [FEATURES.SCAN_QR]: PERMISSIONS.USE_BUSINESS_PRODUCTS,
  [FEATURES.SEND_POINTS]: PERMISSIONS.USE_BUSINESS_PRODUCTS,
  [FEATURES.VIEW_REPORTS]: PERMISSIONS.VIEW_REPORTS,
};

export const BUSINESS_TYPES = {
  MERCHANT: "Merchant",
  LOOP: "Loop",
};

export const CODE_FORMATS = {
  STRING: "string",
  QR_CODE: "qrcode",
};

export const PRODUCT_TYPES = {
  CAMPAIGN_AMAZON: "CAMPAIGN_AMAZON",
  CAMPAIGN_COUPON: "CAMPAIGN_COUPON",
  CAMPAIGN_EARNING_CARD: "CAMPAIGN_EARNING_CARD",
  CAMPAIGN_EVENT_PASS: "CAMPAIGN_EVENT_PASS",
  CAMPAIGN_MULTIWIN: "CAMPAIGN_MULTIWIN",
  CAMPAIGN_SHOPPING_CARD: "CAMPAIGN_SHOPPING_CARD",
};

export const PRODUCT_SUBTYPES = {
  COUPON_AMAZON: "AMAZON_COUPON",
  COUPON_DISCOUNT: "DISCOUNT_COUPON",
  COUPON_MULTIWIN: "MULTIWIN_COUPON",
  COUPON_SIMPLE: "SIMPLE_COUPON",
  COUPON_VALUE: "VALUE_COUPON",
  EARNING_CARD_SIMPLE: "EARNING_CARD_SIMPLE",
  EVENT_PASS_SIMPLE: "EVENT_PASS_SIMPLE",
  SHOPPING_CARD_RECHARGEABLE: "SHOPPING_CARD_RECHARGEABLE",
  SHOPPING_CARD_SIMPLE: "SHOPPING_CARD_SIMPLE",
};

export const TX_TYPES = {
  BURN: "TX_TYPE_BURN",
  DECREASE: "TX_TYPE_DECREASE",
  EXPIRE: "TX_TYPE_EXPIRE",
  INCREASE: "TX_TYPE_INCREASE",
  ISSUE: "TX_TYPE_ISSUE",
};

export const CAMPAIGN_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1,
  EXPIRED: 2,
  DELETED: 3,
  SUSPENDED: 4,
};

export const VARIANTS = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
};

export const CONSUMER = "consumer";
export const MERCHANT = "merchant";
export const CONSUMER_NAMESPACE = "/";
export const MERCHANT_NAMESPACE = "/merchant/";

export const ROUTES = {
  ACCOUNT: "account/",
  HOME: "",
  PASSWORD_FORGET: "pw-forget/",
  SIGN_IN: "signin/",
  SIGN_IN_ACTION: "signin/:action?/",
  SIGN_UP: "signup/",
  INFO: "info/",
  STATIC: "content/:content?/",
};

export const CONSUMER_ROUTES = {
  ACCOUNT: `${CONSUMER_NAMESPACE}${ROUTES.ACCOUNT}`,
  CAMPAIGN: `${CONSUMER_NAMESPACE}campaign/:campaignId/`,
  CALENDAR: `${CONSUMER_NAMESPACE}campaign/:campaignId/calendar/`,
  HOME: `${CONSUMER_NAMESPACE}`,
  INFO: `${CONSUMER_NAMESPACE}${ROUTES.INFO}`,
  PASSWORD_FORGET: `${CONSUMER_NAMESPACE}${ROUTES.PASSWORD_FORGET}`,
  QR_CODE: `${CONSUMER_NAMESPACE}code/`,
  SIGN_IN: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_IN}`,
  SIGN_IN_ACTION: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_IN_ACTION}`,
  SIGN_UP: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_UP}`,
  STATIC: `${CONSUMER_NAMESPACE}${ROUTES.STATIC}`,

  LUCCA_BUY: `${CONSUMER_NAMESPACE}get_pass/`,
  LUCCA_CONFIRM: `${CONSUMER_NAMESPACE}confirm_pass/`,
  LUCCA_CANCEL: `${CONSUMER_NAMESPACE}cancel_order/`,
};

export const MERCHANT_ROUTES = {
  ACCOUNT: `${MERCHANT_NAMESPACE}${ROUTES.ACCOUNT}`,
  CAMPAIGNS: `${MERCHANT_NAMESPACE}campaigns/`,
  RESERVATIONS: `${MERCHANT_NAMESPACE}campaigns/:campaignId/reservations/`,
  CREATE_CAMPAIGN: `${MERCHANT_NAMESPACE}campaigns/create/:subtype?/`,
  EXPORT: `${MERCHANT_NAMESPACE}export/`,
  HOME: `${MERCHANT_NAMESPACE}`,
  INFO: `${MERCHANT_NAMESPACE}${ROUTES.INFO}`,
  PASSWORD_FORGET: `${MERCHANT_NAMESPACE}${ROUTES.PASSWORD_FORGET}`,
  PROMOS: `${MERCHANT_NAMESPACE}promo/`,
  SCAN: `${MERCHANT_NAMESPACE}scan/`,
  SEND_POINTS: `${MERCHANT_NAMESPACE}send-points/`,
  SIGN_IN: `${MERCHANT_NAMESPACE}${ROUTES.SIGN_IN}`,
  SIGN_IN_ACTION: `${MERCHANT_NAMESPACE}${ROUTES.SIGN_IN_ACTION}`,
  SIGN_UP: `${MERCHANT_NAMESPACE}${ROUTES.SIGN_UP}`,
  STORES: `${MERCHANT_NAMESPACE}stores/`,
  TENANT_SETTINGS: `${MERCHANT_NAMESPACE}configuration/`,

  LUCCA_STATS: `${MERCHANT_NAMESPACE}lucca-stats/`,
};

export const API = {
  GET_BUSINESS: "/business/{business}",
  DELETE_CONTENT: "/business/{business}/contents/{content}",
  GET_BUSINESS_CAMPAIGNS: "/business/{business}/campaigns",
  GET_CAMPAIGN_PERF: "/business/{business}/campaigns/{campaign}/performance",
  GET_CAMPAIGN_DETAILS: "/business/{business}/campaigns/{campaign}/details",
  GET_CAMPAIGN_UPLOAD: "/business/{business}/campaigns/{campaign}/upload-url",
  GET_CAMPAIGNS: "/business/{business}/campaigns",
  GET_CONTENTS: "/business/{business}/contents",
  GET_PRODUCT_INFO: "/business/{business}/products/{qr-code}/info",
  GET_TERMS: "/business/{business}/terms",
  SIGN_TERM: "/business/{business}/terms/{terms}/sign",
  GET_RULES: "/business/{business}/campaigns/{campaign}/rules",
  GET_UPLOAD_CONTENTS: "/business/{business}/contents/upload-url",
  GET_USE_PERMISSION_BY_EMAIL: "/business/{business}/products/default/qrcode",
  UPDATE_CONTENT_STATUS: "/business/{business}/contents/{content}/{status}",
  UPDATE_CONTENT: "/business/{business}/contents/{content}",

  CAMPAIGN_CREATE: "/campaigns",
  CAMPAIGN_DELETE: "/campaigns/{campaign}",
  ISSUE_CAMPAIGN_PRODUCT: "/campaigns/{campaign}/issue",
  UPLOAD_CAMPAIGN_COVER: "/campaigns/{campaign}/picture/upload",
  UPLOAD_CAMPAIGN_ICON: "/campaigns/{campaign}/picture/upload?type=avatar",
  GET_CAMPAIGN_TERMS: "/campaigns/{campaign}/use-terms",
  GET_CAMPAIGN_PRIZES: "/campaigns/{campaign}/products/{product}/prizes",
  ACCEPT_PRODUCT: "/campaigns/{campaign}/products/accept",
  CAMPAIGN_RESERVATION: "/campaigns/{campaign}/reservations",

  GET_TRANSACTIONS: "/data/transactions",

  BURN_COUPON: "/products/coupon/{qr-code}/burn",
  CREATE_QRCODE_IMAGE: "/products/{qr-code}/qrcode-img",
  REDEEM_PRIZE: "/products/card/{card}/prize/{prize}",
  DECREASE_CARD_BALANCE: "/products/card/{qr-code}/decrease",
  INCREASE_CARD_BALANCE: "/products/card/{qr-code}/increase",
  CREATE_QRCODE_PERMISSION: "/products/{product}/qrcode",
  GET_EXTERNAL_CODE: "/products/{product}/external-id",

  RESERVATION: "/reservations/{reservation}",

  SIGNUP: "/signup",
  CUSTOM_SIGNUP: "/signup/custom",
  QUERY_POINTS_USERS: "/stats/points-users",

  GET_TENANT_BUSINESS: "/tenant/business",
  GET_TENANT_CAMPAIGNS: "/tenant/campaigns",
  GET_TENANT_POLICY: "/tenant/policies",
  UPDATE_TENANT_POLICY: "/tenant/policies",
  GET_CAMPAIGN: "/tenant/{tenant}/campaigns/{campaign}",
  GET_EVENTS: "/tenant/{tenant}/events",

  GET_USER: "/users/{user}",
  GET_USER_BUSINESS: "/users/{user}/business",
  GET_USER_PERMISSION: "/users/{user}/business/{business}/permissions",
  GET_PRODUCTS: "/users/{user}/products",
  GET_PRODUCTS_STATS: "/users/{user}/campaigns/stats",
  GET_DEFAULT_PRODUCT: "/users/{user}/products/default",
  GET_USER_RESERVATIONS: "/users/{user}/reservations",
  UPLOAD_AVATAR: "/users/{user}/picture/upload",
  UPDATE_LCNG_USER: "/users/lcng/{user}",

  CREATE_ORDER: "/operations/orders",
  CHECK_ORDER: "/operations/orders/{order}/check/{status}",
  GET_INVENTORIES: "/operations/orders/inventories",
};
