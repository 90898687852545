import React from "react";
import { FormattedMessage } from "react-intl";

import { Grid as MuiGrid, Typography } from "@material-ui/core";

export default function Grid({ items = [], render, emptyMsg = "" }) {
  return items.length ? (
    <MuiGrid
      container
      spacing={4}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      {items.map((item, i) => (
        <MuiGrid key={i} item xs={12} sm={6} md={4}>
          {render(item)}
        </MuiGrid>
      ))}
    </MuiGrid>
  ) : emptyMsg ? (
    <Typography
      variant="subtitle2"
      align="center"
      color="textSecondary"
      paragraph
    >
      <FormattedMessage id={emptyMsg} />
    </Typography>
  ) : null;
}
