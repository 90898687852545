import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import SignInForm from "../../ui/SignInForm";
import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES } from "../../utils/constants";

export default function SignIn() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const { action } = useParams();

  function onSubmit(email, password) {
    startLoading();

    return bmapi.doSignInWithEmailAndPassword(email, password).catch((e) => {
      stopLoading();
      notifyError(e);
    });
  }

  useEffect(() => {
    if (action === "logged" && bmapi.settings.customLogin) {
      startLoading();
      bmapi.doSignInWithCustomToken().catch((e) => {
        if (e.code === "COOKIES_NOT_FOUND") {
          window.location.href = bmapi.settings.customLogin.replace(
            "{cbUrl}",
            `${bmapi.baseUrl}${CONSUMER_ROUTES.SIGN_IN_ACTION.replace(
              ":action?",
              "logged"
            ).slice(1)}`
          );
        } else {
          notifyError(e);
        }
      });
    }
  }, [action, startLoading, bmapi, notifyError]);

  return !action && <SignInForm onSubmit={onSubmit} routes={CONSUMER_ROUTES} />;
}
