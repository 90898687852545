import React from "react";

import { Typography } from "@material-ui/core";

import { DatePicker } from "../../DatePicker";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function ProductLimitDate({ values, handleChange, label }) {
  return (
    values.subtype !== PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
      <React.Fragment>
        <Typography variant="caption" display="block" gutterBottom>
          In caso di inserimento di scadenza e validità, alla generazione
          {label === "coupon" ? " del coupon " : " della card "} verrà calcolata
          e usata la data più vicina. Almeno uno dei 2 campi è obbligatorio
        </Typography>

        <DatePicker
          label={`Scadenza ${label}`}
          value={values.product_limit_date}
          onChange={handleChange("product_limit_date")}
          key="product_limit_date"
          fullWidth
          clearable
          autoOk
          required={!values.product_lifespan}
          minDate={values.expiration_date}
          minDateMessage="Scadenza prima della fine della campagna"
        />
      </React.Fragment>
    )
  );
}
