import React from "react";

import { TimePicker } from "../../DatePicker";

export default function EndHour({ values, handleChange, label }) {
  return (
    <TimePicker
      label={
        label === "pass" ? "Ora di fine evento" : "Ora termine distribuzione"
      }
      value={values.end_hour}
      onChange={handleChange("end_hour")}
      key="end_hour"
      fullWidth
      autoOk
      clearable
      required={!!values.start_hour}
    />
  );
}
