import React, { useState } from "react";

import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";

import styles from "../../../utils/styles";
import { IconsMap } from "../../../utils/campaigns";

export default function Icon({ values, handleChange, label }) {
  const classes = styles.useStyles();
  const [iconPreview, setIconPreview] = useState(false);

  const removeIcon = () => {
    setIconPreview(false);
    handleChange("icon")(false);
  };

  const handleCapture = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => setIconPreview(e.target.result);
    handleChange("icon")(file);
  };

  const TypeIcon = IconsMap[values.subtype];

  return (
    <FormControl margin="normal">
      <Typography variant="caption" gutterBottom>
        Icona {label === "coupon" ? "del coupon" : "della card"}
      </Typography>

      <Grid container spacing={3} wrap="nowrap" alignItems="center">
        <Grid item xs>
          <Avatar
            src={iconPreview || ""}
            style={{ width: 100, height: 100 }}
            className={classes.campaignIcon}
          >
            <TypeIcon fontSize="large" />
          </Avatar>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <Button
              component="label"
              color="secondary"
              variant="contained"
              disableElevation
            >
              Cambia icona
              <Hidden
                only={["xs", "sm", "md", "lg", "xl"]}
                implementation="css"
              >
                <input accept="image/*" onChange={handleCapture} type="file" />
              </Hidden>
            </Button>
          </Box>
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            onClick={removeIcon}
            disabled={!values.icon}
          >
            Elimina
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}
