import React from "react";

import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Toast from "../ui/Toast";
import Loading from "../ui/Loading";

import { useBmapi } from "../utils/bmapi-context";

export default function Common({ Routing }) {
  const {
    bmapi,
    loading,
    starting,
    message,
    showMessage,
    hideMessage,
  } = useBmapi();

  return (
    <MuiThemeProvider theme={bmapi.theme.app}>
      <CssBaseline />
      {!starting && bmapi.app ? <Routing /> : null}
      <Toast
        message={message.text}
        onClose={hideMessage}
        open={showMessage}
        variant={message.type}
      />
      <Loading loading={loading || starting} />
    </MuiThemeProvider>
  );
}
