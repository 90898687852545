import React, { useEffect, useCallback, useState } from "react";
import { FormattedMessage, FormattedRelativeTime } from "react-intl";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  List,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Adjust as AdjustIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Person as PersonIcon,
  SwapHoriz as SwapHorizIcon,
} from "@material-ui/icons";

import { useBmapi } from "../../utils/bmapi-context";
import ListItemInfo from "../ListItemInfo";

export default function EventPassActionForm({
  info,
  owner,
  qrCode,
  transactions,
}) {
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [isBurning, setIsBurning] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function onSubmit(event) {
    event.preventDefault();
    setIsBurning(true);
  }

  const burn = useCallback(() => {
    startLoading();

    bmapi
      .burnCoupon(qrCode)
      .then(() => setSuccess(true))
      .catch((e) => setError(bmapi.getErrorMessage(e)))
      .finally(() => {
        stopLoading();
        setIsBurning(false);
      });
  }, [bmapi, setError, setSuccess, startLoading, stopLoading, qrCode]);

  useEffect(() => {
    if (isBurning) burn();
  }, [isBurning, burn]);

  useEffect(() => {
    if (!!bmapi.settings.fastScan) setIsBurning(true);
  }, [bmapi.settings.fastScan]);

  const hasRestrictions = () =>
    !!info.business_restriction &&
    !info.business_restriction.includes(bmapi.getUserInfo().business.id);

  return (
    <React.Fragment>
      {hasRestrictions() && (
        <Box mb={2}>
          <Alert severity="warning">
            Coupon valido ma associato ad un altro punto vendita
          </Alert>
        </Box>
      )}
      <Card>
        <CardContent>
          <form onSubmit={onSubmit}>
            <List>
              <ListItemInfo
                Icon={ConfirmationNumberIcon}
                label="Prodotto"
                text="Pass evento"
              />
              <ListItemInfo
                Icon={AdjustIcon}
                label="Evento"
                text={info.campaign_name}
              />
              <ListItemInfo
                Icon={PersonIcon}
                label="Emessa a"
                text={owner && owner.email}
              />
              <ListItemInfo
                Icon={SwapHorizIcon}
                label="Ultime transazioni"
                text={
                  transactions &&
                  transactions.filter((t) => t.type === "TX_TYPE_BURN").length >
                    0 ? (
                    <React.Fragment>
                      {transactions
                        .filter((t) => t.type === "TX_TYPE_BURN")
                        .map((t) => (
                          <span key={t.id} style={{ display: "block" }}>
                            <FormattedRelativeTime
                              value={
                                (new Date(t.timestamp) - Date.now()) / 1000
                              }
                              numeric="auto"
                              updateIntervalInSeconds={10}
                            />
                          </span>
                        ))}
                    </React.Fragment>
                  ) : (
                    "Nessuna transazione ancora effettuata"
                  )
                }
              />
            </List>

            <FormControl fullWidth={true} margin="normal">
              {!success && !error ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isBurning}
                >
                  <FormattedMessage id="app-actions--confirm" />
                </Button>
              ) : (
                <Alert
                  variant="filled"
                  severity={success ? "success" : "error"}
                  // action={
                  //   <Button color="inherit" size="small" onClick={onSuccess}>
                  //     {success ? "Nuova scansione" : "Riprova"}
                  //   </Button>
                  // }
                >
                  {success ? "Coupon usato correttamente" : error}
                </Alert>
              )}
            </FormControl>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
