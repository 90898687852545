import React from "react";

import { MenuItem, TextField } from "@material-ui/core";

import { PRODUCT_TYPES } from "../../../utils/constants";

export default function UsePeriod({ values, handleChange }) {
  return (
    [PRODUCT_TYPES.CAMPAIGN_EVENT_PASS].includes(values.type) && (
      <TextField
        name="use_period"
        label="Periodo"
        value={values.use_period}
        onChange={handleChange("use_period")}
        select
        fullWidth
        required={!!values.use_frequency}
        margin="normal"
        helperText="Periodo di tempo"
      >
        {[
          { label: "Nessun periodo", value: "" },
          { label: "Giorno", value: "-1" },
          { label: "Settimana", value: "-7" },
          { label: "Mese", value: "-30" },
        ].map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    )
  );
}
