import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

import { MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";

export default function ListItemLink({ link, onClick }) {
  const { bmapi } = useBmapi();
  const classes = styles.useStyles();

  const renderLink = forwardRef((props, ref) => (
    <Link to={link.url || "#"} {...props} innerRef={ref} />
  ));

  function handleClick(e) {
    if (link.action) {
      e.preventDefault();
      link.action(e);
    }
    onClick(e);
  }

  return (
    <MenuItem
      button
      component={renderLink}
      onClick={handleClick}
      selected={link.active}
      classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}
    >
      {bmapi.settings.hideMenuIcon ? null : (
        <ListItemIcon style={{ color: "inherit" }}>{link.icon}</ListItemIcon>
      )}
      <ListItemText
        primary={link.label}
        secondary={link.sublabel || false}
        secondaryTypographyProps={{
          color: "inherit",
        }}
      />
    </MenuItem>
  );
}
