import React, { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  List,
  Typography,
} from "@material-ui/core";
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Add as AddIcon,
  Adjust as AdjustIcon,
  Euro as EuroIcon,
  Loyalty as LoyaltyIcon,
  Person as PersonIcon,
  TrendingUp as TrendingUpIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import * as CONSTANTS from "../../utils/constants";
import { useBmapi } from "../../utils/bmapi-context";
import ListItemInfo from "../ListItemInfo";
import NumberInput from "../NumberInput";

export default function EarningCardActionForm({ info, owner, qrCode, rules }) {
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    pointsToAdd: "",
    expense: "",
  });

  function handleChange(valueLabel) {
    return (event) => {
      setValues({ ...values, [valueLabel]: event.target.value });
    };
  }

  function onSubmit(event) {
    event.preventDefault();
    startLoading();

    bmapi
      .increaseCardBalance(
        qrCode,
        bmapi.getUserInfo().business.id,
        getExpensePoints() + getExtraPoints(),
        parseFloat(values.expense) || 0,
        getExtraPoints()
      )
      .then(() => setSuccess(true))
      .catch((e) => setError(bmapi.getErrorMessage(e)))
      .finally(stopLoading);
  }

  function convertExpenseToPoints(expense, quantum, rate) {
    return ((parseInt(expense / quantum) * quantum) / rate) * 100;
  }

  function getExpensePoints() {
    return values.expense.match(CONSTANTS.POSITIVE_FLOATING_NUMBER) || !rules
      ? convertExpenseToPoints(
          values.expense * 100,
          rules.quantum || 1,
          rules.rate || 1
        )
      : 0;
  }

  function getExtraPoints() {
    return values.pointsToAdd.match(CONSTANTS.POSITIVE_FLOATING_NUMBER)
      ? values.pointsToAdd * 100
      : 0;
  }

  function getNewCardBalance() {
    return info.available_value + getExtraPoints() + getExpensePoints();
  }

  return (
    <Card>
      <CardContent>
        <form onSubmit={onSubmit}>
          <List>
            <ListItemInfo Icon={LoyaltyIcon} label="Prodotto" text="Fidelity" />
            <ListItemInfo
              Icon={AdjustIcon}
              label="Campagna"
              text={info.campaign_name}
            />
            <ListItemInfo
              Icon={PersonIcon}
              label="Emessa a"
              text={owner && owner.email}
            />
            <ListItemInfo
              Icon={AccountBalanceWalletIcon}
              label="Saldo disponibile"
              text={
                <Typography color="primary">
                  <FormattedNumber
                    maximumFractionDigits={0}
                    value={info.available_value / 100}
                  />{" "}
                  punti
                </Typography>
              }
            />
            <ListItemInfo Icon={EuroIcon}>
              <NumberInput
                label={
                  <FormattedMessage id="app-admin--earningCard--expense" />
                }
                fullWidth
                variant="filled"
                value={values.expense}
                required
                onChange={handleChange("expense")}
                id="expense"
                name="expense"
                autoFocus
                float
              />
            </ListItemInfo>
            <ListItemInfo Icon={AddIcon}>
              <NumberInput
                label={
                  <FormattedMessage id="app-admin--earningCard--points-to-add" />
                }
                fullWidth
                variant="filled"
                value={values.pointsToAdd}
                name="pointsToAdd"
                id="pointsToAdd"
                onChange={handleChange("pointsToAdd")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">punti </InputAdornment>
                  ),
                }}
              />
            </ListItemInfo>
            <ListItemInfo
              Icon={TrendingUpIcon}
              label="Saldo dopo ricarica"
              text={
                <Typography color="primary">
                  <FormattedNumber
                    maximumFractionDigits={0}
                    value={getNewCardBalance() / 100}
                  />{" "}
                  punti
                </Typography>
              }
            />
          </List>

          <FormControl fullWidth={true} margin="normal">
            {!success && !error ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={info.available_value === getNewCardBalance()}
              >
                <FormattedMessage id="app-actions--confirm" />
              </Button>
            ) : (
              <Alert
                variant="filled"
                severity={success ? "success" : "error"}
                // action={
                //   <Button color="inherit" size="small" onClick={onSuccess}>
                //     {success ? "Nuova scansione" : "Riprova"}
                //   </Button>
                // }
              >
                {success ? "Transazione effettuata" : error}
              </Alert>
            )}
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}
