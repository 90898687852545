import React from "react";

import { MenuItem, TextField } from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function Subtype({ values, handleChange }) {
  return (
    <TextField
      name="subtype"
      label="Tipo di campagna"
      value={values.subtype}
      onChange={handleChange("subtype")}
      select
      fullWidth
      required
      margin="normal"
    >
      {[
        { label: "Coupon semplice", value: PRODUCT_SUBTYPES.COUPON_SIMPLE },
        { label: "Coupon sconto", value: PRODUCT_SUBTYPES.COUPON_DISCOUNT },
        { label: "Coupon valore", value: PRODUCT_SUBTYPES.COUPON_VALUE },
        { label: "Fidelity", value: PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE },
        { label: "Giftcard", value: PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE },
        { label: "Pass evento", value: PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE },
      ].map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
