import React from "react";

import Campaign from "./Campaign";
import Grid from "./Grid";

export default function CampaignsList({
  campaigns = [],
  signCampaign,
  loadCampaigns,
}) {
  return (
    <Grid
      items={campaigns}
      emptyMsg="page-home--no-campaigns-found"
      render={(campaign) => (
        <Campaign
          key={campaign.campaign_id}
          campaign={campaign}
          signCampaign={signCampaign}
          loadCampaigns={loadCampaigns}
        />
      )}
    />
  );
}
