import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Prompt } from "react-router-dom";

import {
  Avatar,
  Button,
  Container,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  Input,
  InputAdornment,
  InputLabel,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { useBmapi } from "../utils/bmapi-context";
import Title from "../ui/Title";

function getFlags(obj, flag) {
  return Object.keys(obj).reduce((a, c) => ({ ...a, [c]: flag }), {});
}

export default function Account() {
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const initialValues = {
    avatar: null,
    email: bmapi.getUserInfo().token_info.email,
    password: "",
    newPassword: "",
  };
  const initialDirty = getFlags(initialValues, false);
  const initialValid = getFlags(initialValues, true);
  const [values, setValues] = useState(initialValues);
  const [valid, setValid] = useState(initialValid);
  const [dirty, setDirty] = useState(initialDirty);
  const [filePreview, setFilePreview] = useState(null);
  const [enabled, setEnabled] = useState(false);

  const handleCapture = (event) => {
    const fileSelected = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const img = new Image();

      img.onload = function () {
        const AVATAR_SIZE = 200;
        const canvas = document.createElement("canvas");
        canvas.width = AVATAR_SIZE;
        canvas.height = AVATAR_SIZE;

        const width =
          img.width < img.height
            ? AVATAR_SIZE
            : (img.width * AVATAR_SIZE) / img.height;
        const height =
          img.width > img.height
            ? AVATAR_SIZE
            : (img.height * AVATAR_SIZE) / img.width;
        const offsetY = img.width < img.height ? (height - width) / -2 : 0;
        const offsetX = img.width > img.height ? (width - height) / -2 : 0;

        canvas.getContext("2d").drawImage(img, offsetX, offsetY, width, height);
        canvas.toBlob(
          (avatar) => setValues({ ...values, avatar }),
          "image/jpeg",
          0.65
        );

        setDirty({ ...dirty, avatar: !!fileSelected });
        setValid({ ...valid, avatar: !!fileSelected });
        setFilePreview(canvas.toDataURL("image/jpeg", 0.65));
      };
      img.src = e.target.result;
    };
    fileReader.readAsDataURL(fileSelected);
  };

  function handleChange(valueLabel) {
    return (event) => {
      setValues({ ...values, [valueLabel]: event.target.value });
      if (valueLabel !== "password") {
        setDirty({
          ...dirty,
          [valueLabel]: event.target.value !== initialValues[valueLabel],
        });
      }
      if (valueLabel === "password" || valueLabel === "newPassword") {
        setValid({
          ...valid,
          [valueLabel]: bmapi.validatePassword(event.target.value),
        });
      }
    };
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleClickShowNewPassword() {
    setShowNewPassword(!showNewPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  function handleSubmit(event) {
    event.preventDefault();
    const saveFns = [];
    if (dirty.avatar) {
      saveFns.push(bmapi.uploadProfilePicture(values.avatar));
    }
    if (dirty.newPassword) {
      saveFns.push(bmapi.doPasswordUpdate(values.password, values.newPassword));
    }

    if (saveFns.length > 0) {
      Promise.all(saveFns)
        .then(() => {
          notifySuccess("Salvataggio effettuato");
          setValues(initialValues);
          setDirty(initialDirty);
          setValid(initialValid);
        })
        .catch(notifyError);
    }
  }

  useEffect(() => {
    const status =
      Object.values(dirty).some((v) => v) &&
      Object.values(valid).every((v) => v);

    window.onbeforeunload = status ? () => true : undefined;
    setEnabled(status);

    return () => {
      window.onbeforeunload = undefined;
    };
  }, [valid, dirty]);

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Title subtitle={values.email}>
          <FormattedMessage id="app-navbar--account" />
        </Title>
        <Prompt
          when={enabled}
          message="Ci sono modifiche non salvate. Sei sicuro di voler uscire?"
        />
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Foto profilo
            </Typography>

            <Grid container spacing={3} wrap="nowrap" alignItems="center">
              <Grid item xs>
                <Avatar
                  src={filePreview || bmapi.userData.picture}
                  style={{ width: 100, height: 100 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mb={1}>
                  <Button
                    component="label"
                    htmlFor="avatarUpload"
                    color="secondary"
                    variant="contained"
                    disableElevation
                  >
                    Cambia foto
                  </Button>
                  <Hidden
                    only={["xs", "sm", "md", "lg", "xl"]}
                    implementation="css"
                  >
                    <input
                      accept="image/*"
                      onChange={handleCapture}
                      type="file"
                      id="avatarUpload"
                    />
                  </Hidden>
                </Box>
                {/* <Button color="secondary" variant="contained" disableElevation>
                  Elimina foto
                </Button> */}
              </Grid>
            </Grid>
          </CardContent>

          <CardContent>
            <Typography variant="h5" component="h2">
              Cambio password
            </Typography>

            {/* <FormControl margin="normal" fullWidth disabled>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                name="email"
                value={values.email}
                autoComplete="email"
                onChange={handleChange("email")}
                readOnly
              />
            </FormControl> */}

            <FormControl
              margin="normal"
              fullWidth
              error={dirty.password && !valid.password}
            >
              <InputLabel htmlFor="password">Password corrente</InputLabel>
              <Input
                name="password"
                value={values.password}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {dirty.password && !valid.password ? (
                <FormHelperText>Password non valida</FormHelperText>
              ) : null}
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              error={dirty.newPassword && !valid.newPassword}
            >
              <InputLabel htmlFor="password">Nuova Password</InputLabel>
              <Input
                name="newPassword"
                value={values.newPassword}
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                autoComplete="new-password"
                onChange={handleChange("newPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {dirty.newPassword && !valid.newPassword ? (
                <FormHelperText>Password non valida</FormHelperText>
              ) : null}
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!enabled}
              >
                Aggiorna
              </Button>
            </FormControl>
          </CardContent>
        </Card>
      </form>
    </Container>
  );
}
