import React from "react";
import { useHistory } from "react-router-dom";

import { useBmapi } from "../utils/bmapi-context";
import PasswordForgetForm from "../ui/PasswordForgetForm";

export default function PasswordReset({ routes }) {
  const { bmapi, notifySuccess, startLoading, stopLoading } = useBmapi();
  const history = useHistory();

  function onSubmit(email) {
    startLoading();

    return bmapi.doPasswordReset(email).finally(() => {
      history.push(routes.SIGN_IN);
      stopLoading();
      notifySuccess(
        "Email inviata, controlla la tua casella di posta elettronica"
      );
    });
  }

  return <PasswordForgetForm onSubmit={onSubmit} routes={routes} />;
}
