import React from "react";
import { useIntl } from "react-intl";

import Utils from "@date-io/date-fns";
import {
  DatePicker as MuiDatePicker,
  TimePicker as MuiTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export function TimePicker({ label, value, onChange, ...props }) {
  const intl = useIntl();

  return (
    <MuiPickersUtilsProvider utils={Utils} locale={intl.dateDictionary}>
      <MuiTimePicker
        ampm={false}
        margin="normal"
        label={label}
        value={value}
        onChange={onChange}
        format="HH:mm"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export function DatePicker({ label, value, onChange, ...props }) {
  const intl = useIntl();

  return (
    <MuiPickersUtilsProvider utils={Utils} locale={intl.dateDictionary}>
      <MuiDatePicker
        margin="normal"
        label={label}
        value={value}
        onChange={onChange}
        format="dd/MM/yyyy"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
