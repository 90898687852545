import React from "react";

import { InputAdornment } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function Value({ values, handleChange }) {
  const overrides = {
    [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: {
      max: 100,
    },
    [PRODUCT_SUBTYPES.COUPON_SIMPLE]: {},
    [PRODUCT_SUBTYPES.COUPON_VALUE]: {
      float: true,
    },
    [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: {
      label: "Valore iniziale",
      placeholder: "0",
      required: false,
    },
    [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: {
      float: true,
      label: "Prezzo",
      required: false,
    },
    [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: {
      float: true,
    },
  };

  return (
    ![PRODUCT_SUBTYPES.COUPON_SIMPLE].includes(values.subtype) && (
      <NumberInput
        name="value"
        label="Valore"
        value={values.value}
        onChange={handleChange("value")}
        fullWidth
        required={true}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{values.currency}</InputAdornment>
          ),
        }}
        {...overrides[values.subtype]}
      />
    )
  );
}
