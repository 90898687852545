import React, { useState, useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { endOfDay } from "date-fns";

import {
  Adjust as AdjustIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  CancelPresentation as CancelPresentationIcon,
  EmojiEvents as EmojiEventsIcon,
  ExpandMore as ExpandMoreIcon,
  FiberNew as FiberNewIcon,
  TimerOff as TimerOffIcon,
} from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@material-ui/core";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";

import { useBmapi } from "../../utils/bmapi-context";
import { IconsMap } from "../../utils/campaigns";
import styles from "../../utils/styles";
import {
  PRODUCT_TYPES,
  PRODUCT_SUBTYPES,
  TX_TYPES,
} from "../../utils/constants";
import Product from "../../ui/Product";
import ProductDetails from "../../ui/ProductDetails";

function formatTransaction(campaign, palette) {
  return (t) => {
    switch (campaign.type) {
      case PRODUCT_TYPES.CAMPAIGN_COUPON:
        switch (t.type) {
          case TX_TYPES.ISSUE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.counter > 1
                  ? `Ricevuti ${t.counter} coupon`
                  : "Ricevuto coupon",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: FiberNewIcon,
              iconBg: palette.info.main,
            };
          case TX_TYPES.BURN:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.counter > 1 ? `Usati ${t.counter} coupon` : "Coupon usato",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: ArrowBackIcon,
              iconBg: palette.warning.main,
            };
          case TX_TYPES.EXPIRE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.counter > 1
                  ? `${t.counter} coupon scaduti`
                  : "Coupon scaduto",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: TimerOffIcon,
              iconBg: palette.error.main,
            };

          default:
            break;
        }
        break;

      case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
        switch (t.type) {
          case TX_TYPES.ISSUE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.counter > 1 ? `Ricevuti ${t.counter} pass` : "Ricevuto pass",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: FiberNewIcon,
              iconBg: palette.info.main,
            };
          case TX_TYPES.BURN:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: t.counter > 1 ? `Usati ${t.counter} pass` : "Pass usato",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: ArrowBackIcon,
              iconBg: palette.warning.main,
            };
          case TX_TYPES.EXPIRE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.counter > 1 ? `${t.counter} pass scaduti` : "Pass scaduto",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: TimerOffIcon,
              iconBg: palette.error.main,
            };

          default:
            break;
        }
        break;

      case "CAMPAIGN_SHOPPING_CARD":
        switch (t.type) {
          case TX_TYPES.ISSUE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.counter > 1 ? `Ricevute ${t.counter} card` : "Ricevuta card",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: FiberNewIcon,
              iconBg: palette.info.main,
            };
          case TX_TYPES.DECREASE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: `Utilizzati ${t.value / 100} ${campaign.rules.currency}`,
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: ArrowBackIcon,
              iconBg: palette.warning.main,
            };
          case TX_TYPES.BURN:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.counter > 1 ? `${t.counter} card esaurite` : "Card esaurita",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: CancelPresentationIcon,
              iconBg: palette.error.main,
            };
          case TX_TYPES.EXPIRE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.counter > 1 ? `${t.counter} card scadute` : "Card scaduta",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: TimerOffIcon,
              iconBg: palette.error.main,
            };

          default:
            break;
        }
        break;

      case "CAMPAIGN_EARNING_CARD":
        switch (t.type) {
          case TX_TYPES.ISSUE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.description === "Prize issue"
                  ? "Ricevuto premio"
                  : `Ricevuta card`,
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon:
                t.description === "Prize issue"
                  ? EmojiEventsIcon
                  : FiberNewIcon,
              iconBg:
                t.description === "Prize issue"
                  ? palette.warning.main
                  : palette.info.main,
            };
          case TX_TYPES.DECREASE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: `Utilizzati ${t.value / 100} punti`,
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: ArrowBackIcon,
              iconBg: palette.error.main,
            };
          case TX_TYPES.INCREASE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: `Ottenuti ${t.value / 100} punti`,
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: ArrowForwardIcon,
              iconBg: palette.success.main,
            };
          case TX_TYPES.EXPIRE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.counter > 1 ? `${t.counter} card scadute` : "Card scaduta",
              subtitle: `${new Date(t.timestamp).toLocaleString()} presso ${
                t.business_name
              }`,
              Icon: TimerOffIcon,
              iconBg: palette.error.main,
            };

          default:
            break;
        }
        break;

      default:
        break;
    }
  };
}

function toMerge(tx1, tx2) {
  return (
    tx1.business_name === tx2.business_name &&
    tx1.description === tx2.description &&
    tx1.type === tx2.type &&
    ![TX_TYPES.DECREASE, TX_TYPES.INCREASE].includes(tx1.type) &&
    tx1.timestamp.slice(0, 16) === tx2.timestamp.slice(0, 16)
  );
}

function mergeTxs(acc, tx) {
  if (acc.length === 0) {
    return [tx];
  }
  const last = acc[acc.length - 1];
  return toMerge(last, tx)
    ? [
        ...acc.slice(0, -1),
        { ...last, counter: last.counter ? last.counter + 1 : 2 },
      ]
    : [...acc, tx];
}

function getDetails({ campaign, statistics }, availability) {
  switch (campaign.type) {
    case PRODUCT_TYPES.CAMPAIGN_EARNING_CARD:
      return [
        {
          label: "Saldo",
          value: `${availability} ${campaign.rules.currency}`,
        },
        {
          label: "Punti ricevuti",
          value: `${statistics.received_value / 100} ${
            campaign.rules.currency
          }`,
        },
        {
          label: "Punti usati",
          value: `${statistics.used_value / 100} ${campaign.rules.currency}`,
        },
        {
          label: "Punti scaduti",
          value: `${statistics.expired_value / 100} ${campaign.rules.currency}`,
        },
      ];
    case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
      return [
        {
          label: "Carte ricevute",
          value: statistics.received_qty,
        },
        {
          label: "Valore residuo",
          value: `${availability} ${campaign.rules.currency}`,
        },
        {
          label: "Valore usato",
          value: `${statistics.used_value / 100} ${campaign.rules.currency}`,
        },
        {
          label: "Valore scaduto",
          value: `${statistics.expired_value / 100} ${campaign.rules.currency}`,
        },
      ];
    case PRODUCT_TYPES.CAMPAIGN_COUPON:
      return [
        {
          label: "Coupon disponibili",
          value: availability,
        },
        {
          label: "Coupon ricevuti",
          value: statistics.received_qty,
        },
        {
          label: "Coupon usati",
          value: statistics.used_qty,
        },
        {
          label: "Coupon scaduti",
          value: statistics.expired_qty,
        },
      ];

    case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
      return [
        {
          label: "Pass disponibili",
          value: availability,
        },
        {
          label: "Pass ricevuti",
          value: statistics.received_qty,
        },
        {
          label: "Pass usati",
          value: statistics.used_qty,
        },
        {
          label: "Pass scaduti",
          value: statistics.expired_qty,
        },
      ];

    default:
      break;
  }

  return [];
}

function getProductInfo(products, type, businesses = []) {
  switch (type) {
    case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
      return products.map((p) => ({
        id: p.id,
        title: `Valore disponibile: ${p.available_value / 100} ${p.currency}`,
        subtitle: `Scadenza: ${new Date(p.expiration_date).toLocaleDateString(
          "it-IT"
        )}`,
        Icon: IconsMap[p.subtype],
      }));

    case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
    case PRODUCT_TYPES.CAMPAIGN_COUPON:
      return products
        .reduce(
          (acc, p) =>
            acc.some(
              (prod) =>
                prod.expiration_date ===
                  endOfDay(new Date(p.expiration_date)).toISOString() &&
                prod.business_restrictions?.join(";") ===
                  p.business_restrictions?.join(";")
            )
              ? acc
              : [
                  ...acc,
                  {
                    product: p,
                    expiration_date: endOfDay(
                      new Date(p.expiration_date)
                    ).toISOString(),
                    counter: products.filter(
                      (prod) =>
                        endOfDay(
                          new Date(prod.expiration_date)
                        ).toISOString() ===
                        endOfDay(new Date(p.expiration_date)).toISOString()
                    ).length,
                  },
                ],
          []
        )
        .map((p) => ({
          id: p.product.id,
          title: `${p.counter} ${
            type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS ? "Pass" : "Coupon"
          }${
            p.product.subtype === PRODUCT_SUBTYPES.COUPON_VALUE
              ? ` [${(p.counter * p.product.value) / 100} ${
                  p.product.currency
                }]`
              : ""
          } ${
            p.product.business_restrictions
              ? `utilizzabile presso ${p.product.business_restrictions
                  .map((bs) => businesses.find((b) => b.id === bs)?.name)
                  .join(", ")}`
              : `disponibil${p.counter === 1 ? "e" : "i"}`
          }`,
          subtitle: `Scadenza: ${new Date(p.expiration_date).toLocaleDateString(
            "it-IT"
          )}`,
          counter: p.counter,
          Icon: IconsMap[p.product.subtype],
        }));

    default:
      return [];
  }
}

function ProductCard({ title, subtitle, Icon, iconBg, counter }) {
  const classes = styles.useStyles();

  return (
    <CardHeader
      avatar={
        Icon && (
          <Badge
            color="secondary"
            badgeContent={counter > 1 ? counter : 0}
            overlap="circle"
          >
            <Avatar
              className={classes.cardIcon}
              style={iconBg && { backgroundColor: iconBg }}
            >
              <Icon />
            </Avatar>
          </Badge>
        )
      }
      title={title}
      subheader={subtitle}
    />
  );
}

export default function Campaign() {
  const { campaignId } = useParams();
  const history = useHistory();
  const {
    bmapi,
    userId,
    startLoading,
    stopLoading,
    notifySuccess,
    notifyError,
  } = useBmapi();
  const [campaign, setCampaign] = useState(null);
  const [stats, setStats] = useState(null);
  const [products, setProducts] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [prizes, setPrizes] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [viewStores, setViewStores] = useState(false);
  const [stores, setStores] = useState(false);
  const [reserved, setReserved] = useState(false);
  const [issuedQuantity, setIssuedQuantity] = useState(false);
  const [viewTransactions, setViewTransactions] = useState(false);

  const updateTransactions = useCallback(() => {
    if (!viewTransactions) {
      bmapi.getTransactionsByCampaign(campaignId).then((transactions) => {
        setTransactions(transactions || []);
      });
    }
  }, [bmapi, campaignId, viewTransactions]);

  const handleOpenTransactions = (f) => {
    if (f && !transactions) {
      updateTransactions();
    }
    setViewTransactions(f);
  };

  const handleCloseStores = () => setViewStores(false);

  const byExpiration = (a, b) =>
    new Date(a.expiration_date) - new Date(b.expiration_date);

  const updateData = useCallback(() => {
    startLoading();

    // setTransactions(false);
    // updateTransactions();

    Promise.all([
      bmapi.getCampaign(campaignId),
      userId ? bmapi.getUserProductsStats() : [],
      userId ? bmapi.getUserProductsByCampaign(campaignId) : [],
      userId ? bmapi.getUserReservations() : [],
    ])
      .then(([campaign, stats, products, reservations]) => {
        setCampaign(campaign.campaign);
        setIssuedQuantity(campaign.issued_qty);
        setStats(stats.find((s) => s.campaign.id === campaignId) || false);
        setProducts(products.sort(byExpiration) || []);

        setReserved(
          (reservations || []).some(
            (res) =>
              res.campaign_id === campaign.campaign.id && res.status === 0
          )
        );

        Promise.all(
          products
            .map((p) => p.business_restrictions)
            .filter((res) => res)
            .flat()
            .map((bId) => bmapi.getBusiness(bId))
        ).then(setBusinesses);

        if (userId && campaign.campaign.loop_campaign) {
          bmapi.getCampaignTerms(campaign.campaign.id).then((st) => {
            setStores(st.length === 0 || st.filter((s) => s.signed));
          });
        }

        if (campaign.campaign.type === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD) {
          setPrizes(campaign.campaign.rules.prizes || []);
        }
      })
      .catch(notifyError)
      .finally(stopLoading);
  }, [
    campaignId,
    // updateTransactions,
    userId,
    bmapi,
    startLoading,
    stopLoading,
    notifyError,
  ]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  function getProductExpiration() {
    const nextProd = getFirstToUseProduct();
    return nextProd && nextProd.expiration_date;
  }

  function getFirstToUseProduct() {
    return products.sort(byExpiration)[0];
  }

  function getAvailability({ campaign, statistics }) {
    switch (campaign.type) {
      case PRODUCT_TYPES.CAMPAIGN_EARNING_CARD:
      case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
        return (
          (statistics.received_value -
            statistics.used_value -
            statistics.expired_value) /
          100
        );

      case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
      case PRODUCT_TYPES.CAMPAIGN_COUPON:
        return campaign.rules.shot_number === -1
          ? statistics.received_qty - statistics.expired_qty
          : statistics.received_qty * (campaign.rules.shot_number || 1) -
              statistics.used_qty -
              statistics.expired_qty;

      default:
        break;
    }

    return false;
  }

  const prizesTier = new Set(
    prizes?.map((p) => p.threshold).sort((a, b) => a - b)
  );

  const prizesForTier = (tier) => {
    return prizes.filter((p) => p.threshold === tier);
  };

  const redeem = useCallback(
    (prizeId) => {
      startLoading();

      bmapi
        .redeemPrize(products[0].id, prizeId)
        .then(() => {
          notifySuccess("Premio riscosso con successo");
          updateData();
        })
        .catch(notifyError)
        .finally(stopLoading);
    },
    [
      updateData,
      bmapi,
      products,
      startLoading,
      stopLoading,
      notifySuccess,
      notifyError,
    ]
  );

  return !!campaign ? (
    <Container maxWidth="sm">
      <Dialog open={viewStores} onClose={handleCloseStores}>
        <DialogTitle>Negozi aderenti alla campagna</DialogTitle>
        <DialogContent>
          {stores === false ? (
            "Loading..."
          ) : stores === true ? (
            "Tutti i negozi del circuito"
          ) : stores.length === 0 ? (
            "Nessun negozio ha ancora aderito alla campagna"
          ) : (
            <ul>
              {stores.map((s) => (
                <li key={s.business_id}>{s.business_name}</li>
              ))}
            </ul>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStores} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Box my={2}>
        <Button onClick={history.goBack} startIcon={<ArrowBackIcon />}>
          Indietro
        </Button>
      </Box>

      <Box mb={4}>
        <Product
          campaign={campaign}
          products={products}
          issuedQuantity={issuedQuantity}
          availability={stats ? getAvailability(stats) : 0}
          getProductExpiration={getProductExpiration}
          showCardImage
          onUpdate={updateData}
          reserved={reserved}
        />
      </Box>

      {campaign.type !== PRODUCT_TYPES.CAMPAIGN_EARNING_CARD &&
        products?.filter((p) => p.status === 0).length > 0 && (
          <Box mb={4}>
            <Card>
              {getProductInfo(
                products.filter((p) => p.status === 0),
                campaign.type,
                businesses
              ).map((item) => (
                <ProductCard key={item.id} {...item} />
              ))}
            </Card>
          </Box>
        )}

      {campaign.type === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD &&
        Array.isArray(prizes) &&
        prizes.length > 0 && (
          <Box mb={4}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id="prizes">
                <Typography>Premi</Typography>
              </AccordionSummary>

              <Timeline style={{ padding: 0 }}>
                {[...prizesTier].map((p, i) => (
                  <TimelineItem key={p}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {p / 100} punti
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      {products[0]?.available_value > p ? (
                        <TimelineDot color="primary">
                          <EmojiEventsIcon />
                        </TimelineDot>
                      ) : (
                        <TimelineDot color="grey">
                          <AdjustIcon />
                        </TimelineDot>
                      )}
                      {i < prizesTier.size - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent style={{ flexGrow: 4 }}>
                      {prizesForTier(p).map((prize) => (
                        <Typography gutterBottom key={prize.id}>
                          {prize.name}{" "}
                          {products[0]?.available_value > p ? (
                            <Button
                              color="primary"
                              size="small"
                              onClick={() => redeem(prize.id)}
                            >
                              Richiedi
                            </Button>
                          ) : null}
                        </Typography>
                      ))}
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Accordion>
          </Box>
        )}

      <Box mb={4}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Dettagli campagna</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            {campaign.description.split("\n").map(
              (item, key, arr) =>
                item && (
                  <Typography
                    variant="body2"
                    key={key}
                    gutterBottom={!arr[key + 1]}
                  >
                    {item}
                  </Typography>
                )
            )}
            {campaign.tos_consumer_url && (
              <Box mt={1}>
                <Typography variant="body2">
                  Leggi i{" "}
                  <Link
                    href={campaign.tos_consumer_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    termini e condizioni del servizio
                  </Link>
                </Typography>
              </Box>
            )}
            {userId && campaign.loop_campaign && (
              <Box mt={1}>
                <Typography variant="body2">
                  <Link onClick={() => setViewStores(true)}>
                    Negozi aderenti all'iniziativa
                  </Link>
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>

      {stats && (
        <Box mb={4}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Statistiche</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              {getDetails(stats, getAvailability(stats)).map((d) => (
                <ProductDetails key={d.label} label={d.label} value={d.value} />
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      {products?.length > 0 && (
        <Box mb={4}>
          <Accordion
            expanded={viewTransactions}
            onChange={(_, f) => handleOpenTransactions(f)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Transazioni</Typography>
            </AccordionSummary>
            {!transactions ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                m={2}
              >
                <CircularProgress />
              </Box>
            ) : (
              transactions
                .reduce(mergeTxs, [])
                .map(formatTransaction(campaign, bmapi.theme.app.palette))
                .map((item) => <ProductCard key={item.id} {...item} />)
            )}
          </Accordion>
        </Box>
      )}

      <Box my={2}>
        <Button onClick={history.goBack} startIcon={<ArrowBackIcon />}>
          Indietro
        </Button>
      </Box>
    </Container>
  ) : null;
}
