import React, { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import classNames from "classnames";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Adjust as AdjustIcon,
  Close as CloseIcon,
  Info as InfoIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Send as SendIcon,
} from "@material-ui/icons";

import CampaignPerformance from "./CampaignPerformance";
import Confirm from "./Confirm";
import DeleteCampaign from "./DeleteCampaign";
import IssueProduct from "./IssueProduct";

import styles from "../utils/styles";
import {
  CAMPAIGN_STATUS,
  CONSUMER_ROUTES,
  MERCHANT_ROUTES,
  ROLES,
} from "../utils/constants";
import { useBmapi } from "../utils/bmapi-context";
import { IconsMap } from "../utils/campaigns";

function CampaignElement({ campaign, signCampaign, loadCampaigns }) {
  const classes = styles.useStyles();
  const {
    bmapi,
    baseUrl,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [performance, setPerformance] = useState(false);
  const [terms, setTerms] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [acceptTOS, setAcceptTOS] = useState(!campaign.tos_merchant_url);
  const [sending, setSending] = useState(false);

  const askSendConfirm = useCallback(() => setSending(true), [setSending]);
  const closeSendConfirm = useCallback(() => setSending(false), [setSending]);

  const getSharableLink = () =>
    `${baseUrl}${CONSUMER_ROUTES.CAMPAIGN.replace(
      ":campaignId",
      campaign.campaign_id
    ).slice(1)}`;

  const copyLink = () => {
    navigator.clipboard
      .writeText(getSharableLink())
      .then(() => notifySuccess("Link copiato con successo"))
      .catch(notifyError);
  };

  const handleDelete = useCallback(() => {
    startLoading();

    return bmapi
      .deleteCampaign(campaign.campaign_id)
      .then(() => {
        loadCampaigns();
        notifySuccess(
          `Hai eliminato con successo la campagna ${campaign.name}`
        );
      })
      .catch(notifyError)
      .finally(stopLoading);
  }, [
    campaign,
    loadCampaigns,
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  ]);

  const handleJoin = useCallback(() => {
    setLoading(true);

    signCampaign(campaign)
      .then(loadCampaigns)
      .then(() => {
        notifySuccess(
          `Hai aderito con successo alla campagna ${campaign.name}`
        );
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  }, [campaign, loadCampaigns, notifySuccess, setLoading, signCampaign]);

  function handleCloseClick() {
    setExpanded(false);
  }

  function handleExpandClick() {
    setLoading(true);
    setPerformance(false);

    bmapi
      .getCampaignDetails(campaign.campaign_id)
      .then(({ use_terms, performance, campaign }) => {
        setPerformance(performance);
        setTerms(use_terms);
        setInfo(campaign);
        setExpanded(true);
        setLoading(false);
      })
      .catch(notifyError);
  }

  const canDelete = () =>
    campaign.canIssue &&
    (campaign.demo ||
      (performance?.issued_value === 0 && performance?.issued_qty === 0));

  const CampaignIcon = IconsMap[campaign.type];

  return (
    <React.Fragment>
      <IssueProduct
        campaign={campaign}
        open={sending}
        onClose={closeSendConfirm}
      />

      <Confirm
        open={showConfirm}
        onConfirm={handleJoin}
        onCancel={() => setShowConfirm(false)}
        disabled={!acceptTOS}
      >
        <Typography variant="body1" gutterBottom>
          Sei sicuro di voler aderire alla campagna{" "}
          <strong>{campaign.name}</strong>?
        </Typography>

        {campaign.tos_merchant_url && (
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTOS}
                onChange={(_, f) => setAcceptTOS(f)}
                name="flag"
                color="primary"
              />
            }
            label={
              <Typography variant="caption">
                Dichiaro di avere letto e approvato il regolamento della
                campagna le condizioni di adesione pubblicate su{" "}
                <a
                  href={campaign.tos_merchant_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  questa pagina
                </a>
              </Typography>
            }
          />
        )}
      </Confirm>

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            src={campaign.avatar_url || ""}
            className={classNames(classes.cardIcon, {
              [classes.disabled]:
                campaign.toSign || campaign.status === CAMPAIGN_STATUS.EXPIRED,
            })}
          >
            {CampaignIcon ? <CampaignIcon /> : <AdjustIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              {campaign.name}{" "}
              {campaign.demo && (
                <Chip label="demo" size="small" className={classes.demoChip} />
              )}
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textSecondary"
              >
                {campaign.business_owner_name}
              </Typography>
              <br />
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color={
                  campaign.status === CAMPAIGN_STATUS.EXPIRED
                    ? "primary"
                    : "textPrimary"
                }
              >
                {`${
                  campaign.status === CAMPAIGN_STATUS.EXPIRED
                    ? "Scaduta il"
                    : "Scadenza:"
                } ${new Date(campaign.expiration_date).toLocaleDateString(
                  "it-IT"
                )}`}
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          {campaign.canIssue && campaign.campaign_data.waiting_list && (
            <IconButton
              component={RouterLink}
              to={MERCHANT_ROUTES.RESERVATIONS.replace(
                ":campaignId",
                campaign.campaign_id
              )}
            >
              <PlaylistAddCheckIcon />
            </IconButton>
          )}
          {campaign.canIssue && (
            <IconButton
              aria-label="send"
              onClick={askSendConfirm}
              disabled={loading}
            >
              <SendIcon />
            </IconButton>
          )}
          {campaign.toSign && (
            <IconButton
              aria-label="join"
              onClick={() => setShowConfirm(true)}
              disabled={loading}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          )}
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              edge="end"
              aria-label="info"
              onClick={expanded ? handleCloseClick : handleExpandClick}
            >
              {expanded ? <CloseIcon /> : <InfoIcon />}
            </IconButton>
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem>
            <ListItemText inset secondary={campaign.description} />
          </ListItem>
          {info.link_distribution && (
            <ListItem>
              <ListItemText
                inset
                primary={
                  <Tooltip title={getSharableLink()}>
                    <Button
                      variant="contained"
                      onClick={copyLink}
                      disableElevation
                    >
                      Copia il link di condivisione
                    </Button>
                  </Tooltip>
                }
              />
            </ListItem>
          )}
          {campaign.toSign ? null : (
            <ListItem>
              <ListItemText
                inset
                primary={
                  <CampaignPerformance
                    fromLoop={campaign.loop_campaign}
                    owner={campaign.business_id === campaign.business_owner_id}
                    type={campaign.type}
                    performance={performance}
                    terms={terms}
                  />
                }
              />
            </ListItem>
          )}
          {canDelete() && (
            <ListItem>
              <ListItemText
                inset
                primary={
                  <DeleteCampaign
                    handleDelete={handleDelete}
                    campaign={campaign}
                  >
                    {(onClick) => (
                      <Link color="error" component="button" onClick={onClick}>
                        Elimina la campagna <strong>{campaign.name}</strong>
                      </Link>
                    )}
                  </DeleteCampaign>
                }
              />
            </ListItem>
          )}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

function CampaignsList({ campaigns, title, signCampaign, loadCampaigns }) {
  return campaigns.length ? (
    <Box mb={4}>
      <Card>
        <List subheader={<ListSubheader>{title}</ListSubheader>}>
          {campaigns.map((campaign, i) => (
            <React.Fragment key={campaign.id}>
              {i !== 0 ? <Divider variant="inset" component="li" /> : null}
              <CampaignElement
                key={campaign.id}
                campaign={campaign}
                signCampaign={signCampaign}
                loadCampaigns={loadCampaigns}
              />
            </React.Fragment>
          ))}
        </List>
      </Card>
    </Box>
  ) : null;
}

export default function CampaignsTable({
  campaigns = [],
  signCampaign,
  loadCampaigns,
}) {
  const { bmapi } = useBmapi();

  const activeCampaigns = (c) =>
    !c.toSign && c.status === CAMPAIGN_STATUS.ACTIVE;
  const availableCampaigns = (c) =>
    c.toSign && c.status === CAMPAIGN_STATUS.ACTIVE;
  const expiredCampaigns = (c) =>
    !c.toSign && c.status === CAMPAIGN_STATUS.EXPIRED;

  return campaigns.length ? (
    <>
      <CampaignsList
        title="Campagne attive"
        campaigns={campaigns.filter(activeCampaigns)}
        signCampaign={signCampaign}
        loadCampaigns={loadCampaigns}
      />
      {bmapi.getUserInfo().role !== ROLES.TENANT_MANAGER && (
        <CampaignsList
          title="Campagne disponibili"
          campaigns={campaigns.filter(availableCampaigns)}
          signCampaign={signCampaign}
          loadCampaigns={loadCampaigns}
        />
      )}
      <CampaignsList
        title="Campagne scadute"
        campaigns={campaigns.filter(expiredCampaigns)}
        signCampaign={signCampaign}
        loadCampaigns={loadCampaigns}
      />
    </>
  ) : (
    <Card>
      <CardContent>
        Nessuna campagna presente.
        <br />
        Crea la tua prima campagna usando il pulsante in basso a destra
      </CardContent>
    </Card>
  );
}
