import React from "react";

import { TextField } from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function Currency({ values, handleChange }) {
  return (
    [
      PRODUCT_SUBTYPES.COUPON_VALUE,
      PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE,
      PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
    ].includes(values.subtype) && (
      <TextField
        name="currency"
        label="Valuta"
        value={values.currency}
        onChange={handleChange("currency")}
        fullWidth
        required={
          values.subtype !== PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE ||
          !!values.value
        }
        margin="normal"
      />
    )
  );
}
