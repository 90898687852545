import React, { useState, useCallback } from "react";

import { Dialog, DialogContent } from "@material-ui/core";

import CampaignCard from "./CampaignCard";
import CampaignEdit from "./CampaignEdit";
import IssueProduct from "./IssueProduct";

import { useBmapi } from "../utils/bmapi-context";

export default function Campaign({ campaign, signCampaign, loadCampaigns }) {
  const {
    bmapi,
    notifySuccess,
    notifyError,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [editing, setEditing] = useState(false);
  const [sending, setSending] = useState(false);

  const askSendConfirm = useCallback(() => setSending(true), [setSending]);
  const closeSendConfirm = useCallback(() => setSending(false), [setSending]);

  const handleDelete = useCallback(() => {
    startLoading();

    return bmapi
      .deleteCampaign(campaign.campaign_id)
      .then(() => {
        loadCampaigns();
        notifySuccess(
          `Hai eliminato con successo la campagna ${campaign.name}`
        );
      })
      .catch(notifyError)
      .finally(stopLoading);
  }, [
    campaign,
    loadCampaigns,
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  ]);

  const handleEdit = useCallback(() => setEditing(true), [setEditing]);
  const handleJoin = useCallback(() => {
    startLoading();

    signCampaign(campaign).then(() => {
      loadCampaigns();
      notifySuccess(`Hai aderito con successo alla campagna ${campaign.name}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
      stopLoading();
    });
  }, [
    campaign,
    loadCampaigns,
    notifySuccess,
    signCampaign,
    startLoading,
    stopLoading,
  ]);

  return (
    <React.Fragment>
      <Dialog onClose={() => setEditing(false)} open={editing} width={"md"}>
        <DialogContent dividers>
          {editing ? (
            <CampaignEdit
              campaign={campaign}
              onCancel={() => setEditing(false)}
            />
          ) : null}
        </DialogContent>
      </Dialog>

      <IssueProduct
        campaign={campaign}
        open={sending}
        onClose={closeSendConfirm}
      />

      <CampaignCard
        campaign={campaign}
        handleJoin={handleJoin}
        handleEdit={handleEdit}
        handleSend={askSendConfirm}
        handleDelete={handleDelete}
      />
    </React.Fragment>
  );
}
