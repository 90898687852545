import React from "react";
import { useHistory } from "react-router-dom";

import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";

import { MERCHANT_ROUTES, PRODUCT_SUBTYPES } from "../utils/constants";
import { IconsMap } from "../utils/campaigns";

const CampaignMenuItem = React.forwardRef(({ name, Icon, onClick }, ref) => (
  <MenuItem onClick={onClick} innerRef={ref}>
    <ListItemIcon>
      <Icon />
    </ListItemIcon>
    <ListItemText
      primary={name}
      secondary={`Crea una campagna di tipo ${name}`}
    />
  </MenuItem>
));

export default function CreateCampaignButton({ trigger }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const create = (subtype) => {
    handleClose();
    history.push(
      MERCHANT_ROUTES.CREATE_CAMPAIGN.replace(
        ":subtype?",
        subtype.toLowerCase()
      )
    );
  };

  return (
    <React.Fragment>
      {trigger(handleClick)}
      <Drawer anchor="bottom" open={Boolean(anchorEl)} onClose={handleClose}>
        <List>
          <CampaignMenuItem
            name="Coupon"
            Icon={IconsMap[PRODUCT_SUBTYPES.COUPON_SIMPLE]}
            onClick={() => create(PRODUCT_SUBTYPES.COUPON_SIMPLE)}
          />
          <CampaignMenuItem
            name="Fidelity"
            Icon={IconsMap[PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]}
            onClick={() => create(PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE)}
          />
          <CampaignMenuItem
            name="Giftcard"
            Icon={IconsMap[PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]}
            onClick={() => create(PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE)}
          />
          <CampaignMenuItem
            name="Pass evento"
            Icon={IconsMap[PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]}
            onClick={() => create(PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE)}
          />
        </List>
      </Drawer>
    </React.Fragment>
  );
}
