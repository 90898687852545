import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Link } from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";
import LoginCard from "../ui/LoginCard";

export default function MoreInfo({ routes }) {
  const { bmapi } = useBmapi();

  return (
    <LoginCard title="Informazioni">
      <Box mt={4} mb={4} textAlign="left">
        {bmapi.settings.moreInfo}
      </Box>

      <Link component={RouterLink} to={routes.HOME}>
        Torna al login
      </Link>
    </LoginCard>
  );
}
