import React from "react";
import { FormattedRelativeTime } from "react-intl";

import { Box, Typography } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";

import ProductDetails from "./ProductDetails";
import { PRODUCT_SUBTYPES } from "../utils/constants";

function getDetails(performance, terms, type, owner, fromLoop) {
  switch (type) {
    case PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE:
      return [
        ...(owner
          ? [
              {
                label: "Saldo",
                value:
                  performance &&
                  `${
                    (performance.issued_value -
                      performance.used_value -
                      performance.expired_value) /
                    100
                  } ${"" && "campaign.rules.currency"}`,
              },
              {
                label: "Punti usati",
                value:
                  performance &&
                  `${performance.used_value / 100} ${
                    "" && "campaign.rules.currency"
                  }`,
              },
              {
                label: "Punti scaduti",
                value:
                  performance &&
                  `${performance.expired_value / 100} ${
                    "" && "campaign.rules.currency"
                  }`,
              },
            ]
          : []),
        {
          label: "Punti rilasciati",
          value:
            performance &&
            `${performance.issued_value / 100} ${
              "" && "campaign.rules.currency"
            }`,
        },
        ...(owner && fromLoop
          ? [
              {
                label: "Negozi aderenti",
                value: terms.length
                  ? `${terms.filter((t) => t.signed).length}/${terms.length}`
                  : "Tutti",
              },
            ]
          : []),
      ];
    case PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE:
      return [
        ...(owner
          ? [
              {
                label: "Carte rilasciate",
                value: performance && performance.issued_qty,
              },
              {
                label: "Valore residuo",
                value:
                  performance &&
                  `${
                    (performance.issued_value -
                      performance.used_value -
                      performance.expired_value) /
                    100
                  } ${"" && "campaign.rules.currency"}`,
              },
              {
                label: "Valore scaduto",
                value:
                  performance &&
                  `${performance.expired_value / 100} ${
                    "" && "campaign.rules.currency"
                  }`,
              },
            ]
          : []),
        {
          label: "Valore usato",
          value:
            performance &&
            `${performance.used_value / 100} ${
              "" && "campaign.rules.currency"
            }`,
        },
        ...(owner && fromLoop
          ? [
              {
                label: "Negozi aderenti",
                value: terms.length
                  ? `${terms.filter((t) => t.signed).length}/${terms.length}`
                  : "Tutti",
              },
            ]
          : []),
      ];
    case PRODUCT_SUBTYPES.COUPON_DISCOUNT:
    case PRODUCT_SUBTYPES.COUPON_MULTIWIN:
    case PRODUCT_SUBTYPES.COUPON_SIMPLE:
    case PRODUCT_SUBTYPES.COUPON_VALUE:
      return [
        {
          label: "Ultimo coupon usato",
          value:
            !performance || !performance.last_tx ? (
              false
            ) : performance.last_tx.id ? (
              <FormattedRelativeTime
                value={
                  (new Date(performance.last_tx.created_at) - Date.now()) / 1000
                }
                numeric="auto"
                updateIntervalInSeconds={10}
              />
            ) : (
              "-"
            ),
        },
        {
          label: "Coupon usati",
          value: performance && performance.used_qty,
        },
        ...(owner
          ? [
              {
                label: "Coupon disponibili",
                value:
                  performance &&
                  performance.issued_qty -
                    performance.used_qty -
                    performance.expired_qty,
              },
              {
                label: "Coupon rilasciati",
                value: performance && performance.issued_qty,
              },
              {
                label: "Coupon scaduti",
                value: performance && performance.expired_qty,
              },
            ]
          : []),
        ...(owner && fromLoop
          ? [
              {
                label: "Negozi aderenti",
                value: terms.length
                  ? `${terms.filter((t) => t.signed).length}/${terms.length}`
                  : "Tutti",
              },
            ]
          : []),
      ];

    case PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE:
      return [
        {
          label: "Ultimo pass usato",
          value:
            !performance || !performance.last_tx ? (
              false
            ) : performance.last_tx.id ? (
              <FormattedRelativeTime
                value={
                  (new Date(performance.last_tx.created_at) - Date.now()) / 1000
                }
                numeric="auto"
                updateIntervalInSeconds={10}
              />
            ) : (
              "-"
            ),
        },
        {
          label: "Pass usati",
          value: performance && performance.used_qty,
        },
        ...(owner
          ? [
              {
                label: "Pass disponibili",
                value:
                  performance &&
                  performance.issued_qty -
                    performance.used_qty -
                    performance.expired_qty,
              },
              {
                label: "Pass rilasciati",
                value: performance && performance.issued_qty,
              },
              {
                label: "Pass scaduti",
                value: performance && performance.expired_qty,
              },
            ]
          : []),
        ...(owner && fromLoop
          ? [
              {
                label: "Negozi aderenti",
                value: terms.length
                  ? `${terms.filter((t) => t.signed).length}/${terms.length}`
                  : "Tutti",
              },
            ]
          : []),
      ];

    default:
      break;
  }

  return [];
}

export default function CampaignPerformance({
  fromLoop,
  owner,
  type,
  performance,
  terms,
}) {
  return (
    <React.Fragment>
      <Box mt={1} mb={1}>
        {getDetails(performance, terms, type, owner, fromLoop).map((d) => (
          <ProductDetails key={d.label} label={d.label} value={d.value} />
        ))}
      </Box>

      {fromLoop ? (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          <InfoIcon
            fontSize="small"
            color="inherit"
            style={{ verticalAlign: "text-bottom", marginRight: 4 }}
          />
          {owner
            ? "Le statistiche visualizzate sono quelle generate da tutti i punti vendita"
            : "Le statistiche visualizzate sono solo quelle generate dal punto vendita"}
        </Typography>
      ) : null}
    </React.Fragment>
  );
}
