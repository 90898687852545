import React from "react";

import { Button, Divider, Drawer, List } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import styles from "../utils/styles";

import ListItemLink from "./ListItemLink";

export default function MainMenu({
  open,
  onClose,
  links,
  userActions,
  logoImage,
  anchor = "left",
}) {
  const classes = styles.useStyles();

  function createLinkItem(link, i) {
    return <ListItemLink key={i} link={link} onClick={onClose} />;
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      classes={{ paper: classes.mainMenu }}
    >
      <Button
        startIcon={<ArrowBackIcon />}
        className={classes.mainMenuBack}
        onClick={onClose}
      >
        Back
      </Button>
      <div className={classes.mainMenuLogoWrapper}>
        <img className={classes.mainMenuLogo} src={logoImage} alt="logo" />
      </div>
      <List
        style={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {links.map(createLinkItem)}
      </List>
      {userActions.length > 0 && <Divider />}
      <List>{userActions.map(createLinkItem)}</List>
    </Drawer>
  );
}
