import React from "react";

import NumberInput from "../../NumberInput";
import { PRODUCT_TYPES } from "../../../utils/constants";

export default function Rate({ values, handleChange, ...props }) {
  return (
    [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD].includes(values.type) && (
      <NumberInput
        name="rate"
        label="Tasso di conversione spesa -> punti"
        placeholder="1"
        value={values.rate}
        float
        onChange={handleChange("rate")}
        fullWidth
        margin="normal"
        helperText="Tasso di conversione spesa -> punti (es: quanti euro per guadagnare un punto)"
        {...props}
      />
    )
  );
}
