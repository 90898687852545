import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Card, CardContent, Container } from "@material-ui/core";

export default function Static() {
  const { content } = useParams();
  const [module, setModule] = useState(false);

  import(`./contents/${content}`).then(setModule);

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>{module ? module.default() : ""}</CardContent>
      </Card>
    </Container>
  );
}
