import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useBmapi } from "../../utils/bmapi-context";
import FormManager from "../../ui/FormManager";
import FidelityForm from "../../ui/FidelityForm";
import Title from "../../ui/Title";

export default function SendPoints() {
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [productInfo, setProductInfo] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  function handleSuccess(msg) {
    notifySuccess(msg);
    setProductInfo(null);
    setQrCode(null);
  }

  function getPermissionByMail(email) {
    startLoading();

    bmapi
      .getUsePermissionByEmail(bmapi.getUserInfo().business.id, email)
      .then((up) => {
        setQrCode(up.id);
        return bmapi.getProductInfo(up.id, bmapi.getUserInfo().business.id);
      })
      .then(setProductInfo)
      .catch((error) => {
        stopLoading();
        notifyError(error);
      });
  }

  return (
    <React.Fragment>
      <Title>
        <FormattedMessage id="app-navbar--send-points" />
      </Title>

      {productInfo ? (
        <FormManager
          onCancel={() => setProductInfo(null)}
          onSuccess={handleSuccess}
          info={productInfo}
          qrCode={qrCode}
        />
      ) : (
        <FidelityForm getPermissionByMail={getPermissionByMail} />
      )}
    </React.Fragment>
  );
}
