import React from "react";

import { MenuItem, TextField } from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";

const formatDate = (date) => (date ? date.toLocaleDateString("it-IT") : "");

export default function MainEventId({ values, handleChange, campaigns = [] }) {
  const mainEvents = (campaign) =>
    campaign.type === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE &&
    campaign.campaign_data.rules.main_event_id === "" &&
    new Date(campaign.expiration_date) > new Date();

  return (
    values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
      <TextField
        name="main_event_id"
        label="Evento principale di riferimento"
        value={values.main_event_id}
        onChange={handleChange("main_event_id")}
        key="main_event_id"
        select
        fullWidth
        margin="normal"
      >
        <MenuItem value="">Nessuno</MenuItem>
        {campaigns?.filter(mainEvents).map((campaign) => (
          <MenuItem key={campaign.campaign_id} value={campaign.campaign_id}>
            {campaign.name} (
            {formatDate(new Date(campaign.campaign_data.start_date))} →{" "}
            {formatDate(new Date(campaign.campaign_data.expiration_date))})
          </MenuItem>
        ))}
      </TextField>
    )
  );
}
