const CampaignExpirationPast =
  "La campagna non puo' terminare la distribuzione oggi";
const campaignNotDeletable =
  "Impossibile eliminare la campagna. Sono già stati emessi prodotti";
const demoLimitReached =
  "Campagna demo: numero massimo di prodotti già raggiunto";
const emailAlreadyInUse = "L'indirizzo email inserito risulta già registrato";
const expiredProduct = "Il prodotto scansionato risulta scaduto";
const expiredQrCode =
  "Il QR Code mostrato risulta scaduto. Chiedere all'utente di aggiornare il QR Code e riprovare la scansione";
const invalidCode = "Il codice non appartiene ad un prodotto valido";
const maxProducts =
  "L'utente ha già raggiunto il limite massimo di prodotti per questa campagna";
const notAuthorized =
  "Il codice non appartiene a un prodotto gestito dallo user autenticato";
const ordersLimitExceeded =
  "Hai già acquistato questo prodotto e non puoi acquistarlo di nuovo";
const tokenNotAuthorized =
  "Non è possibile eseguire l'operazione richiesta: non si possiedono i permessi necessari";
const notSigned =
  "Per utilizzare il prodotto scansionato devi prima aderire alla campagna";
const usedProduct = "ll prodotto risulta già utilizzato";
const userNotFound = "La mail inserita non appartiene ad un utente registrato";
const userNotVerified = "Prima di accedere devi certificare la tua mail";
const wrongUser = "Email o password errati";
const maxQtyExcedeed = "Prodotti esauriti";
const primaryMandatory =
  "Per ottenere questo prodotto è necessario aderire all'evento principale";
const codesSoldOut = "Prodotti esauriti";

export function getErrorMessage(error) {
  if (typeof error === "string") return error;

  if (error.code === "auth/email-already-in-use") return emailAlreadyInUse;
  if (error.code === "auth/user-not-found") return wrongUser;
  if (error.code === "auth/wrong-password") return wrongUser;
  if (error.code === "EXPIRED_PERMISSION") return expiredQrCode;
  if (error.code === "DEMO_LIMITS_ERROR") return demoLimitReached;
  if (error.code === "ORDERS_LIMIT_EXCEEDED") return ordersLimitExceeded;
  if (error.message === "USER_NOT_VERIFIED") return userNotVerified;
  if (error.message === "VERIFICATION_TIME_EXPIRED") return userNotVerified;
  if (error.code === "MAX_PRODUCTS_PER_USER_REACHED") return maxProducts;
  if (error.code === "CAMPAIGN_MAX_QTY_EXCEDED") return maxQtyExcedeed;
  if (error.code === "PRIMARY_PRODUCT_IS_MANDATORY") return primaryMandatory;
  if (error.code === "EXTERNAL_CODES_SOLD_OUT") return codesSoldOut;
  if (
    error.message ===
    "ExpirationDate not valid: expiration must be at least one day in the future"
  )
    return CampaignExpirationPast;
  if (error.message === "Token not authorized for the data's tenantID")
    return tokenNotAuthorized;
  if (
    error.message ===
    "Cannot delete campaign. Campaign has products associated and is not a demo"
  )
    return campaignNotDeletable;
  if (error.message === "VALIDATION_ERROR: Product is expired")
    return expiredProduct;
  if (error.message === "VALIDATION_ERROR: Product is not ACTIVE")
    return usedProduct;
  if (error.message === "UseTerms not signed") return notSigned;
  if (error.message === "datastore: no such entity") return invalidCode;
  if (error.message === "TENANT_NOT_AUTHORIZED") return notAuthorized;
  if (error.message === "USER_NOT_FOUND") return userNotFound;

  return error.message;
}
