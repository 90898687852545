import React from "react";

import SignInForm from "../../ui/SignInForm";
import { useBmapi } from "../../utils/bmapi-context";
import { MERCHANT_ROUTES } from "../../utils/constants";

export default function SignIn() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();

  function onSubmit(email, password) {
    startLoading();

    return bmapi.doSignInWithEmailAndPassword(email, password).catch((e) => {
      stopLoading();
      notifyError(e);
    });
  }

  return <SignInForm onSubmit={onSubmit} routes={MERCHANT_ROUTES} />;
}
